/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button, Divider, MenuItem, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../actions/types';
import {
  getInventarioAlmacen,
  setTrasladoPDF,
  getProductos,
  setPedido,
  ultimoConsecutivo,
  setPedidoPDF,
} from '../../api';
import Dialogo from '../../components/DialogoAceptarCancelar/dialogo';
import { StyledPaper } from '../../components/Paper/Paper';
import TablaProductos from '../../components/TablaDeProductos/Tabla';
import { getFechaHora, validarFolio } from '../../Utils/validations';

const Traspaso = () => {
  const {
    inventarios: {
      configuraciones: { tMovimientos },
      almacenes,
      inventarios,
      usuario: { IidTrabajador, token, Email },
    },
  } = useSelector(state => {
    return state;
  });

  const { enqueueSnackbar } = useSnackbar();
  let variant = 'error';
  const listaRolRep = ['Representante', 'Sustituto'];
  const maximaEntrada = 100000;
  const [validProduct, setValidProduct] = useState(false);
  const [productos, setProductos] = useState([]);
  const [productosAlmacen, setProductosAlmacen] = useState([]);
  const [producto, setProducto] = useState(null);
  const [movimiento, setMovimiento] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [origen, setOrigen] = useState(null);
  const [destino, setDestino] = useState(null);
  const [origenes, setOrigenes] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [numMax, setNumMax] = useState(maximaEntrada);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [consecutivo, setConsecutivo] = useState(1);
  const [comentarios, setComentarios] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const titulo = 'Guardar Pedido';
  const [mensaje, setMensaje] = useState('¿Deseas guardar Pedido?');
  const dispatch = useDispatch();
  const listaMov = ['Pedido'];
  const [movSeleccionado, setMovSeleccionado] = useState('Pedido');
  const [loteProducto, setLoteProducto] = useState('');

  const validarDatos = () => {
    if (origen && movimiento && productos.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  const headCells = [
    {
      id: 'id',
      numeric: false,
      label: '#',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Producto',
    },
    {
      id: 'codigo',
      numeric: false,
      disablePadding: true,
      label: 'Código',
    },
    {
      id: 'lote',
      numeric: false,
      disablePadding: true,
      label: 'Lote',
    },
    {
      id: 'cantidad',
      numeric: true,
      disablePadding: false,
      label: 'Cantidad',
    },
    {
      id: 'acciones',
      numeric: false,
      label: 'Acciones',
    },
  ];

  function editItem(id) {
    const productoEdit = productos.filter(item => item.id === id)[0];
    const productTmp = productosAlmacen.filter(
      item => item.SKU === productoEdit.sku
    )[0];
    const { cantidad, lote } = productoEdit;
    setCantidad(cantidad);
    setProducto(productTmp);
    setEditando(productoEdit);
    setLoteProducto(lote);
    setEditar(true);
  }

  function deleteItem(id) {
    const newProducto = productos.filter(item => item.id !== id);
    setProductos(newProducto);
  }

  const cancelar = async () => {
    setProductos([]);
    setProducto(null);
  };

  const guardar = async () => {
    let tmpFolio = origen.Folio;
    let almacenOrigen = {
      ClaveAlmacen: origen.ClaveAlmacen,
      idAlmacen: origen.IidAlmacen,
      Centro: origen.Centro,
      Folio: origen.Folio,
      Zona: origen.Zona,
      rfcOrigen: origen.RFC,
      origenDetalle: origen,
      TipoTraspaso: movSeleccionado === 'Entrada' ? 'Entrada' : 'Salida',
    };
    let almacenDestino = {
      idAlmacen: origen.IidAlmacen,
      ClaveAlmacen: origen.ClaveAlmacen,
      destinoDetalle: origen,
      Centro: origen.Centro,
      rfcDestino: origen.RFC,
      ComentariosTraspaso: comentarios,
    };
    try {
      const {
        consecutivos: { ConsPedido },
      } = await ultimoConsecutivo(token, { almacenOrigen });
      setConsecutivo(ConsPedido);
      const tipoMov = movimiento.IidMov;
      const padAM = validarFolio(consecutivo);
      const folioMov = (tmpFolio + padAM).trim();
      const filename = `Pedido_${folioMov}`;
      const { date, time } = getFechaHora();
      const productosList = [...productos];
      productosList.forEach(element => {
        if (element?.lote?.trim() === '') {
          element.lote = null;
        }
      });
      const payload = {
        productos: productosList,
        almacenDestino,
        almacenOrigen,
        movimiento,
        folioMov,
        tipoMov,
        ConsPedido: consecutivo,
      };
      const payload2 = {
        folio: folioMov,
        solicito: IidTrabajador,
        estatus: 1,
      };
      const crearXLSX = [];
      productos.forEach((element, index) => {
        const row = {
          num: index + 1,
          producto: element.name,
          sku: element.sku,
          cantidad: element.cantidad,
        };
        row.representante = Email;
        row.origen = almacenOrigen.ClaveAlmacen;
        row.centroOrigen = almacenOrigen.Centro;
        row.destino = almacenDestino.ClaveAlmacen;
        row.centroDestino = almacenDestino.Centro;
        row.tipoMovimiento = movimiento.label;
        row.folioMovimiento = folioMov;
        row.fecha = `${date} ${time}`;
        crearXLSX.push(row);
      });
      dispatch({ type: 'FETCHING_START' });
      try {
        const { tPedidos, tAlmacenes } = await setPedido(token, payload);
        if (tPedidos) {
          const tmpCons = consecutivo + 1;
          setConsecutivo(tmpCons);
          tPedidos.forEach(element => {
            dispatch({
              type: 'PEDIDOS_ADD',
              payload: { pedido: element },
            });
          });
          dispatch({
            type: 'ALMACENES_FETCHING',
            payload: { almacenes: tAlmacenes },
          });
          setProductos([]);
          setProducto(null);
          setMovSeleccionado('Entrada');
          variant = 'success';
          sendSnackA('Se realizo el movimiento con éxito');
          //getFile(crearXLSX, filename);
          await setPedidoPDF(token, payload2, filename);
          dispatch({ type: 'FETCHING_END' });
        }
      } catch (error) {
        console.log(error);
        variant = 'error';
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesion finalizada');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde');
          dispatch({ type: 'FETCHING_END' });
        }
      }
    } catch (error) {
      console.log(error);
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const agregarProducto = async () => {
    let lastId = 0;
    if (productos.length > 0) {
      lastId = Math.max(...productos.map(x => parseInt(x.id))) + 1;
    }
    let tmpProd = {};
    let productosOrigen = inventarios[origen.C_Unica];
    tmpProd = productosOrigen.filter(
      productoOrigen =>
        productoOrigen.Lote === producto.Lote &&
        productoOrigen.SKU === producto.SKU
    )[0];
    const detalles = [producto];
    detalles.push(tmpProd);
    const newProduct = {
      id: lastId,
      name: producto.Nombre,
      sku: producto.SKU,
      lote: producto.Lote,
      cantidad,
      detalles,
      acciones: true,
    };
    setProductos(productos.concat(newProduct));
    setProducto(null);
    setCantidad(1);
    setLoteProducto('');
  };

  const actualizarProducto = async () => {
    const { id } = editando;
    let tmpProduct = [...productos];
    let tmpProd = [];
    tmpProduct = tmpProduct.filter(item => item.id !== id);
    const detalles = [producto];
    detalles.push(tmpProd);
    const newProduct = {
      id,
      name: producto.Nombre,
      sku: producto.SKU,
      lote: producto.Lote,
      cantidad,
      detalles,
      acciones: true,
    };
    tmpProduct.push(newProduct);
    tmpProduct = tmpProduct.sort(function (a, b) {
      return a.id - b.id;
    });
    setProductos(tmpProduct);
    setProducto(null);
    setCantidad(1);
    setEditando(false);
    setEditar(false);
    setLoteProducto('');
  };

  const handleChangeCantidad = event => {
    const newValue = event.target.value;
    setCantidad(parseInt(newValue <= numMax ? newValue : numMax));
  };

  useEffect(() => {
    let flag = false;
    if (producto && cantidad > 0) {
      flag = true;
    }
    setValidProduct(flag);
  }, [producto, cantidad]);

  const sendSnackA = message => {
    if (origen && destino)
      enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  useEffect(() => {
    let tmp = [];
    async function fetchData(baja = false) {
      dispatch({ type: 'FETCHING_START' });
      try {
        if (!baja) {
          const { tProductos } = await getProductos(token);
          tmp = tProductos;
          tmp.sort(function (a, b) {
            if (a.SKU < b.SKU) {
              return -1;
            }
            if (a.SKU > b.SKU) {
              return 1;
            }
            return 0;
          });
          setProductosAlmacen(tmp);
        } else {
          const { tInventario } = await getInventarioAlmacen(token, baja);
          tmp = tInventario;
          tmp.sort(function (a, b) {
            if (a.SKU < b.SKU) {
              return -1;
            }
            if (a.SKU > b.SKU) {
              return 1;
            }
            return 0;
          });
          setProductosAlmacen(tmp);
        }
        setProducto(null);
      } catch (error) {
        variant = 'error';
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesión finalizada');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde');
          dispatch({ type: 'FETCHING_END' });
        }
      } finally {
        dispatch({ type: 'FETCHING_END' });
      }
    }
    if (origen) {
      const lista = inventarios[origen.C_Unica] || [];
      let tmpConsecutivo = origen.ConsPedido;
      lista.forEach(element => {
        const flag = tmp.find(
          element2 =>
            element2.SKU === element.SKU &&
            element2.Lote === element.Lote &&
            element.Iidtrabajador === IidTrabajador
        );
        if (!flag) {
          tmp.push(element);
        }
      });
      tmp.sort(function (a, b) {
        if (a.SKU < b.SKU) {
          return -1;
        }
        if (a.SKU > b.SKU) {
          return 1;
        }
        return 0;
      });
      setProductosAlmacen(tmp);
      setProductos([]);
      setConsecutivo(tmpConsecutivo);
    } else {
      setProductosAlmacen([]);
      setProducto(null);
    }
  }, [origen]);

  useEffect(() => {
    getAlmacenesPropios();
  }, [movSeleccionado]);

  useEffect(() => {
    getAlmacenesPropios();
  }, [almacenes]);

  function getAlmacenesPropios() {
    const tmpAlmacenes = almacenes.filter(
      almacen =>
        (almacen.Iidtrabajador === IidTrabajador ||
          almacen.idSustituto === IidTrabajador) &&
        almacen?.Almacen !== 'SAP'
    );
    tmpAlmacenes.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    setDestinos(tmpAlmacenes);
    setOrigenes(tmpAlmacenes);
    setDestino(null);
    setOrigen(null);
  }

  async function aceptar() {
    setOpenDialog(false);
    guardar();
  }
  async function cerrar() {
    setOpenDialog(false);
  }

  useEffect(() => {
    setMovimiento(tMovimientos[0]);
  }, []);

  const boxStyle = { marginTop: 10, textAlign: 'center', marginRight: 25 };
  const dividerStyle = { marginTop: 10 };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h5' gutterBottom>
          Pedido de Inventario
        </Typography>
        <Typography variant='h6' gutterBottom>
          Pedido: #{consecutivo}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginLeft: '5%',
        }}
      >
        <Stack>
          <Divider style={dividerStyle}>
            <Typography variant='h5' gutterBottom>
              <ArrowDownwardIcon />
              {`Selecciona el almacen`}
              <ArrowDownwardIcon />
            </Typography>
          </Divider>
          <Box style={boxStyle} spacing={1} sx={{ minWidth: '30%' }}>
            <Autocomplete
              options={origenes}
              id='auto-origen'
              clearOnEscape
              value={origen}
              onChange={(event, newValue) => {
                if (newValue !== destino && newValue !== origen) {
                  setOrigen(newValue);
                  setProductos([]);
                  setProducto(null);
                } else {
                  setOrigen(null);
                  sendSnackA(types.MISMO_DESTINO);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Origen'
                  required
                  variant='standard'
                />
              )}
            />
          </Box>
        </Stack>
        <Stack>
          <Divider style={dividerStyle}>
            <Typography variant='h5' gutterBottom>
              <ArrowDownwardIcon />
              {`Selecciona el Producto`}
              <ArrowDownwardIcon />
            </Typography>
          </Divider>
          <Box style={boxStyle} spacing={1} sx={{ minWidth: '55%' }}>
            <Autocomplete
              size='medium'
              options={productosAlmacen}
              id='auto-producto'
              clearOnEscape
              value={producto}
              onChange={(event, newValue) => {
                const flag = productos.find(
                  element2 => element2.sku === newValue.SKU
                );
                if (!flag) setProducto(newValue);
                try {
                  const { CantidadDisponible } = newValue;
                  setNumMax(maximaEntrada);
                  setCantidad(CantidadDisponible > 0 ? 1 : 0);
                } catch (error) {
                  console.log(error);
                  setCantidad(1);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  label='Producto'
                  variant='standard'
                  helperText='Selecciona el producto'
                />
              )}
            />
          </Box>
          <Divider style={dividerStyle}>
            <Typography variant='h5' gutterBottom>
              <ArrowDownwardIcon />
              {`Ingresa Cantidad`}
              <ArrowDownwardIcon />
            </Typography>
          </Divider>
          <Box style={boxStyle} spacing={1} sx={{ minWidth: '35%' }}>
            <div style={{ marginTop: 10 }}>
              <TextField
                id='outlined-lote'
                label='Cantidad'
                type='number'
                required
                value={cantidad}
                onChange={handleChangeCantidad}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1, max: numMax },
                }}
                helperText={`${producto ? 'Cantidad maxima ' + numMax : ''}`}
              />
            </div>
          </Box>
        </Stack>
        <div style={divStyle}>
          {editar ? (
            <>
              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  setCantidad(1);
                  setProducto(null);
                  setEditando(null);
                  setEditar(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                disabled={!validProduct}
                onClick={() => actualizarProducto()}
              >
                Actualizar
              </Button>
            </>
          ) : (
            <Button
              variant='contained'
              disabled={!validProduct}
              onClick={() => agregarProducto()}
            >
              Agregar
            </Button>
          )}
        </div>
      </div>
      <div style={divStyle}>
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <StyledPaper>
            <Grid container wrap='nowrap' spacing={2}>
              <Grid item xs={12}>
                <TablaProductos
                  headers={headCells}
                  productos={productos}
                  editItem={editItem}
                  deleteItem={deleteItem}
                  detalles={false}
                  mensaje={'Productos '}
                />
              </Grid>
            </Grid>
          </StyledPaper>
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 15,
          marginLeft: 35,
          marginRight: 35,
        }}
      >
        <TextField
          label='Comentarios del Pedido'
          multiline
          minRows={4}
          maxRows={7}
          inputProps={{ maxLength: 500 }}
          style={{ width: '100%' }}
          value={comentarios}
          onChange={event => {
            const newValue = event.target.value;
            setComentarios(newValue);
          }}
        />
      </div>
      <br />
      <div style={divStyle}>
        <Button variant='contained' color='error' onClick={() => cancelar()}>
          Cancelar
        </Button>
        <Button
          variant='contained'
          disabled={validarDatos()}
          onClick={() => setOpenDialog(true)}
        >
          Guardar
        </Button>
      </div>
      <br />
      <br />
      <Dialogo
        abrir={openDialog}
        cerrar={cerrar}
        titulo={titulo}
        mensaje={mensaje}
        aceptar={aceptar}
      />
    </>
  );
};

export default Traspaso;
