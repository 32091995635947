/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { validToken } from '../../api';
import BackgroundLetterAvatars from '../Avatar/Avatar';
import Header from '../Header/header';

const pagesIniciales = [
  {
    name: 'Inicio',
    route: '',
  },
  {
    name: 'Pedidos',
    route: 'pedidos',
  },
  // {
  //   name: 'Forecast',
  //   route: 'forecast',
  // },
  {
    name: 'OV',
    route: 'ov',
  },
  {
    name: 'N.C./Can.',
    route: 'nc',
  },
  {
    name: 'AM',
    route: 'traspasos',
  },
  {
    name: 'Reportes',
    route: 'reportes',
  },
];

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const drawerWidth = 120;

function MenuBar() {
  const navigate = useNavigate();
  const {
    inventarios: {
      usuario: { Nombre, Paterno, admin, token, NombreRol },
    },
  } = useSelector(state => {
    return state;
  });
  const { enqueueSnackbar } = useSnackbar();
  let variant = 'error';
  const theme = useTheme();
  const nombreVendedor = `${Nombre} ${Paterno}`;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [pages, setPages] = useState(pagesIniciales);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (admin && !pages.some(e => e.name === 'Admin')) {
      const tmpPages = [...pagesIniciales];
      tmpPages.push({
        name: 'Admin',
        route: 'admin',
      });
      setPages(tmpPages);
    } else {
      const existe = pages.some(e => e.name === 'Admin');
      if (NombreRol === 'Reporteo') {
        setPages([
          {
            name: 'Reportes',
            route: 'reportes',
          },
        ]);
        navigate('/reportes');
        setPage('Reportes');
      }
      if (existe && !admin) {
        setPages(pagesIniciales);
      }
    }
  }, []);

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    validarToken();
  }, [page]);
  const sendSnackA = message => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  async function validarToken() {
    dispatch({ type: 'FETCHING_START' });
    try {
      const response = await validToken(token);
      console.log(response);
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesión finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    } finally {
      dispatch({ type: 'FETCHING_END' });
    }
  }

  return (
    <div className='App'>
      <Box sx={{ flexGrow: 1 }}>
        <Header />
        <AppBar position='static'>
          <Container maxWidth='xl'>
            <Toolbar
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              {isMobile ? (
                <>
                  <Box
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}
                  >
                    <IconButton
                      color='inherit'
                      component='label'
                      size='small'
                      onClick={handleDrawerOpen}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    noWrap
                    component='div'
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}
                  >
                    {page}
                  </Typography>
                </>
              ) : (
                <div style={{ marginRight: '1rem' }}>
                  {pages.map((link, index) => {
                    return (
                      <Button
                        variant='text'
                        component={Link}
                        style={{ padding: 5, margin: 2 }}
                        to={`/${link.route}`}
                        key={index}
                        color={page === link.name ? 'secondary' : 'inherit'}
                        onClick={() => {
                          setPage(link.name);
                          handleDrawerClose();
                        }}
                      >
                        {link.name}
                      </Button>
                    );
                  })}
                </div>
              )}
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title='Abrir opciones'>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {nombreVendedor && nombreVendedor !== undefined ? (
                      <BackgroundLetterAvatars nombre={nombreVendedor} />
                    ) : null}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {/* <MenuItem
                    key={'config'}
                    onClick={() => {
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign='center'>Configuración</Typography>
                  </MenuItem> */}
                  <MenuItem
                    key={'cerrar'}
                    onClick={async () => {
                      dispatch({ type: 'RESET_ALL_DATA' });
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign='center'>Cerrar sesión</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='temporary'
        anchor='left'
        onClose={() => {
          handleDrawerClose();
        }}
        open={open}
      >
        <DrawerHeader style={{ alignSelf: 'center' }}>MENU</DrawerHeader>
        <Divider />
        <List style={{ alignItems: 'center' }}>
          {pages.map((link, index) => {
            return (
              <Button
                variant='text'
                component={Link}
                style={{
                  padding: 20,
                  margin: 2,
                  alignSelf: 'center',
                  display: 'flex',
                }}
                to={`/${link.route}`}
                key={index}
                color={page === link.name ? 'secondary' : 'inherit'}
                onClick={() => {
                  setPage(link.name);
                  handleDrawerClose();
                }}
              >
                {link.name}
              </Button>
            );
          })}
        </List>
      </Drawer>
    </div>
  );
}

export default MenuBar;
