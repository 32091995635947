/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialogo from '../../components/DialogoAceptarCancelar/dialogo';

const Forecast = () => {
  const {
    inventarios: {
      configuraciones: { tMovimientos },
      almacenes,
      inventarios,
      usuario: { IidTrabajador, token, Email },
    },
  } = useSelector(state => {
    return state;
  });

  const { enqueueSnackbar } = useSnackbar();
  let variant = 'error';
  const listaRolRep = ['Representante', 'Sustituto'];
  const [openDialog, setOpenDialog] = useState(false);
  const titulo = 'Guardar Forecast';
  const [mensaje, setMensaje] = useState('¿Deseas guardar Pedido?');
  const dispatch = useDispatch();

  async function cerrar() {
    setOpenDialog(false);
  }
  
  async function aceptar() {
    setOpenDialog(false);
  }



  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h5' gutterBottom>
          Forecast
        </Typography>
      </div>
     
      <Dialogo
        abrir={openDialog}
        cerrar={cerrar}
        titulo={titulo}
        mensaje={mensaje}
        aceptar={aceptar}
      />
    </>
  );
};

export default Forecast;
