/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Button,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../actions/types';
import {
  getInventarioAlmacen,
  getPedidosR,
  getProductos,
  setEntrada,
  setTraslado,
  setTrasladoBajaProducto,
  setTrasladoManual,
  setTrasladoPDF,
  ultimoConsecutivo,
} from '../../api';
import Dialogo from '../../components/DialogoAceptarCancelar/dialogo';
import { StyledPaper } from '../../components/Paper/Paper';
import TablaProductos from '../../components/TablaDeProductos/Tabla';
import { getFechaHora, validarFolio } from '../../Utils/validations';
const Traspaso = () => {
  const {
    inventarios: {
      configuraciones: { tMovimientos, tZonas },
      almacenes,
      inventarios,
      representantes,
      pedidos,
      usuario: { IidTrabajador, token, Email, NombreRol, admin },
    },
  } = useSelector(state => {
    return state;
  });

  const { enqueueSnackbar } = useSnackbar();
  let variant = 'error';
  const listaRolRep = ['Representante', 'Sustituto'];
  const maximaEntrada = 100000;
  const [checked, setChecked] = useState(false);
  const [flujoAlterno, setFlujoAlterno] = useState(false);
  const [validProduct, setValidProduct] = useState(false);
  const [productos, setProductos] = useState([]);
  const [productosAlmacen, setProductosAlmacen] = useState([]);
  const [productosAlmacenTodos, setProductosAlmacenTodos] = useState([]);
  const [producto, setProducto] = useState(null);
  const [movimiento, setMovimiento] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [origen, setOrigen] = useState(null);
  const [destino, setDestino] = useState(null);
  const [origenes, setOrigenes] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [numMax, setNumMax] = useState(1);
  const [ordenCompra, setOrdenCompra] = useState(null);
  const [otrasZonas, setOtrasZonas] = useState([]);
  const [bajaProducto, setBajaProducto] = useState(false);
  const [otraZona, setOtraZona] = useState(null);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [consecutivo, setConsecutivo] = useState(1);
  const [comentarios, setComentarios] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const titulo = 'Guardar AM';
  const flagRepresentante = listaRolRep.includes(NombreRol);
  const [mensaje, setMensaje] = useState('¿Deseas guardar AM?');
  const dispatch = useDispatch();
  const listaMov = ['Movimiento', 'Entrada', 'Movimiento Manual'];
  const [movSeleccionado, setMovSeleccionado] = useState('Movimiento');
  const [loteProducto, setLoteProducto] = useState('');
  const [listaMovimientos, setListaMovimientos] = useState([]);
  const [representanteSeleccionado, setRepresentanteSeleccionado] =
    useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [esPedido, setEsPedido] = useState(false);
  const [pedidosRepresentante, setPedidosRepresentante] = useState([]);
  const [pedidoSeleccionado, setPedidoSeleccionado] = useState(null);
  const [productosPedido, setProductosPedido] = useState(null);
  const [estado, setEstado] = useState('');
  const [colonia, setColonia] = useState('');
  const [direccion, setDireccion] = useState('');
  const [editarDestino, setEditarDestino] = useState(false);

  const listaEspecial = ['Entrada', 'Movimiento Manual'];
  const listaFlujo = [
    'muestra',
    'merma/faltante',
    'pruebas semicomerciales',
    'desarrollo',
  ];

  const validarDatos = () => {
    if (
      origen &&
      destino &&
      movimiento &&
      productos.length > 0 &&
      (movSeleccionado === 'Movimiento Manual' ? fechaFinal !== null : true)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = event => {
    const flag = event.target.checked;
    setChecked(flag);
    if (flag) {
      const zonas = tZonas.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setOtrasZonas(zonas);
    } else {
      getAlmacenesPropios();
    }
  };

  const handleChangeDestino = event => {
    const flag = event.target.checked;
    setEditarDestino(flag);
  };

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  const headCells = [
    {
      id: 'id',
      numeric: false,
      label: '#',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Producto',
    },
    {
      id: 'codigo',
      numeric: false,
      disablePadding: true,
      label: 'Código',
    },
    {
      id: 'lote',
      numeric: false,
      disablePadding: true,
      label: 'Lote',
    },
    {
      id: 'cantidad',
      numeric: true,
      disablePadding: false,
      label: 'Cantidad',
    },
    {
      id: 'acciones',
      numeric: false,
      label: 'Acciones',
    },
  ];

  const headCellsPedidos = [
    {
      id: 'id',
      numeric: false,
      label: '#',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Producto',
    },
    {
      id: 'codigo',
      numeric: false,
      disablePadding: true,
      label: 'Código',
    },
    {
      id: 'lote',
      numeric: false,
      disablePadding: true,
      label: 'Lote',
    },
    {
      id: 'cantidad',
      numeric: true,
      disablePadding: false,
      label: 'Cantidad',
    },
  ];

  function editItem(id) {
    const productoEdit = productos.filter(item => item.id === id)[0];
    const productTmp = productosAlmacen.filter(
      item => item.SKU === productoEdit.sku
    )[0];
    const { CantidadDisponible } = productTmp;
    const { cantidad, lote } = productoEdit;
    setCantidad(cantidad);
    setNumMax(
      listaEspecial.includes(movSeleccionado)
        ? maximaEntrada
        : CantidadDisponible
    );
    setProducto(productTmp);
    setEditando(productoEdit);
    setLoteProducto(lote);
    setEditar(true);
  }

  function deleteItem(id) {
    const newProducto = productos.filter(item => item.id !== id);
    setProductos(newProducto);
  }

  const cancelar = async () => {
    setProductos([]);
    setProducto(null);
  };

  const guardar = async () => {
    let tmpFolio = origen.Folio;
    let almacenOrigen = {
      TipoTraspaso: movSeleccionado === 'Entrada' ? 'Entrada' : 'Salida',
      ClaveAlmacen: origen.ClaveAlmacen,
      idAlmacen: origen.IidAlmacen,
      origenDetalle: origen,
      rfcOrigen: origen.RFC,
      Centro: origen.Centro,
      Folio: origen.Folio,
      Zona: origen.Zona,
    };
    const domicilio = {
      direccion: editarDestino ? direccion : destino.Direccion,
      colonia: editarDestino ? colonia : destino.Colonia,
      estado: editarDestino ? estado : destino.Estado,
    };
    let almacenDestino = {
      ClaveAlmacen: destino.ClaveAlmacen,
      ComentariosTraspaso: comentarios,
      idAlmacen: destino.IidAlmacen,
      rfcDestino: destino.RFC,
      destinoDetalle: destino,
      Centro: destino.Centro,
      domicilio,
    };
    if (bajaProducto) {
      tmpFolio = origen.Folio;
      almacenOrigen = {
        TipoTraspaso: movSeleccionado === 'Entrada' ? 'Entrada' : 'Salida',
        ClaveAlmacen: origen.ClaveAlmacen,
        idAlmacen: origen.IidAlmacen,
        origenDetalle: origen,
        Centro: origen.Centro,
        Folio: origen.Folio,
        Zona: origen.Zona,
      };
      almacenDestino = {
        ClaveAlmacen: origen.ClaveAlmacen,
        ComentariosTraspaso: comentarios,
        idAlmacen: origen.IidAlmacen,
        destinoDetalle: origen,
        Centro: origen.Centro,
        domicilio,
      };
    }
    try {
      const {
        consecutivos: { AMcons },
      } = await ultimoConsecutivo(token, { almacenOrigen });
      setConsecutivo(AMcons);
      const tipoMov = movimiento.IidMov;
      const padAM = validarFolio(consecutivo);
      const folioMov = (tmpFolio + padAM).trim();
      const filename = `AM_${folioMov}`;
      const { date, time } = getFechaHora();
      const productosList = [...productos];
      productosList.forEach(element => {
        if (element?.lote?.trim() === '') {
          element.lote = null;
        }
      });
      const payload = {
        productos: productosList,
        ConsAM: consecutivo,
        pedidoSeleccionado,
        almacenDestino,
        almacenOrigen,
        movimiento,
        esPedido,
        folioMov,
        tipoMov,
      };
      const crearXLSX = [];
      productos.forEach((element, index) => {
        const row = {
          num: index + 1,
          producto: element.name,
          sku: element.sku,
          cantidad: element.cantidad,
        };
        row.representante = Email;
        row.origen = almacenOrigen.ClaveAlmacen;
        row.centroOrigen = almacenOrigen.Centro;
        row.destino = almacenDestino.ClaveAlmacen;
        row.centroDestino = almacenDestino.Centro;
        row.tipoMovimiento = movimiento.label;
        row.folioMovimiento = folioMov;
        row.fecha = `${date} ${time}`;
        crearXLSX.push(row);
      });
      dispatch({ type: 'FETCHING_START' });
      try {
        if (bajaProducto) {
          const { tAlmacenes } = await setTrasladoBajaProducto(token, payload);
          variant = 'success';
          sendSnackA('Se realizo el movimiento con éxito');
          dispatch({
            type: 'ALMACENES_FETCHING',
            payload: { almacenes: tAlmacenes },
          });
          await setTrasladoPDF(token, payload, filename);
          await new Promise(res => setTimeout(res, 800));
          setRepresentanteSeleccionado(null);
          dispatch({ type: 'FETCHING_END' });
        } else {
          const { tInventario, tTraslado, tAlmacenes } =
            movSeleccionado === 'Entrada'
              ? await setEntrada(token, payload)
              : await setTraslado(token, payload);
          if (tInventario && movSeleccionado !== 'Entrada') {
            const tmpCons = consecutivo + 1;
            dispatch({
              type: 'ALMACENES_FETCHING',
              payload: { almacenes: tAlmacenes },
            });
            setConsecutivo(tmpCons);
            dispatch({
              type: 'INVENTARIO_FETCHING',
              payload: { inventarios: tInventario },
            });
            tTraslado.forEach(element => {
              dispatch({
                type: 'MOVIMIENTO_ADD',
                payload: { movimiento: element },
              });
            });
            const lista = await tInventario[origen.C_Unica];
            lista.sort(function (a, b) {
              if (a.SKU < b.SKU) {
                return -1;
              }
              if (a.SKU > b.SKU) {
                return 1;
              }
              return 0;
            });
            setProductosAlmacen(lista);
            setProductos([]);
            setProducto(null);
            variant = 'success';
            sendSnackA('Se realizo el movimiento con éxito');
            //getFile(crearXLSX, filename);
            await setTrasladoPDF(token, payload, filename);
            dispatch({ type: 'FETCHING_END' });
          } else if (tAlmacenes) {
            const tmpCons = consecutivo + 1;
            dispatch({
              type: 'ALMACENES_FETCHING',
              payload: { almacenes: tAlmacenes },
            });
            setConsecutivo(tmpCons);
            dispatch({
              type: 'INVENTARIO_FETCHING',
              payload: { inventarios: tInventario },
            });
            tTraslado.forEach(element => {
              dispatch({
                type: 'MOVIMIENTO_ADD',
                payload: { movimiento: element },
              });
            });
            setProductos([]);
            setProducto(null);
            setMovSeleccionado('Entrada');
            setRepresentanteSeleccionado(null);
            setPedidoSeleccionado(null);
            setProductosPedido([]);
            setComentarios('');
            setDestino(null);
            setEstado('');
            setDireccion('');
            setColonia('');
            setOrigen(null);
            variant = 'success';
            sendSnackA('Se realizo el movimiento con éxito');
            //getFile(crearXLSX, filename);
            await setTrasladoPDF(token, payload, filename);
            dispatch({ type: 'FETCHING_END' });
          }
        }
      } catch (error) {
        console.log(error);
        variant = 'error';
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesion finalizada');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde');
          dispatch({ type: 'FETCHING_END' });
        }
      }
    } catch (error) {
      console.log(error);
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const guardarHistorico = async () => {
    let tmpFolio = origen.Folio;
    if (!tmpFolio) {
      sendSnackA('El almacen origen no tiene folio asignado');
    }
    let almacenOrigen = {
      ClaveAlmacen: origen.ClaveAlmacen,
      idAlmacen: origen.IidAlmacen,
      Centro: origen.Centro,
      Folio: origen.Folio,
      Zona: origen.Zona,
      rfcOrigen: origen.RFC,
      origenDetalle: origen,
      TipoTraspaso: 'Salida',
    };
    let almacenDestino = {
      idAlmacen: destino.IidAlmacen,
      ClaveAlmacen: destino.ClaveAlmacen,
      destinoDetalle: destino,
      Centro: destino.Centro,
      rfcDestino: destino.RFC,
      ComentariosTraspaso: comentarios,
    };
    const tipoMov = movimiento.IidMov;
    const padAM = validarFolio(consecutivo);
    const folioMov = (tmpFolio + padAM).trim();
    const filename = `AM_${folioMov}`;
    const { date, time } = getFechaHora();
    const payload = {
      fechaAm: fechaFinal?.toJSON().split('T')[0],
      ConsAM: consecutivo,
      almacenDestino,
      almacenOrigen,
      movimiento,
      productos,
      folioMov,
      tipoMov,
    };
    const crearXLSX = [];
    productos.forEach((element, index) => {
      const row = {
        num: index + 1,
        producto: element.name,
        sku: element.sku,
        cantidad: element.cantidad,
      };
      if (index === 0) {
        row.representante = Email;
        row.origen = almacenOrigen.ClaveAlmacen;
        row.centroOrigen = almacenOrigen.Centro;
        row.destino = almacenDestino.ClaveAlmacen;
        row.centroDestino = almacenDestino.Centro;
        row.tipoMovimiento = movimiento.label;
        row.folioMovimiento = folioMov;
        row.fecha = `${date} ${time}`;
      }
      crearXLSX.push(row);
    });
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tInventario, tTraslado, tAlmacenes } = await setTrasladoManual(
        token,
        payload
      );
      if (tInventario) {
        const tmpCons = consecutivo + 1;
        dispatch({
          type: 'ALMACENES_FETCHING',
          payload: { almacenes: tAlmacenes },
        });
        setConsecutivo(tmpCons);
        dispatch({
          type: 'INVENTARIO_FETCHING',
          payload: { inventarios: tInventario },
        });
        tTraslado.forEach(element => {
          dispatch({
            type: 'MOVIMIENTO_ADD',
            payload: { movimiento: element },
          });
        });
        variant = 'success';
        sendSnackA('Se realizo el movimiento con éxito');
        //getFile(crearXLSX, filename);
        //await setTrasladoPDF(token, payload, filename);
        dispatch({ type: 'FETCHING_END' });
      }
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const agregarProducto = async () => {
    let lastId = 0;
    if (productos.length > 0) {
      lastId = Math.max(...productos.map(x => parseInt(x.id))) + 1;
    }
    let tmpProd = {};
    if (listaEspecial.includes(movSeleccionado)) {
      tmpProd = productosAlmacen.filter(
        productoOrigen =>
          productoOrigen.Lote === producto.Lote &&
          productoOrigen.SKU === producto.SKU
      )[0];
    } else if (
      representanteSeleccionado &&
      (movimiento?.DescripcionMov.toLowerCase().includes('baja') || esPedido)
    ) {
      tmpProd = productosAlmacen.filter(
        productoOrigen =>
          productoOrigen.Lote === producto.Lote &&
          productoOrigen.SKU === producto.SKU
      )[0];
    } else {
      let productosOrigen = inventarios[origen.C_Unica];
      tmpProd = productosOrigen.filter(
        productoOrigen =>
          productoOrigen.Lote === producto.Lote &&
          productoOrigen.SKU === producto.SKU
      )[0];
    }
    const detalles = [producto];
    detalles.push(tmpProd);
    const newProduct = {
      id: lastId,
      name: producto.Nombre,
      sku: producto.SKU,
      lote: listaEspecial.includes(movSeleccionado)
        ? loteProducto
        : producto.Lote,
      cantidad,
      detalles,
      acciones: true,
    };
    setProductos(productos.concat(newProduct));
    setProducto(null);
    setCantidad(1);
    setLoteProducto('');
    setNumMax(1);
  };

  const actualizarProducto = async () => {
    const { id } = editando;
    let tmpProduct = [...productos];
    let tmpProd = [];
    tmpProduct = tmpProduct.filter(item => item.id !== id);
    if (listaEspecial.includes(movSeleccionado)) {
      tmpProd = productosAlmacen.filter(
        productoOrigen =>
          productoOrigen.Lote === producto.Lote &&
          productoOrigen.SKU === producto.SKU
      )[0];
    } else {
      let productosDestino = inventarios[destino.C_Unica];
      tmpProd = productosDestino.filter(
        productoDestino =>
          productosDestino.Lote === producto.Lote &&
          productoDestino.SKU === producto.SKU
      )[0];
    }
    const detalles = [producto];
    detalles.push(tmpProd);
    const newProduct = {
      id,
      name: producto.Nombre,
      sku: producto.SKU,
      lote: listaEspecial.includes(movSeleccionado)
        ? loteProducto
        : producto.Lote,
      cantidad,
      detalles,
      acciones: true,
    };
    tmpProduct.push(newProduct);
    tmpProduct = tmpProduct.sort(function (a, b) {
      return a.id - b.id;
    });
    setProductos(tmpProduct);
    setProducto(null);
    setCantidad(1);
    setNumMax(1);
    setEditando(false);
    setEditar(false);
    setLoteProducto('');
  };

  const handleChangeCantidad = event => {
    const newValue = event.target.value;
    setCantidad(parseInt(newValue <= numMax ? newValue : numMax));
  };

  useEffect(() => {
    let flag = false;
    if (producto && cantidad > 0) {
      flag = true;
    }
    setValidProduct(flag);
  }, [producto, cantidad]);

  useEffect(() => {
    const flag =
      movimiento?.DescripcionMov.toLowerCase().includes('baja') || false;
    setBajaProducto(flag);
    setDestino(null);
    setEstado('');
    setDireccion('');
    setColonia('');
    if (flag) {
      setOtraZona(null);
      setChecked(false);
      setRepresentanteSeleccionado(representantes[0]);
    } else {
      getAlmacenesPropios();
    }
  }, [movimiento]);

  useEffect(() => {
    if (destino) {
      setEstado(destino.Estado || null);
      setDireccion(destino.Direccion || null);
      setColonia(destino.Colonia || null);
    } else {
      setEstado('');
      setDireccion('');
      setColonia('');
    }
  }, [destino]);

  useEffect(() => {
    if (otraZona !== null) {
      const tmp = [];
      almacenes.forEach(almacen => {
        const flag = tmp.find(
          element => element.IidAlmacen === almacen.IidAlmacen
        );
        if (
          !flag &&
          almacen?.Almacen !== 'SAP' &&
          otraZona.label === almacen.Zona
        ) {
          tmp.push(almacen);
        }
      });
      tmp.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setDestinos(tmp);
      setDestino(tmp[0] || null);
      setEstado(tmp[0].Estado || null);
      setDireccion(tmp[0].Direccion || null);
      setColonia(tmp[0].Colonia || null);
    } else {
      setDestino(null);
      setEstado('');
      setDireccion('');
      setColonia('');
    }
  }, [otraZona]);

  const sendSnackA = message => {
    if (origen && destino)
      enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  const handleChangeFechaFinal = newValue => {
    setFechaFinal(newValue);
  };

  useEffect(() => {
    let tmp = [];
    setProducto(null);
    async function fetchData(baja = false) {
      dispatch({ type: 'FETCHING_START' });
      try {
        if (!baja) {
          console.log('mero: ', esPedido);
          const { tProductos } = await getProductos(token);
          tmp = tProductos;
          tmp.sort(function (a, b) {
            if (a.SKU < b.SKU) {
              return -1;
            }
            if (a.SKU > b.SKU) {
              return 1;
            }
            return 0;
          });
          if (origen.Almacen !== 'POSA') {
            setProductosAlmacen(tmp);
          }
          setProductosAlmacenTodos(tProductos);
        } else if (baja) {
          const { tInventario } = await getInventarioAlmacen(token, baja);
          tmp = tInventario;
          tmp.sort(function (a, b) {
            if (a.SKU < b.SKU) {
              return -1;
            }
            if (a.SKU > b.SKU) {
              return 1;
            }
            return 0;
          });
          setProductosAlmacen(tmp);
        }
        setProducto(null);
      } catch (error) {
        variant = 'error';
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesión finalizada');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde');
          dispatch({ type: 'FETCHING_END' });
        }
      } finally {
        dispatch({ type: 'FETCHING_END' });
      }
    }
    if (origen) {
      const lista = inventarios[origen.C_Unica] || [];
      let tmpConsecutivo = origen.ConsAM;
      if (listaEspecial.includes(movSeleccionado)) {
        fetchData();
      } else {
        lista.forEach(element => {
          const flag = tmp.find(
            element2 =>
              element2.SKU === element.SKU &&
              element2.Lote === element.Lote &&
              element.Iidtrabajador === IidTrabajador
          );
          if (!flag) {
            tmp.push(element);
          }
        });
      }
      if ((bajaProducto || esPedido) && representanteSeleccionado) {
        if (!esPedido) {
          setDestino(origen);
          setEstado(origen.Estado || null);
          setDireccion(origen.Direccion || null);
          setColonia(origen.Colonia || null);
          fetchData();
          const tmp2 = [];
          const tmpAlmacenes = almacenes.filter(
            almacen =>
              almacen.Iidtrabajador ===
                representanteSeleccionado.IidTrabajador ||
              almacen.idSustituto === IidTrabajador
          );
          tmpAlmacenes.forEach(element => {
            const flag = tmp2.find(
              element2 => element2.label2 === element.label2
            );
            if (!flag) {
              tmp2.push(element);
            }
          });
          tmpConsecutivo = tmp2[0].ConsAM;
          setProductosAlmacen(tmp2);
        } else {
          console.log('aqui: ', esPedido);
          lista.forEach(element => {
            const flag = tmp.find(
              element2 =>
                element2.SKU === element.SKU &&
                element2.Lote === element.Lote &&
                element.Iidtrabajador === IidTrabajador
            );
            if (!flag) {
              tmp.push(element);
            }
          });
          tmpConsecutivo = origen.ConsAM;
          tmp.sort(function (a, b) {
            if (a.SKU < b.SKU) {
              return -1;
            }
            if (a.SKU > b.SKU) {
              return 1;
            }
            return 0;
          });
          setProductosAlmacen(tmp);
          if (pedidoSeleccionado) {
            const {
              detalles: { valores },
            } = pedidoSeleccionado;
            const lista = inventarios[origen.C_Unica] || productosAlmacenTodos;
            let tmpProductosAlmacen = [];
            valores.forEach((element, index) => {
              const tmpProduct = lista.filter(
                item => item.SKU === element.sku
              )[0];
              if (tmpProduct) {
                tmpProductosAlmacen.push(tmpProduct);
              }
            });
            tmpProductosAlmacen.sort(function (a, b) {
              if (a.SKU < b.SKU) {
                return -1;
              }
              if (a.SKU > b.SKU) {
                return 1;
              }
              return 0;
            });
            setProductosAlmacen(tmpProductosAlmacen);
          }
        }
      } else {
        setRepresentanteSeleccionado(null);
        setProductosAlmacen(tmp);
      }
      setProductos([]);
      if (movSeleccionado !== 'Movimiento Manual') {
        setConsecutivo(tmpConsecutivo);
      }
    } else {
      setProductosAlmacen([]);
      setProducto(null);
    }
  }, [origen]);

  useEffect(() => {
    setRepresentanteSeleccionado(null);
    if (listaEspecial.includes(movSeleccionado)) {
      const tmp = [];
      almacenes.forEach(almacen => {
        const flag = tmp.find(
          element => element.IidAlmacen === almacen.IidAlmacen
        );
        if (!flag && almacen?.Almacen !== 'SAP') {
          tmp.push(almacen);
        }
      });
      tmp.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      if (movSeleccionado === 'Entrada') {
        const tmpSalida = almacenes.filter(
          almacen =>
            almacen.ClaveAlmacen === '0000' && almacen.Centro === '0000'
        );
        setProductos([]);
        setOrigen(tmpSalida[0]);
      } else {
        setConsecutivo(tmp[0].ConsAM);
        setOrigen(tmp[0]);
        setOrigenes(tmp);
        setDestino(tmp[1]);
        setEstado(tmp[1].Estado || null);
        setDireccion(tmp[1].Direccion || null);
        setColonia(tmp[1].Colonia || null);
      }
      setDestinos(tmp);
    } else {
      getAlmacenesPropios();
    }
  }, [movSeleccionado]);

  useEffect(() => {
    async function fetchData(idRepresentante) {
      dispatch({ type: 'FETCHING_START' });
      try {
        const { tPedidos } = await getPedidosR(token, idRepresentante);
        const tmpPedidos = tPedidos.filter(almacen => almacen.Estatus === 1);
        setReportePedidos(tmpPedidos);
        setProducto(null);
      } catch (error) {
        variant = 'error';
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesión finalizada');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde');
          dispatch({ type: 'FETCHING_END' });
        }
      } finally {
        dispatch({ type: 'FETCHING_END' });
      }
    }
    if (representanteSeleccionado) {
      const { ConsAM } = representanteSeleccionado;
      const tmpAlmacenes = almacenes.filter(
        almacen =>
          almacen.Iidtrabajador === representanteSeleccionado.IidTrabajador ||
          almacen.idSustituto === IidTrabajador
      );
      tmpAlmacenes.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      if (!esPedido) {
        setOrigenes(tmpAlmacenes);
        setOrigen(tmpAlmacenes[0]);
        setEstado(tmpAlmacenes[0].Estado || null);
        setDireccion(tmpAlmacenes[0].Direccion || null);
        setColonia(tmpAlmacenes[0].Colonia || null);
        setConsecutivo(ConsAM);
      } else {
        fetchData(representanteSeleccionado.IidTrabajador);
        setDestinos(tmpAlmacenes);
        setDestino(tmpAlmacenes[0]);
        setEstado(tmpAlmacenes[0].Estado || null);
        setDireccion(tmpAlmacenes[0].Direccion || null);
        setColonia(tmpAlmacenes[0].Colonia || null);
        const tmpAlmacenesOrigen = almacenes.filter(
          almacen =>
            (almacen.Iidtrabajador === IidTrabajador &&
              almacen?.Almacen === 'SAP') ||
            (almacen.Iidtrabajador === IidTrabajador &&
              almacen?.Almacen === 'POSA')
        );
        tmpAlmacenesOrigen.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        setOrigenes(tmpAlmacenesOrigen);
      }
    } else {
      setConsecutivo(origen && origen.ConsAM ? origen.ConsAM : 0);
      setRepresentanteSeleccionado(null);
    }
  }, [representanteSeleccionado]);

  useEffect(() => {
    if (movSeleccionado !== 'Entrada') getAlmacenesPropios();
  }, [almacenes]);

  useEffect(() => {
    setProducto(null);
    if (pedidoSeleccionado) {
      const destinoPedido =
        destinos.filter(
          almacen => almacen.C_Unica === pedidoSeleccionado.C_Unica
        )[0] || null;
      setDestino(destinoPedido);
      setEstado(destinoPedido.Estado || null);
      setDireccion(destinoPedido.Direccion || null);
      setColonia(destinoPedido.Colonia || null);
      const {
        detalles: { valores },
      } = pedidoSeleccionado;
      const tmpProductos = [];
      const lista = inventarios[origen.C_Unica] || productosAlmacenTodos;
      let tmpProductosAlmacen = [];
      valores.forEach((element, index) => {
        const tmpProduct = lista.filter(item => item.SKU === element.sku)[0];
        if (tmpProduct) {
          tmpProductosAlmacen.push(tmpProduct);
        }
        const newProduct = {
          id: index,
          name: element.producto,
          sku: element.sku,
          lote: element.lote,
          cantidad: element.cantidad,
        };
        tmpProductos.push(newProduct);
      });
      tmpProductosAlmacen.sort(function (a, b) {
        if (a.SKU < b.SKU) {
          return -1;
        }
        if (a.SKU > b.SKU) {
          return 1;
        }
        return 0;
      });
      setProductosAlmacen(tmpProductosAlmacen);
      setProductosPedido(tmpProductos);
    }
  }, [pedidoSeleccionado]);

  function getAlmacenesPropios() {
    if (movSeleccionado !== 'Movimiento Manual') {
      const tmpAlmacenes = almacenes.filter(
        almacen =>
          (almacen.Iidtrabajador === IidTrabajador ||
            almacen.idSustituto === IidTrabajador) &&
          almacen?.Almacen !== 'SAP'
      );
      tmpAlmacenes.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setDestinos(tmpAlmacenes);
      setOrigenes(tmpAlmacenes);
      setDestino(null);
      setEstado('');
      setDireccion('');
      setColonia('');
      setOrigen(null);
    } else {
      const tmp = [];
      almacenes.forEach(almacen => {
        const flag = tmp.find(
          element => element.IidAlmacen === almacen.IidAlmacen
        );
        if (!flag && almacen?.Almacen !== 'SAP') {
          tmp.push(almacen);
        }
      });
      tmp.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setOrigenes(tmp);
      setDestinos(tmp);
    }
  }

  async function aceptar() {
    setOpenDialog(false);
    if (movSeleccionado !== 'Movimiento Manual') {
      guardar();
    } else {
      guardarHistorico();
    }
  }
  async function cerrar() {
    setOpenDialog(false);
  }

  useEffect(() => {
    if (flagRepresentante) {
      const tmp = tMovimientos.filter(mov => {
        return !mov.label.toLowerCase().includes('baja');
      });
      setListaMovimientos(tmp);
      setMovimiento(tmp[0]);
    } else {
      setListaMovimientos(tMovimientos);
      setMovimiento(tMovimientos[0]);
    }
  }, []);

  useEffect(() => {
    if (
      movimiento &&
      listaFlujo.includes(movimiento.label.toLowerCase()) &&
      !esPedido
    ) {
      setFlujoAlterno(true);
      setDestino(origen);
      setEstado(origen.Estado || null);
      setDireccion(origen.Direccion || null);
      setColonia(origen.Colonia || null);
    } else {
      setFlujoAlterno(false);
    }
  }, [movimiento]);

  const boxStyle = { marginTop: 10, textAlign: 'center', marginRight: 25 };
  const dividerStyle = { marginTop: 10 };

  const handleChangePedido = async event => {
    const flag = event.target.checked;
    setEsPedido(flag);
    if (!flag) {
      setRepresentanteSeleccionado(null);
      const tmpSalida = almacenes.filter(
        almacen => almacen.ClaveAlmacen !== '0000' && almacen.Centro !== '0000'
      );
      const tmpSalidaSAP = almacenes.filter(
        almacen => almacen.ClaveAlmacen === '0000' && almacen.Centro === '0000'
      );
      setProductos([]);
      setOrigen(tmpSalidaSAP[0]);
      setOrigenes(tmpSalida);
      setDestinos(tmpSalida);
      setDestino(null);
      setEstado('');
      setDireccion('');
      setColonia('');
      setPedidoSeleccionado(null);
      setPedidosRepresentante([]);
    }
  };

  function setReportePedidos(listaMovimientos = pedidos) {
    const tmp = [];
    let id = 1;
    if (listaMovimientos && listaMovimientos.length > 0) {
      listaMovimientos.forEach((element, index) => {
        const fecha = element.Fecha.split('T')[0];
        const row = {
          id,
          label: `${fecha} - ${element.FolioTraslado} - (${element.CentroDestino}-${element.AlmDestino})`,
          destino: `${element.CentroDestino}-${element.AlmDestino}`,
          FolioTraslado: element.FolioTraslado,
          C_Unica: `${element.CentroDestino}${element.AlmDestino}`,
          detalles: {
            valores: [
              {
                producto: element.Nombre,
                sku: element.SKU,
                lote: element.Lote,
                cantidad: element.Enviado,
              },
            ],
            extra: element,
          },
        };
        const flag = tmp.find(
          element2 =>
            element2.FolioTraslado === row.FolioTraslado &&
            element2.Tipo === row.Tipo
        );
        if (!flag) {
          id++;
          tmp.push(row);
        } else {
          const productoTmp = row.detalles.valores;
          flag.detalles.valores.push(productoTmp[0]);
        }
      });
    }
    setPedidosRepresentante(tmp);
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!admin ? (
          <>
            <Typography variant='h5' gutterBottom>
              Movimiento de Inventario
            </Typography>
          </>
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <Select
                labelId='demo-simple-select-standard-label'
                id='demo-simple-select-standard'
                value={movSeleccionado}
                onChange={(event, newValue) => {
                  const valor = event.target.value;
                  setMovSeleccionado(valor);
                }}
              >
                {listaMov.map(value => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant='h5' gutterBottom style={{ marginTop: 15 }}>
                de Inventario
              </Typography>
            </div>
          </>
        )}
        {movSeleccionado === 'Movimiento Manual' ? (
          <TextField
            id='outlined-number-AM'
            label='# AM'
            value={consecutivo}
            onChange={event => {
              const newValue = event.target.value;
              setConsecutivo(newValue);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 1, max: numMax },
            }}
            style={{ marginTop: 20 }}
          />
        ) : (
          <Typography variant='h6' gutterBottom>
            AM: #{consecutivo}
          </Typography>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginLeft: '5%',
        }}
      >
        {movSeleccionado === 'Entrada' ? (
          <>
            <div style={divStyle}>
              <FormControlLabel
                control={
                  <>
                    <Switch checked={esPedido} onChange={handleChangePedido} />
                  </>
                }
                label='Es pedido'
              />
            </div>
            {esPedido ? (
              <>
                <Divider style={dividerStyle}>
                  <Typography variant='h5' gutterBottom>
                    <ArrowDownwardIcon />
                    {`Selecciona el pedido`}
                    <ArrowDownwardIcon />
                  </Typography>
                </Divider>
                <div style={divStyle}>
                  <Stack spacing={1} sx={{ width: '65%' }}>
                    <Autocomplete
                      options={representantes}
                      id='auto-origen'
                      clearOnEscape
                      getOptionLabel={option => option.label}
                      value={representanteSeleccionado}
                      onChange={(event, newValue) => {
                        setRepresentanteSeleccionado(newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Representante'
                          variant='standard'
                          helperText='Selecciona el representante'
                        />
                      )}
                    />
                  </Stack>
                </div>
                <div style={divStyle}>
                  <Stack spacing={1} sx={{ width: '65%' }}>
                    <Autocomplete
                      options={pedidosRepresentante}
                      id='auto-destino'
                      clearOnEscape
                      value={pedidoSeleccionado}
                      onChange={(event, newValue) => {
                        setPedidoSeleccionado(newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Pedidos'
                          required
                          variant='standard'
                          helperText='Selecciona el pedido'
                        />
                      )}
                    />
                  </Stack>
                </div>

                <Divider style={dividerStyle}>
                  <Typography variant='h5' gutterBottom>
                    <ArrowDownwardIcon />
                    {`Selecciona el almacen Origen`}
                    <ArrowDownwardIcon />
                  </Typography>
                </Divider>
                <div style={divStyle}>
                  <Stack spacing={1} sx={{ width: '65%' }}>
                    <Autocomplete
                      options={origenes}
                      id='auto-origen'
                      clearOnEscape
                      value={origen}
                      onChange={(event, newValue) => {
                        setOrigen(newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Origen'
                          required
                          variant='standard'
                        />
                      )}
                    />
                  </Stack>
                </div>
              </>
            ) : null}

            <Divider style={dividerStyle}>
              <Typography variant='h5' gutterBottom>
                <ArrowDownwardIcon />
                {`Selecciona el almacen destino`}
                <ArrowDownwardIcon />
              </Typography>
            </Divider>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '65%' }}>
                <Autocomplete
                  options={destinos}
                  id='auto-destino'
                  clearOnEscape
                  value={destino}
                  onChange={(event, newValue) => {
                    if (newValue !== origen && newValue !== destino) {
                      setDestino(newValue);
                      setEstado(newValue.Estado || '');
                      setDireccion(newValue.Direccion || '');
                      setColonia(newValue.Colonia || '');
                    } else {
                      setDestino(null);
                      setEstado('');
                      setDireccion('');
                      setColonia('');
                      sendSnackA(types.MISMO_DESTINO);
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Destino'
                      required
                      variant='standard'
                      helperText='Selecciona el destino'
                    />
                  )}
                />
              </Stack>
            </div>
            {destino ? (
              <>
                <Box style={boxStyle} spacing={1} sx={{ minWidth: '30%' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editarDestino}
                        onChange={handleChangeDestino}
                      />
                    }
                    label='Modificar dirección'
                  />
                </Box>
                <br />
                {editarDestino ? (
                  <>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                      <Box
                        spacing={1}
                        sx={{ minWidth: '28%' }}
                        style={boxStyle}
                      >
                        <TextField
                          label='Poblacion'
                          inputProps={{ maxLength: 50 }}
                          style={{ width: '100%' }}
                          value={estado}
                          slotProps={{
                            input: {
                              readOnly: !editarDestino,
                            },
                          }}
                          onChange={event => {
                            const newValue = event.target.value;
                            setEstado(newValue);
                          }}
                        />
                      </Box>
                      <Box
                        spacing={1}
                        sx={{ minWidth: '20%' }}
                        style={boxStyle}
                      >
                        <TextField
                          label='Colonia'
                          inputProps={{ maxLength: 50 }}
                          style={{ width: '100%' }}
                          value={colonia}
                          slotProps={{
                            input: {
                              readOnly: !editarDestino,
                            },
                          }}
                          onChange={event => {
                            const newValue = event.target.value;
                            setColonia(newValue);
                          }}
                        />
                      </Box>
                      <Box
                        spacing={1}
                        sx={{ minWidth: '35%' }}
                        style={boxStyle}
                      >
                        <TextField
                          label='Domicilio'
                          inputProps={{ maxLength: 200 }}
                          style={{ width: '100%' }}
                          value={direccion}
                          multiline
                          minRows={1}
                          maxRows={2}
                          slotProps={{
                            input: {
                              readOnly: !editarDestino,
                            },
                          }}
                          onChange={event => {
                            const newValue = event.target.value;
                            setDireccion(newValue);
                          }}
                        />
                      </Box>
                    </Stack>
                    <br />
                  </>
                ) : null}
                <br />
              </>
            ) : null}
          </>
        ) : (
          <>
            <Stack>
              <Divider style={dividerStyle}>
                <Typography variant='h5' gutterBottom>
                  <ArrowDownwardIcon />
                  {`Selecciona tipo de Movimiento`}
                  <ArrowDownwardIcon />
                </Typography>
              </Divider>
              <Box style={boxStyle} spacing={1} sx={{ minWidth: '25%' }}>
                <Autocomplete
                  size='medium'
                  options={listaMovimientos}
                  id='auto-movimiento'
                  clearOnEscape
                  value={movimiento}
                  onChange={(event, newValue) => {
                    setMovimiento(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Movimiento'
                      variant='standard'
                    />
                  )}
                />
              </Box>
            </Stack>
            <Stack>
              <Divider style={dividerStyle}>
                <Typography variant='h5' gutterBottom>
                  <ArrowDownwardIcon />
                  {`Selecciona el almacen Origen`}
                  <ArrowDownwardIcon />
                </Typography>
              </Divider>
              <Box style={boxStyle} spacing={1} sx={{ minWidth: '30%' }}>
                <Autocomplete
                  options={origenes}
                  id='auto-origen'
                  clearOnEscape
                  value={origen}
                  onChange={(event, newValue) => {
                    if (!esPedido) {
                      if (newValue !== destino && newValue !== origen) {
                        setOrigen(newValue);
                        setProductos([]);
                        setProducto(null);
                      } else if (flujoAlterno) {
                        setOrigen(newValue);
                        setProductos([]);
                        setProducto(null);
                      } else {
                        setOrigen(null);
                        sendSnackA(types.MISMO_DESTINO);
                      }
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Origen'
                      required
                      variant='standard'
                    />
                  )}
                />
              </Box>
            </Stack>
            <Stack>
              {!listaEspecial.includes(movSeleccionado) &&
              !bajaProducto &&
              !flujoAlterno ? (
                <Divider style={dividerStyle}>
                  <Typography variant='h5' gutterBottom>
                    <ArrowDownwardIcon />
                    {`Selecciona el almacen Destino`}
                    <ArrowDownwardIcon />
                  </Typography>
                </Divider>
              ) : null}

              <Box style={boxStyle} spacing={1} sx={{ minWidth: '30%' }}>
                {!listaEspecial.includes(movSeleccionado) &&
                !bajaProducto &&
                !flujoAlterno ? (
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    label='Otra zona'
                  />
                ) : null}
                {checked && !bajaProducto && !flujoAlterno ? (
                  <Box style={boxStyle} spacing={1} sx={{ minWidth: '30%' }}>
                    <Autocomplete
                      options={otrasZonas}
                      id='auto-zonas'
                      clearOnEscape
                      value={otraZona}
                      onChange={(event, newValue) => {
                        setOtraZona(newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Zona'
                          variant='standard'
                          helperText='Selecciona la zona'
                        />
                      )}
                    />
                  </Box>
                ) : null}
              </Box>

              <Box style={boxStyle} spacing={1} sx={{ minWidth: '30%' }}>
                {bajaProducto ? (
                  <Autocomplete
                    options={representantes}
                    id='auto-origen'
                    clearOnEscape
                    getOptionLabel={option => option.label}
                    value={representanteSeleccionado}
                    onChange={(event, newValue) => {
                      setRepresentanteSeleccionado(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Representante'
                        variant='standard'
                        helperText='Selecciona el representante'
                      />
                    )}
                  />
                ) : flujoAlterno ? null : (
                  <Autocomplete
                    options={destinos}
                    id='auto-destino'
                    clearOnEscape
                    value={destino}
                    onChange={(event, newValue) => {
                      if (newValue !== origen && newValue !== destino) {
                        setDestino(newValue);
                        setEstado(newValue.Estado || null);
                        setDireccion(newValue.Direccion || null);
                        setColonia(newValue.Colonia || null);
                      } else {
                        setDestino(null);
                        setEstado('');
                        setDireccion('');
                        setColonia('');
                        sendSnackA(types.MISMO_DESTINO);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Destino'
                        required
                        variant='standard'
                      />
                    )}
                  />
                )}
              </Box>
              {destino ? (
                <>
                  <Box style={boxStyle} spacing={1} sx={{ minWidth: '30%' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={editarDestino}
                          onChange={handleChangeDestino}
                        />
                      }
                      label='Modificar dirección'
                    />
                  </Box>
                  <br />
                  {editarDestino ? (
                    <>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                      >
                        <Box
                          spacing={1}
                          sx={{ minWidth: '28%' }}
                          style={boxStyle}
                        >
                          <TextField
                            label='Poblacion'
                            inputProps={{ maxLength: 50 }}
                            style={{ width: '100%' }}
                            value={estado}
                            slotProps={{
                              input: {
                                readOnly: !editarDestino,
                              },
                            }}
                            onChange={event => {
                              const newValue = event.target.value;
                              setEstado(newValue);
                            }}
                          />
                        </Box>
                        <Box
                          spacing={1}
                          sx={{ minWidth: '20%' }}
                          style={boxStyle}
                        >
                          <TextField
                            label='Colonia'
                            inputProps={{ maxLength: 50 }}
                            style={{ width: '100%' }}
                            value={colonia}
                            slotProps={{
                              input: {
                                readOnly: !editarDestino,
                              },
                            }}
                            onChange={event => {
                              const newValue = event.target.value;
                              setColonia(newValue);
                            }}
                          />
                        </Box>
                        <Box
                          spacing={1}
                          sx={{ minWidth: '35%' }}
                          style={boxStyle}
                        >
                          <TextField
                            label='Domicilio'
                            inputProps={{ maxLength: 200 }}
                            style={{ width: '100%' }}
                            value={direccion}
                            multiline
                            minRows={1}
                            maxRows={2}
                            slotProps={{
                              input: {
                                readOnly: !editarDestino,
                              },
                            }}
                            onChange={event => {
                              const newValue = event.target.value;
                              setDireccion(newValue);
                            }}
                          />
                        </Box>
                      </Stack>
                      <br />
                    </>
                  ) : null}
                  <br />
                </>
              ) : null}
            </Stack>
          </>
        )}
        {esPedido ? (
          <>
            <Divider style={dividerStyle}>
              <Typography variant='h5' gutterBottom>
                <ArrowDownwardIcon />
                {`Producto solicitado`}
                <ArrowDownwardIcon />
              </Typography>
            </Divider>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '85%' }}>
                <Grid container wrap='nowrap' spacing={2}>
                  <Grid item xs={12}>
                    <TablaProductos
                      headers={headCellsPedidos}
                      productos={productosPedido ? productosPedido : []}
                      editItem={false}
                      deleteItem={false}
                      detalles={false}
                      mensaje={'Productos '}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </div>
          </>
        ) : null}
        <Stack>
          <Divider style={dividerStyle}>
            <Typography variant='h5' gutterBottom>
              <ArrowDownwardIcon />
              {`Selecciona el Producto`}
              <ArrowDownwardIcon />
            </Typography>
          </Divider>
          <Box style={boxStyle} spacing={1} sx={{ minWidth: '45%' }}>
            <Autocomplete
              size='medium'
              options={productosAlmacen}
              id='auto-producto'
              clearOnEscape
              value={producto}
              onChange={(event, newValue) => {
                const flag = productos.find(
                  element2 => element2.sku === newValue.SKU
                );
                if (!flag) setProducto(newValue);
                if (
                  listaEspecial.includes(movSeleccionado) &&
                  origen.Almacen === 'SAP'
                ) {
                  setCantidad(1);
                  setNumMax(maximaEntrada);
                } else {
                  try {
                    const { CantidadDisponible } = newValue;
                    setNumMax(CantidadDisponible);
                    setCantidad(CantidadDisponible > 0 ? 1 : 0);
                  } catch (error) {
                    setCantidad(0);
                  }
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  label='Producto'
                  variant='standard'
                  helperText='Selecciona el producto'
                />
              )}
            />
          </Box>
          {movSeleccionado !== 'Entrada' ? null : (
            <Box style={boxStyle} spacing={1} sx={{ minWidth: '35%' }}>
              <div style={{ marginTop: 10 }}>
                <TextField
                  id='outlined-number'
                  label='Lote'
                  value={loteProducto}
                  onChange={event => {
                    const newValue = event.target.value;
                    setLoteProducto(newValue);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 1, max: numMax },
                  }}
                />
              </div>
            </Box>
          )}
          <Box style={boxStyle} spacing={1} sx={{ minWidth: '35%' }}>
            <div style={{ marginTop: 10 }}>
              <TextField
                id='outlined-lote'
                label='Cantidad'
                type='number'
                required
                value={cantidad}
                onChange={handleChangeCantidad}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1, max: numMax },
                }}
                helperText={`${producto ? 'Cantidad maxima ' + numMax : ''}`}
              />
            </div>
          </Box>
          {movSeleccionado === 'Movimiento Manual' ? (
            <Box style={boxStyle} spacing={1} sx={{ minWidth: '35%' }}>
              <div style={{ marginTop: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label='Fecha AM'
                    inputFormat='DD/MM/YYYY'
                    maxDate={new Date()}
                    minDate={dayjs('2023-04-01')}
                    value={fechaFinal}
                    onChange={handleChangeFechaFinal}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </Box>
          ) : null}
        </Stack>
        <div style={divStyle}>
          {editar ? (
            <>
              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  setCantidad(1);
                  setNumMax(1);
                  setProducto(null);
                  setEditando(null);
                  setEditar(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                disabled={!validProduct}
                onClick={() => actualizarProducto()}
              >
                Actualizar
              </Button>
            </>
          ) : (
            <Button
              variant='contained'
              disabled={!validProduct}
              onClick={() => agregarProducto()}
            >
              Agregar
            </Button>
          )}
        </div>
      </div>
      <div style={divStyle}>
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <StyledPaper>
            <Grid container wrap='nowrap' spacing={2}>
              <Grid item xs={12}>
                <TablaProductos
                  headers={headCells}
                  productos={productos}
                  editItem={editItem}
                  deleteItem={deleteItem}
                  detalles={false}
                  mensaje={'Productos '}
                />
              </Grid>
            </Grid>
          </StyledPaper>
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 15,
          marginLeft: 35,
          marginRight: 35,
        }}
      >
        <TextField
          label='Comentarios de AM'
          multiline
          minRows={4}
          maxRows={7}
          inputProps={{ maxLength: 500 }}
          style={{ width: '100%' }}
          value={comentarios}
          onChange={event => {
            const newValue = event.target.value;
            setComentarios(newValue);
          }}
        />
      </div>
      <br />
      <div style={divStyle}>
        <Button variant='contained' color='error' onClick={() => cancelar()}>
          Cancelar
        </Button>
        <Button
          variant='contained'
          disabled={validarDatos()}
          onClick={() => setOpenDialog(true)}
        >
          {flujoAlterno ? 'solicitar' : 'Guardar'}
        </Button>
      </div>
      <br />
      <br />
      <Dialogo
        abrir={openDialog}
        cerrar={cerrar}
        titulo={titulo}
        mensaje={mensaje}
        aceptar={aceptar}
      />
    </>
  );
};

export default Traspaso;
