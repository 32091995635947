export const formatCurrency = value => {
  const number =
    !isNaN(parseFloat(value)) && String(value).trim() !== ''
      ? parseFloat(value)
      : 0;
  return String('$' + parseFloat(number).toFixed(2)).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    '$1,'
  );
};

export const getFechaHora = () => {
  const today = new Date();
  const date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const time =
    today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  return {
    date,
    time,
  };
};

const regexStrongPassword = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
);

const regexEmail = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$');

export const validatePassword = value => {
  let result = 'Débil';
  if (regexStrongPassword.test(value)) {
    result = 'Fuerte';
  }
  return result;
};

export const validateEmail = value => {
  return regexEmail.test(value);
};


export function validarFolio(number, width = 5) {
  let numberOutput = Math.abs(number); /* Valor absoluto del número */
  let length = number.toString().length; /* Largo del número */
  let zero = '0'; /* String de cero */
  if (width <= length) {
    if (number < 0) {
      return ('-' + numberOutput.toString());
    } else {
      return numberOutput.toString();
    }
  } else {
    if (number < 0) {
      return ('-' + (zero.repeat(width - length)) + numberOutput.toString());
    } else {
      return ((zero.repeat(width - length)) + numberOutput.toString());
    }
  }
}