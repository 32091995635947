/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Button,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../actions/types';
import {
  getOVsAutorizacionR,
  getRecuperarPdf,
  setAutorizarCancelarOv,
  setCancelarOv,
  setRechazarCancelarOv,
} from '../../api';
import Dialogo from '../../components/DialogoAceptarCancelar/dialogo';
import { StyledPaper } from '../../components/Paper/Paper';
import TablaOVS from '../../components/TablaDeOVS/TablaOvs';
import TablaProductos from '../../components/TablaDeProductos/Tabla';
import { formatCurrency } from '../../Utils/validations';

const steps = ['Seleccionar OV', 'Seleccionar productos', 'Solicitar'];

const ventasHeadCells = [
  {
    accessorKey: 'id',
    name: 'id',
    numeric: false,
    header: '#',
    size: 50,
  },
  {
    accessorKey: 'fecha',
    name: 'fecha',
    numeric: false,
    header: 'Fecha',
    size: 50,
  },
  {
    accessorKey: 'origen',
    name: 'origen',
    numeric: false,
    header: 'Almacen',
    size: 50,
  },
  {
    accessorKey: 'cliente',
    name: 'cliente',
    numeric: false,
    header: 'Cliente',
  },
  {
    accessorKey: 'FolioOV',
    FolioOV: 'FolioOV',
    numeric: false,
    header: 'Folio',
    size: 50,
  },
  {
    accessorKey: 'ptotal',
    name: 'ptotal',
    numeric: true,
    header: 'Total',
    size: 50,
  },
  {
    accessorKey: 'estatusOV',
    name: 'estatusOV',
    numeric: false,
    header: 'Estatus',
    size: 50,
  },
];

const solicitudesHeadCells = [
  {
    accessorKey: 'id',
    name: 'id',
    numeric: true,
    header: '#',
    size: 50,
  },
  {
    accessorKey: 'representante',
    name: 'representante',
    numeric: false,
    header: 'Representante',
    size: 50,
  },
  {
    accessorKey: 'fecha',
    name: 'fecha',
    numeric: false,
    header: 'Fecha Solicitud',
    size: 50,
  },
  {
    accessorKey: 'origen',
    name: 'origen',
    numeric: false,
    header: 'Almacen',
    size: 50,
  },
  {
    accessorKey: 'cliente',
    name: 'cliente',
    numeric: false,
    header: 'Cliente',
  },
  {
    accessorKey: 'FolioOV',
    FolioOV: 'FolioOV',
    numeric: false,
    header: 'Folio',
    size: 50,
  },
  {
    accessorKey: 'ptotal',
    name: 'ptotal',
    numeric: true,
    header: 'Total',
    size: 50,
  },
  {
    accessorKey: 'estatusOV',
    name: 'estatusOV',
    numeric: false,
    header: 'Estatus',
    size: 50,
  },
];

const detalleVentas = [
  {
    id: 'name',
    name: 'name',
    label: 'Producto',
  },
  {
    id: 'sku',
    label: 'Sku',
  },
  {
    id: 'lote',
    label: 'Lote',
  },
  {
    id: 'cantidad',
    label: 'Cantidad',
  },
  {
    id: 'iva',
    label: 'IVA',
  },
  {
    id: 'ieps',
    label: 'IEPS',
  },
  {
    id: 'precioU',
    label: 'Precio U.',
  },
  {
    id: 'precioT',
    label: 'Total',
  },
];

const headCells = [
  {
    id: 'id',
    numeric: false,
    label: '#',
  },
  {
    id: 'name',
    numeric: false,
    label: 'Producto',
  },
  {
    id: 'codigo',
    numeric: false,
    label: 'Código',
  },
  {
    id: 'lote',
    numeric: true,
    label: 'Lote',
  },
  {
    id: 'cantidad',
    numeric: true,
    label: 'Cantidad',
  },

  {
    id: 'precio',
    numeric: true,
    label: 'Precio antes de impuestos',
  },
  {
    id: 'totalP',
    numeric: true,
    label: 'Total',
  },
  {
    id: 'acciones',
    numeric: false,
    label: 'Acciones',
  },
];

const detalleColumnas = [
  {
    id: 'iva',
    label: 'IVA',
  },
  {
    id: 'ieps',
    label: 'IEPS',
  },
  {
    id: 'precioU',
    label: 'Precio U.',
  },
  {
    id: 'precioT',
    label: 'Precio U. Neto',
  },
];

const columnasFinal = [
  {
    id: 'id',
    numeric: false,
    label: '#',
  },
  {
    id: 'name',
    numeric: false,
    label: 'Producto',
  },
  {
    id: 'codigo',
    numeric: false,
    label: 'Código',
  },
  {
    id: 'lote',
    numeric: true,
    label: 'Lote',
  },
  {
    id: 'cantidad',
    numeric: true,
    label: 'Cantidad',
  },
  {
    id: 'precio',
    numeric: true,
    label: 'Precio antes de impuestos',
  },
  {
    id: 'totalP',
    numeric: true,
    label: 'Total',
  },
];

const Motivos = [{ label: 'Devolución', value: 'Devolución' }];

const NotasCredito = () => {
  const {
    inventarios: {
      ordenesVenta,
      representante,
      usuario: { NombreRol, token },
    },
  } = useSelector(state => {
    return state;
  });
  const [rows, setRows] = useState([]);
  const [ordenSeleccionada, setOrdenSeleccionada] = useState(null);
  const [ordenSeleccionadaF, setOrdenSeleccionadaF] = useState(null);
  const [esRepresentante, setEsRepresentante] = useState(true);
  const [productos, setProductos] = useState([]);
  const [productosDetalle, setProductosDetalle] = useState([]);
  const [productosBack, setProductosBack] = useState([]);
  const [cantidad, setCantidad] = useState(1);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [numMax, setNumMax] = useState(100);
  const reportes = ['Nota de crédito', 'Cancelación'];
  const listaCor = ['Autorizar', 'Solicitar'];
  const [reporteCoor, setReporteCoor] = useState('Solicitar');
  const [reporte, setReporte] = useState('Nota de crédito');
  const [comentarios, setComentarios] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [titulo, setTitulo] = useState('Guardar AM');
  const [mensaje, setMensaje] = useState('¿Deseas guardar AM?');
  const [headers, setHeaders] = useState(ventasHeadCells);
  const [folioInterno, setFolioInterno] = useState(null);
  const [folioCFDI, setFolioCFDI] = useState(null);
  const listaRolRep = ['Representante', 'Sustituto'];

  const sendSnackA = (message, variant = 'success') => {
    enqueueSnackbar(message, { variant, autoHideDuration: 2500 });
  };
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  async function setReporteOVS(listaOrdenes = ordenesVenta) {
    const tmp = [];
    let id = 1;
    if (listaOrdenes && listaOrdenes.length > 0) {
      await listaOrdenes.forEach(element => {
        const { Estatus } = element;
        if (esRepresentante && Estatus !== 1) {
          return;
        }
        const fecha = element.Fecha.split('T')[0];
        const subtotal = element.SubTotal / element.Cantidad;
        const total = element.SubTotal + element.IVA + element.IEPS;
        let row = {};
        if ((esRepresentante || NombreRol === 'Admin') && Estatus === 1) {
          row = {
            id,
            fecha,
            origen: `${element.CentroOrigen}-${element.ClaveAlmancen}`,
            cliente: element.NombreCliente,
            FolioOV: element.FolioOV,
            ptotal: `${formatCurrency(element.Total)} ${element.Moneda}`,
            estatusOV:
              Estatus === 1
                ? 'Activo'
                : Estatus === 2
                ? 'Autorización Cancelación'
                : Estatus === 3
                ? 'Cancelado'
                : Estatus === 4
                ? 'Autorización NC'
                : 'Nota crédito',
            detalles: {
              valores: [
                {
                  producto: element.Nombre,
                  sku: element.SKU,
                  lote: element.Lote,
                  cantidad: element.Cantidad,
                  iva: `${formatCurrency(element.IVA)} ${element.Moneda}`,
                  ieps: `${formatCurrency(element.IEPS)} ${element.Moneda}`,
                  precioU: `${formatCurrency(subtotal)} ${element.Moneda}`,
                  precioN: `${formatCurrency(total)} ${element.Moneda}`,
                },
              ],
              extra: element,
            },
          };
        } else if (!esRepresentante && [2, 4].includes(Estatus)) {
          const { FechaSolicitud, NombreTrabajador } = element;
          const fecha = FechaSolicitud.split('T')[0];
          row = {
            id,
            representante: NombreTrabajador,
            fecha,
            origen: `${element.CentroOrigen}-${element.ClaveAlmancen}`,
            cliente: element.NombreCliente,
            FolioOV: element.FolioOV,
            ptotal: `${formatCurrency(element.Total)} ${element.Moneda}`,
            estatusOV:
              Estatus === 1
                ? 'Activo'
                : Estatus === 2
                ? 'Autorización Cancelación'
                : Estatus === 3
                ? 'Cancelado'
                : Estatus === 4
                ? 'Autorización NC'
                : 'Nota crédito',
            detalles: {
              valores: [
                {
                  producto: element.Nombre,
                  sku: element.SKU,
                  lote: element.Lote,
                  cantidad: element.Cantidad,
                  iva: `${formatCurrency(element.IVA)} ${element.Moneda}`,
                  ieps: `${formatCurrency(element.IEPS)} ${element.Moneda}`,
                  precioU: `${formatCurrency(subtotal)} ${element.Moneda}`,
                  precioN: `${formatCurrency(total)} ${element.Moneda}`,
                },
              ],
              extra: element,
            },
          };
        }
        const flag = tmp.find(element2 => element2.FolioOV === row.FolioOV);
        if (!flag && row?.FolioOV) {
          id++;
          tmp.push(row);
        } else {
          if (row?.FolioOV) {
            const productoTmp = row.detalles.valores;
            let valor1 = row.ptotal.split(' ')[0];
            valor1 = valor1.substring(1);
            valor1 = valor1.replace('$', '');
            valor1 = valor1.replace(',', '');
            let valor2 = flag.ptotal.split(' ')[0];
            valor2 = valor2.replace('$', '');
            valor2 = valor2.replace(',', '');
            let precioTtal = parseFloat(valor1) + parseFloat(valor2);
            flag.ptotal = `${formatCurrency(precioTtal)} ${element.Moneda}`;
            flag.detalles.valores.push(productoTmp[0]);
          }
        }
      });
    }
    setRows(tmp);
  }

  function limpiarFiltros() {
    try {
      dispatch({ type: 'FETCHING_START' });
      setReporteOVS(ordenesVenta);
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      sendSnackA(mensaje, 'error');
      dispatch({ type: 'FETCHING_END' });
    }
  }

  async function seleccionar(linea) {
    const folio = Object.keys(linea)[0];
    const tmpProductos = [];
    const tmpOv = (
      esRepresentante || NombreRol === 'Admin'
        ? ordenesVenta
        : representante.tOVs
    ).filter(item => item.FolioOV === folio);
    tmpOv.forEach((prod, index) => {
      const {
        Nombre,
        SKU,
        Cantidad,
        SubTotal,
        Total,
        IVA,
        IEPS,
        CantidadRegresa,
        Lote,
      } = prod;
      const cantidadTotal =
        esRepresentante || NombreRol === 'Admin'
          ? Cantidad - CantidadRegresa
          : CantidadRegresa;
      const precioUnitario =
        esRepresentante || NombreRol === 'Admin'
          ? SubTotal / cantidadTotal
          : SubTotal / Cantidad;
      const precioUnitarioTotal =
        esRepresentante || NombreRol === 'Admin'
          ? Total / cantidadTotal
          : Total / Cantidad;
      const newProduct = {
        id: index + 1,
        name: Nombre,
        sku: SKU,
        lote: Lote,
        cantidad: cantidadTotal,
        precio:
          esRepresentante || NombreRol === 'Admin'
            ? SubTotal
            : precioUnitario * CantidadRegresa,
        total:
          esRepresentante || NombreRol === 'Admin'
            ? Total
            : precioUnitarioTotal * CantidadRegresa,
        acciones: 5,
        detalles: {
          tmpBack: prod,
          valores: [
            {
              iva: IVA,
              ieps: IEPS,
              precioU: precioUnitario,
              precioN: precioUnitarioTotal,
            },
          ],
        },
      };
      tmpProductos.push(newProduct);
    });
    setProductos(tmpProductos);
    setProductosBack(tmpProductos);
    setOrdenSeleccionada(folio || false);
    setOrdenSeleccionadaF(tmpOv[0]);
    if (!esRepresentante && tmpOv[0] && reporteCoor === 'Autorizar') {
      const { Estatus } = tmpOv[0];
      mostrarDetalle(tmpProductos);
      setComentarios(tmpOv[0]?.ComentariosCancelacion);
      setReporte(
        Estatus === 2 ? 'Autorización Cancelación' : 'Autorización NC'
      );
    }
  }

  async function mostrarDetalle(listaProductos = productos) {
    const tmpProductos = [];
    listaProductos.forEach((prod, index) => {
      const newProduct = {
        ...prod,
      };
      delete newProduct.acciones;
      tmpProductos.push(newProduct);
    });
    setProductosDetalle(tmpProductos);
  }

  function deleteItem(id) {
    const newProducto = productos.filter(item => item.id !== id);
    setProductos(newProducto);
  }

  const handleChangeFolio = event => {
    const newValue = event.target.value;
    setFolioInterno(newValue);
  };

  const handleChangeFolioCFDI = event => {
    const newValue = event.target.value;
    setFolioCFDI(newValue);
  };

  function editItem(id) {
    const productoEdit = productos.filter(item => item.id === id)[0];
    const productTmp = productosBack.filter(
      item => item.sku === productoEdit.sku
    )[0];
    setCantidad(productTmp.cantidad);
    setNumMax(productTmp.cantidad);
    setEditando(productoEdit);
    setEditar(true);
  }

  useEffect(() => {
    const flagRepresentante = listaRolRep.includes(NombreRol);
    setEsRepresentante(flagRepresentante);
    setHeaders(flagRepresentante ? ventasHeadCells : solicitudesHeadCells);
    limpiarFiltros();
  }, []);

  useEffect(() => {
    if (activeStep === 2) {
      mostrarDetalle();
    }
  }, [activeStep]);

  const actualizarProducto = async () => {
    const { id, detalles, name, sku, lote } = editando;
    let tmpProduct = [...productos];
    tmpProduct = tmpProduct.filter(item => item.id !== id);
    const { precioU, precioN } = detalles.valores[0];
    const newProduct = {
      id,
      name,
      sku,
      lote,
      cantidad,
      precio: precioU * cantidad,
      total: precioN * cantidad,
      acciones: true,
      detalles,
    };
    tmpProduct.push(newProduct);
    tmpProduct = tmpProduct.sort(function (a, b) {
      return a.id - b.id;
    });
    setProductos(tmpProduct);
    setCantidad(1);
    setNumMax(1);
    setEditando(false);
    setEditar(false);
  };

  const validarPasoSiguiente = () => {
    let flag = false;
    if (activeStep === 0) {
      flag = !ordenSeleccionada;
    } else if (activeStep === 1) {
      flag = !(productos.length > 0);
    } else if (activeStep === 2) {
      flag = !(comentarios.length > 10);
    }
    return flag;
  };

  const validarAutorizacion = () => {
    let flag = false;
    if (ordenSeleccionadaF?.Motivo !== 'Cancelación') {
      flag = !(folioCFDI?.length > 5 && folioInterno?.length > 5);
    }
    return flag;
  };

  useEffect(() => {
    if (reporteCoor === 'Autorizar') {
      handleChangeRepresentante();
    } else {
      setReporteOVS();
    }
    setHeaders(ventasHeadCells);
  }, [reporteCoor]);

  const handleChangeCantidad = event => {
    const newValue = event.target.value;
    setCantidad(newValue <= numMax ? newValue : numMax);
  };

  const handleChangeTipo = event => {
    const valor = event.target.value;
    setReporte(valor);
  };

  useEffect(() => {
    setReporteOVS(ordenesVenta);
  }, [ordenesVenta]);

  async function aceptar() {
    if (
      esRepresentante ||
      (NombreRol === 'Admin' && reporteCoor === 'Solicitar')
    ) {
      let motivo = 'Devolución';
      if (reporte === 'Cancelación') {
        motivo = 'Cancelación';
      }
      const payload = {
        folio: ordenSeleccionadaF.FolioOV,
        productosDetalle,
        comentarios,
        reporte,
        motivo,
      };
      cerrar();
      dispatch({ type: 'FETCHING_START' });
      try {
        const { tOV } = await setCancelarOv(token, payload);
        if (tOV) {
          dispatch({
            type: 'ORDEN_VENTA_REMOVE',
            payload: { folio: ordenSeleccionadaF.FolioOV },
          });
          tOV.forEach(element => {
            dispatch({
              type: 'ORDEN_VENTA_ADD',
              payload: { ordeneVenta: element },
            });
          });
          dispatch({ type: 'FETCHING_END' });
          await new Promise(res => setTimeout(res, 1000));
          setActiveStep(0);
          setComentarios('');
        }
        sendSnackA('Se envió la solicitud');
      } catch (error) {
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesion finalizada', 'error');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde', 'error');
          dispatch({ type: 'FETCHING_END' });
        }
      }
    } else {
      const payload = {
        representante: parseInt(ordenSeleccionadaF.Representante),
        reporte: ordenSeleccionadaF.Motivo,
        folio: ordenSeleccionadaF.FolioOV,
        productos: productosDetalle,
        folioCFDI,
        folioInterno,
      };
      cerrar();
      dispatch({ type: 'FETCHING_START' });
      try {
        const { tOVs } = await setAutorizarCancelarOv(token, payload);
        if (tOVs) {
          const tiempo = 900;
          dispatch({
            type: 'REPRESENTANTE_FETCHING',
            payload: { representante: { tOVs } },
          });
          await new Promise(res => setTimeout(res, tiempo));

          await setReporteOVS(tOVs);
          const filename = `OV_${ordenSeleccionadaF.FolioOV}`;
          const payload = {
            folio: ordenSeleccionadaF.FolioOV,
            solicito: ordenSeleccionadaF.Iidtrabajador,
            TipoTraspaso: false,
            tipo: 'OV',
            estatus: ordenSeleccionadaF.Estatus + 1,
          };
          await getRecuperarPdf(token, payload, filename);
          await new Promise(res => setTimeout(res, tiempo));
          setOrdenSeleccionada(null);
          setOrdenSeleccionadaF(null);
          setFolioCFDI(null);
          setFolioInterno(null);
          dispatch({ type: 'FETCHING_END' });
        }
        sendSnackA('Se autorizó la solicitud');
      } catch (error) {
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesión finalizada', 'error');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde', 'error');
          dispatch({ type: 'FETCHING_END' });
        }
      }
    }
  }

  async function cerrar() {
    setOpenDialog(false);
  }

  async function rechazar() {
    const payload = {
      representante: parseInt(ordenSeleccionadaF.Representante),
      folio: ordenSeleccionadaF.FolioOV,
      productos: productosDetalle,
    };
    cerrar();
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tOVs } = await setRechazarCancelarOv(token, payload);
      const tiempo = 900;
      await setReporteOVS(tOVs);
      await new Promise(res => setTimeout(res, tiempo));
      dispatch({
        type: 'REPRESENTANTE_FETCHING',
        payload: { representante: { tOVs } },
      });
      setHeaders(solicitudesHeadCells);
      setOrdenSeleccionada(null);
      setOrdenSeleccionadaF(null);
      sendSnackA('Se rechazo la solicitud');
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada', 'error');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde', 'error');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  }

  const handleChangeRepresentante = async () => {
    try {
      dispatch({ type: 'FETCHING_START' });
      const { tOVs } = await getOVsAutorizacionR(token);
      const tiempo = 1000;
      await setReporteOVS(tOVs);
      dispatch({
        type: 'REPRESENTANTE_FETCHING',
        payload: { representante: { tOVs } },
      });
      setHeaders(solicitudesHeadCells);
      await new Promise(res => setTimeout(res, tiempo));
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      sendSnackA(mensaje, 'error');
      dispatch({ type: 'FETCHING_END' });
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', alignSelf: 'center' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={divStyle}>
            {esRepresentante ? (
              <div style={{ marginTop: 10 }}>
                <Typography variant='h4' gutterBottom>
                  Solicitar
                </Typography>
              </div>
            ) : (
              <div style={{ marginLeft: 10 }}>
                <Select
                  labelId='demo-simple-select-standard-label'
                  id='demo-simple-select-standard'
                  value={reporteCoor}
                  onChange={event => {
                    const newValue = event.target.value;
                    setReporteCoor(newValue);
                  }}
                  label='Tipo de reporte'
                >
                  {listaCor.map(value => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
            <div style={{ marginLeft: 10 }}>
              {reporteCoor !== 'Autorizar' ? (
                <Select
                  labelId='demo-simple-select-standard-label'
                  id='demo-simple-select-standard'
                  value={reporte}
                  onChange={handleChangeTipo}
                  label='Tipo de reporte'
                >
                  {reportes.map(value => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              ) : null}
            </div>
          </div>
        </div>
        {reporteCoor !== 'Autorizar' ? (
          <>
            <Stepper
              activeStep={activeStep}
              style={{
                marginTop: 15,
                marginRight: 35,
              }}
            >
              <Step key='Paso 1'>
                <StepLabel>{'Selecciona OV'}</StepLabel>
              </Step>
              <Step key='Paso 2'>
                <StepLabel>{'Selecciona productos'}</StepLabel>
              </Step>
              <Step key='Paso 3'>
                <StepLabel>{`Solicita ${reporte}`}</StepLabel>
              </Step>
            </Stepper>
            {activeStep === steps.length ? (
              <Fragment>
                <div style={divStyle}>
                  <Typography sx={{ mt: 2, mb: 1 }} variant='h5'>
                    {` Se solicito la ${reporte}`}
                  </Typography>
                </div>
                <div style={divStyle}>
                  <Typography sx={{ mt: 2, mb: 1 }} variant='h5'>
                    {`OV ${ordenSeleccionadaF?.FolioOV}`}
                  </Typography>
                </div>
                <div style={divStyle}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      onClick={handleReset}
                      variant='contained'
                      color='success'
                    >
                      Regresar
                    </Button>
                  </Box>
                </div>
              </Fragment>
            ) : (
              <>
                <div style={divStyle}>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant='contained'
                  >
                    Atrás
                  </Button>
                  {activeStep < steps.length - 1 ? (
                    <Button
                      onClick={handleNext}
                      variant='contained'
                      color='success'
                      disabled={validarPasoSiguiente()}
                    >
                      Siguiente
                    </Button>
                  ) : null}
                </div>
                {activeStep === 0 ? (
                  <Fragment>
                    <div style={divStyle}>
                      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <StyledPaper>
                          <div style={divStyle}>
                            <Typography variant='h5' gutterBottom>
                              Lista de OV
                            </Typography>
                          </div>
                          <Grid container wrap='nowrap' spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{
                                marginTop: 15,
                                marginRight: 35,
                              }}
                            >
                              <TablaOVS
                                headers={headers}
                                productos={rows}
                                detalles={detalleVentas}
                                mensaje={`Ordenes de Venta `}
                                seleccionado={seleccionar}
                              />
                            </Grid>
                          </Grid>
                        </StyledPaper>
                      </Box>
                    </div>
                    <Typography variant='h5' gutterBottom>
                      {ordenSeleccionada?.FolioOV || null}
                    </Typography>
                  </Fragment>
                ) : activeStep === 1 ? (
                  <div style={divStyle}>
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                      <StyledPaper>
                        <div style={divStyle}>
                          <Typography variant='h5' gutterBottom>
                            Lista de productos
                          </Typography>
                        </div>
                        <div style={divStyle}>
                          <Typography variant='body2' gutterBottom>
                            Los productos y cantidades que estén en la tabla, se
                            solicitara {reporte}
                          </Typography>
                          <Button
                            variant='contained'
                            onClick={() => setProductos(productosBack)}
                          >
                            Restablecer productos
                          </Button>
                        </div>
                        <br />
                        {editar ? (
                          <>
                            <div style={divStyle}>
                              <TextField
                                id='outlined-number'
                                label='Cantidad'
                                type='number'
                                required
                                value={cantidad}
                                onChange={handleChangeCantidad}
                                InputLabelProps={{
                                  shrink: true,
                                  readOnly: true,
                                }}
                                InputProps={{
                                  inputProps: { min: 1, max: numMax },
                                }}
                                helperText={'Cantidad maxima ' + numMax}
                              />
                            </div>
                            <div style={divStyle}>
                              <Button
                                variant='contained'
                                color='error'
                                onClick={() => {
                                  setCantidad(1);
                                  setEditando(null);
                                  setEditar(false);
                                }}
                              >
                                Cancelar
                              </Button>
                              <Button
                                variant='contained'
                                onClick={() => actualizarProducto()}
                              >
                                Actualizar
                              </Button>
                            </div>
                            <br />
                          </>
                        ) : null}
                        <Grid container wrap='nowrap' spacing={2}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginTop: 15,
                              marginRight: 35,
                            }}
                          >
                            <TablaProductos
                              headers={headCells}
                              productos={productos}
                              detalles={detalleColumnas}
                              mensaje={'Productos '}
                              deleteItem={deleteItem}
                              editItem={editItem}
                            />
                          </Grid>
                        </Grid>
                      </StyledPaper>
                    </Box>
                  </div>
                ) : activeStep === 2 ? (
                  <div style={divStyle}>
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                      <StyledPaper>
                        <Divider>
                          <Typography variant='h5' gutterBottom>
                            <ArrowDownwardIcon />
                            {`Datos de OV ${ordenSeleccionadaF?.FolioOV}`}
                            <ArrowDownwardIcon />
                          </Typography>
                        </Divider>
                        <div style={divStyle}>
                          <Stack spacing={1} sx={{ width: '50%' }}>
                            <TextField
                              label='Cliente'
                              InputLabelProps={{
                                shrink: true,
                                readOnly: true,
                              }}
                              value={`${ordenSeleccionadaF?.NombreCliente} (${ordenSeleccionadaF.ClaveCliente})`}
                            />
                          </Stack>
                          <Stack spacing={1} sx={{ width: '15%' }}>
                            <TextField
                              label='Almacen'
                              InputLabelProps={{
                                shrink: true,
                                readOnly: true,
                              }}
                              value={`${ordenSeleccionadaF?.CentroOrigen} (${ordenSeleccionadaF.ClaveAlmancen})`}
                            />
                          </Stack>
                          <Stack spacing={1} sx={{ width: '20%' }}>
                            <TextField
                              id='outlined-ordenCompra'
                              label='Orden de compra'
                              type='Text'
                              value={`${ordenSeleccionadaF?.NumOrden}`}
                              InputLabelProps={{
                                shrink: true,
                                readOnly: true,
                              }}
                            />
                          </Stack>
                        </div>
                        <br />
                        <div style={divStyle}>
                          <Stack spacing={1} sx={{ width: '25%' }}>
                            <TextField
                              label='Condiciones de pago'
                              value={`${ordenSeleccionadaF?.UsoCondicionPago}`}
                              InputLabelProps={{
                                shrink: true,
                                readOnly: true,
                              }}
                            />
                          </Stack>
                          <Stack spacing={1} sx={{ width: '13%' }}>
                            <TextField
                              label='Fecha OV'
                              InputLabelProps={{
                                shrink: true,
                                readOnly: true,
                              }}
                              value={`${
                                ordenSeleccionadaF?.Fecha.split('T')[0] || ''
                              }`}
                            />
                          </Stack>
                          <Stack spacing={1} sx={{ width: '30%' }}>
                            <TextField
                              label='Uso CFDI'
                              InputLabelProps={{
                                shrink: true,
                                readOnly: true,
                              }}
                              value={`${
                                ordenSeleccionadaF?.Label ||
                                ordenSeleccionadaF?.UsoCFDI
                              } (${ordenSeleccionadaF.Claveuso})`}
                            />
                          </Stack>
                          <Stack spacing={1} sx={{ width: '15%' }}>
                            <TextField
                              label='Moneda'
                              InputLabelProps={{
                                shrink: true,
                                readOnly: true,
                              }}
                              value={`${ordenSeleccionadaF?.Moneda}`}
                            />
                          </Stack>
                        </div>
                        <br />
                        <Divider>
                          <Typography variant='h5' gutterBottom>
                            <ArrowDownwardIcon />
                            Lista de productos
                            <ArrowDownwardIcon />
                          </Typography>
                        </Divider>
                        <div style={divStyle}></div>
                        <div style={divStyle}>
                          <Typography variant='h5' gutterBottom>
                            Los productos y cantidades que estén en la lista, se
                            solicitara Nota de Créditoss
                          </Typography>
                        </div>
                        <br />
                        <Grid container wrap='nowrap' spacing={2}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginTop: 15,
                              marginRight: 35,
                            }}
                          >
                            <TablaProductos
                              headers={columnasFinal}
                              productos={productosDetalle}
                              detalles={detalleColumnas}
                              mensaje={'Productos '}
                            />
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'stretch',
                            marginTop: 15,
                            marginRight: 35,
                            justifyContent: 'space-between',
                          }}
                        >
                          <Stack
                            spacing={1}
                            sx={{
                              width: '100%',
                            }}
                          >
                            <TextField
                              label={`Comentarios de ${reporte}`}
                              multiline
                              minRows={4}
                              maxRows={7}
                              inputProps={{ maxLength: 500 }}
                              value={comentarios}
                              style={{ width: '100%' }}
                              onChange={event => {
                                const newValue = event.target.value;
                                setComentarios(newValue);
                              }}
                            />
                          </Stack>
                        </div>
                        <br />
                        <div style={divStyle}>
                          <Button
                            color='warning'
                            onClick={handleReset}
                            sx={{ mr: 1 }}
                            variant='contained'
                          >
                            cancelar
                          </Button>
                          {activeStep === steps.length - 1 ? (
                            <Button
                              onClick={() => {
                                setTitulo(`Solicitar ${reporte}`);
                                setMensaje(
                                  `¿Deseas solicitar ${reporte} de OV ${ordenSeleccionadaF?.FolioOV}`
                                );
                                setOpenDialog(true);
                              }}
                              variant='contained'
                              color='success'
                              disabled={validarPasoSiguiente()}
                            >
                              Solicitar
                            </Button>
                          ) : null}
                        </div>
                      </StyledPaper>
                      <br />
                    </Box>
                  </div>
                ) : null}
              </>
            )}
          </>
        ) : (
          <>
            <Fragment>
              <div style={divStyle}>
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                  <StyledPaper>
                    <div style={divStyle}>
                      <Typography variant='h5' gutterBottom>
                        Lista de solicitudes
                      </Typography>
                    </div>
                    <Grid container wrap='nowrap' spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: 15,
                          marginRight: 35,
                        }}
                      >
                        <TablaOVS
                          headers={headers}
                          productos={rows}
                          detalles={detalleVentas}
                          mensaje={`Ordenes de Venta `}
                          seleccionado={seleccionar}
                        />
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Box>
              </div>
            </Fragment>
            {ordenSeleccionadaF?.FolioOV ? (
              <div style={divStyle}>
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                  <StyledPaper>
                    <Divider>
                      <Typography variant='h5' gutterBottom>
                        <ArrowDownwardIcon />
                        {`Datos de OV ${ordenSeleccionadaF?.FolioOV}`}
                        <ArrowDownwardIcon />
                      </Typography>
                    </Divider>
                    <div style={divStyle}>
                      <Stack spacing={1} sx={{ width: '40%' }}>
                        <TextField
                          label='Cliente'
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                          value={`${ordenSeleccionadaF?.NombreCliente} (${ordenSeleccionadaF.ClaveCliente})`}
                        />
                      </Stack>
                      <Stack spacing={1} sx={{ width: '20%' }}>
                        <TextField
                          label='Almacen'
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                          value={`${ordenSeleccionadaF?.CentroOrigen} (${ordenSeleccionadaF.ClaveAlmancen})`}
                        />
                      </Stack>
                      <Stack spacing={1} sx={{ width: '20%' }}>
                        <TextField
                          id='outlined-ordenCompra'
                          label='Orden de compra'
                          type='Text'
                          value={`${ordenSeleccionadaF?.NumOrden}`}
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                        />
                      </Stack>
                    </div>
                    <br />
                    <div style={divStyle}>
                      <Stack spacing={1} sx={{ width: '25%' }}>
                        <TextField
                          label='Condiciones de pago'
                          value={`${ordenSeleccionadaF?.UsoCondicionPago}`}
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                        />
                      </Stack>
                      <Stack spacing={1} sx={{ width: '13%' }}>
                        <TextField
                          label='Fecha OV'
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                          value={`${
                            ordenSeleccionadaF?.Fecha.split('T')[0] || ''
                          }`}
                        />
                      </Stack>
                      <Stack spacing={1} sx={{ width: '30%' }}>
                        <TextField
                          label='Uso CFDI'
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                          value={`${ordenSeleccionadaF?.UsoCFDI} (${ordenSeleccionadaF.Claveuso})`}
                        />
                      </Stack>
                      <Stack spacing={1} sx={{ width: '15%' }}>
                        <TextField
                          label='Moneda'
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                          value={`${ordenSeleccionadaF?.Moneda}`}
                        />
                      </Stack>
                    </div>
                    <div style={divStyle}>
                      <Stack
                        spacing={1}
                        sx={{
                          width: '100%',
                        }}
                      >
                        <TextField
                          label={`Comentarios de ${reporte}`}
                          multiline
                          minRows={4}
                          maxRows={7}
                          inputProps={{ maxLength: 500 }}
                          value={comentarios}
                          style={{ width: '100%' }}
                        />
                      </Stack>
                    </div>
                    <br />
                    <Divider>
                      <Typography variant='h5' gutterBottom>
                        <ArrowDownwardIcon />
                        Lista de productos solicitados
                        <ArrowDownwardIcon />
                      </Typography>
                    </Divider>
                    <div style={divStyle}>
                      <Grid container wrap='nowrap' spacing={2}>
                        <Grid item xs={12}>
                          <TablaProductos
                            headers={columnasFinal}
                            productos={productosDetalle}
                            detalles={detalleColumnas}
                            mensaje={'Productos '}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <br />
                    {ordenSeleccionadaF?.Motivo !== 'Cancelación' ? (
                      <>
                        <div style={divStyle}>
                          <TextField
                            label='Folio Interno'
                            type='Text'
                            value={folioInterno || ''}
                            onChange={handleChangeFolio}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TextField
                            label='Folio CFDI'
                            type='Text'
                            value={folioCFDI || ''}
                            onChange={handleChangeFolioCFDI}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                        <br />
                        <br />
                      </>
                    ) : null}
                    <div style={divStyle}>
                      <Button
                        color='warning'
                        onClick={() => {
                          rechazar();
                        }}
                        sx={{ mr: 1 }}
                        variant='contained'
                      >
                        Rechazar
                      </Button>
                      <Button
                        onClick={() => {
                          setTitulo(`${reporte}`);
                          setMensaje(
                            `¿Deseas Aceptar la ${reporte} de OV ${ordenSeleccionadaF?.FolioOV}`
                          );
                          setOpenDialog(true);
                        }}
                        variant='contained'
                        color='success'
                        disabled={validarAutorizacion()}
                      >
                        Autorizar
                      </Button>
                    </div>
                  </StyledPaper>
                  <br />
                </Box>
              </div>
            ) : null}
          </>
        )}
      </Box>
      <Dialogo
        abrir={openDialog}
        cerrar={cerrar}
        titulo={titulo}
        mensaje={mensaje}
        aceptar={aceptar}
      />
    </>
  );
};

export default NotasCredito;
