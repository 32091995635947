/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Divider,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../actions/types';
import {
  autorizarAM,
  cancelarAM,
  enviaEmailPdf,
  getAMByFolio,
  getFile,
  getMovimientosR,
  getRecuperarPdf,
  validarPedido,
} from '../../api';
import Dialogo from '../../components/DialogoAceptarCancelar/dialogo';
import Graficas from '../../components/Graficas/graficas';
import { StyledPaper } from '../../components/Paper/Paper';
import TablaProductos from '../../components/TablaDeProductos/Tabla';
import { formatCurrency, getFechaHora } from '../../Utils/validations';

const movimientosHeadCells = [
  {
    id: 'id',
    name: 'id',
    numeric: false,
    label: '#',
  },
  {
    id: 'fecha',
    name: 'fecha',
    numeric: false,
    label: 'Fecha',
  },
  {
    id: 'movimiento',
    name: 'movimiento',
    numeric: false,
    label: 'Movimiento',
  },
  {
    id: 'Tipo',
    name: 'Tipo',
    numeric: false,
    label: 'Tipo',
  },
  {
    id: 'origen',
    name: 'origen',
    numeric: false,
    label: 'Origen',
  },
  {
    id: 'destino',
    name: 'destino',
    numeric: false,
    label: 'Destino',
  },
  {
    id: 'FolioTraslado',
    name: 'FolioTraslado',
    numeric: false,
    label: 'Folio',
  },
  {
    id: 'estatusAM',
    name: 'estatusAM',
    numeric: false,
    label: 'Estatus',
  },
  {
    id: 'acciones',
    name: 'acciones',
    numeric: false,
    label: 'Acciones',
  },
];

const detalleMovimientos = [
  {
    id: 'name',
    label: 'Producto',
  },
  {
    id: 'sku',
    label: 'Sku',
  },
  {
    id: 'lote',
    label: 'Lote',
  },
  {
    id: 'cantidad',
    label: 'Cantidad',
  },
];

const ventasHeadCells = [
  {
    id: 'id',
    name: 'id',
    numeric: false,
    label: '#',
  },
  {
    id: 'fecha',
    name: 'fecha',
    numeric: false,
    label: 'Fecha',
  },
  {
    id: 'origen',
    name: 'origen',
    numeric: false,
    label: 'Almacen',
  },
  {
    id: 'cliente',
    name: 'cliente',
    numeric: false,
    label: 'Cliente',
  },
  {
    id: 'FolioOV',
    FolioOV: 'FolioOV',
    numeric: false,
    label: 'Folio',
  },
  {
    id: 'ptotal',
    name: 'ptotal',
    numeric: true,
    label: 'Total',
  },
  {
    id: 'estatusOV',
    name: 'estatusOV',
    numeric: false,
    label: 'Estatus',
  },
  {
    id: 'acciones',
    name: 'acciones',
    numeric: false,
    label: 'Acciones',
  },
];

const detalleVentas = [
  {
    id: 'name',
    name: 'name',
    label: 'Producto',
  },
  {
    id: 'sku',
    label: 'Sku',
  },
  {
    id: 'lote',
    label: 'Lote',
  },
  {
    id: 'cantidad',
    label: 'Cantidad',
  },
  {
    id: 'iva',
    label: 'IVA',
  },
  {
    id: 'ieps',
    label: 'IEPS',
  },
  {
    id: 'precioU',
    label: 'Precio U.',
  },
  {
    id: 'precioT',
    label: 'Total',
  },
];

const tipoMovES = [
  {
    id: 1,
    ClaveMov: 'Ent',
    DescripcionMov: 'Entrada',
    label: 'Entrada',
  },
  {
    id: 2,
    ClaveMov: 'Sal',
    DescripcionMov: 'Salida',
    label: 'Salida',
  },
];

const tipoEstatus = [
  {
    id: 1,
    DescripcionMov: 'Activo',
    label: 'Activo',
  },
  {
    id: 2,
    DescripcionMov: 'Autorización Cancelación',
    label: 'Autorización Cancelación',
  },
  {
    id: 3,
    DescripcionMov: 'Cancelado',
    label: 'Cancelado',
  },
  {
    id: 4,
    DescripcionMov: 'Autorización NC',
    label: 'Autorización NC',
  },
  {
    id: 5,
    DescripcionMov: 'Nota crédito',
    label: 'Nota crédito',
  },
];

const pedidosHeadCells = [
  {
    id: 'id',
    name: 'id',
    numeric: false,
    label: '#',
  },
  {
    id: 'fecha',
    name: 'fecha',
    numeric: false,
    label: 'Fecha',
  },
  {
    id: 'movimiento',
    name: 'movimiento',
    numeric: false,
    label: 'Movimiento',
  },
  {
    id: 'destino',
    name: 'destino',
    numeric: false,
    label: 'Destino',
  },
  {
    id: 'FolioTraslado',
    name: 'FolioTraslado',
    numeric: false,
    label: 'Folio',
  },
  {
    id: 'FolioAM',
    name: 'FolioAM',
    numeric: false,
    label: 'FolioAM',
  },
  {
    id: 'estatusAM',
    name: 'estatusAM',
    numeric: false,
    label: 'Estatus',
  },
  {
    id: 'acciones',
    name: 'acciones',
    numeric: false,
    label: 'Acciones',
  },
];

const detallePedidos = [
  {
    id: 'name',
    label: 'Producto',
  },
  {
    id: 'sku',
    label: 'Sku',
  },
  {
    id: 'lote',
    label: 'Lote',
  },
  {
    id: 'cantidad',
    label: 'Solicitado',
  },
  {
    id: 'recibido',
    label: 'Recibido',
  },
];

const tipoEstatusPedidos = [
  {
    id: 1,
    DescripcionMov: 'Activo',
    label: 'Activo',
  },
  {
    id: 2,
    DescripcionMov: 'AM Realizada',
    label: 'AM Realizada',
  },
  {
    id: 3,
    DescripcionMov: 'Cancelado',
    label: 'Cancelado',
  },
  {
    id: 4,
    DescripcionMov: 'En inventario',
    label: 'En inventario',
  },
];

const Reportes = () => {
  const {
    inventarios: {
      almacenes,
      inventarios,
      clientes,
      movimientos,
      ordenesVenta,
      configuraciones: { tMovimientos },
      usuario: { NombreRol, IidTrabajador, token },
      representantes,
      representante,
      pedidos,
    },
  } = useSelector(state => {
    return state;
  });
  let variant = 'error';
  const [reporte, setReporte] = useState('OV');
  const [cliente, setCliente] = useState(null);
  const [estatusMov, setEstatusMov] = useState(null);
  const [rows, setRows] = useState([]);
  const reportes = ['AM', 'OV', 'Pedidos'];
  const [headCells, setHeadCells] = useState(ventasHeadCells);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [origen, setOrigen] = useState(null);
  const [destino, setDestino] = useState(null);
  const [origenes, setOrigenes] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [listaClientes, setListaClientes] = useState(clientes);
  const [productosAlmacen, setProductosAlmacen] = useState([]);
  const [producto, setProducto] = useState(null);
  const [movimiento, setMovimiento] = useState(null);
  const [movimientoES, setMovimientoES] = useState(null);
  const [tituloGraficas, setTituloGraficas] = useState([
    'Clientes (Cantidad OVs)',
    'Productos (Cantidad)',
  ]);
  const [detalleColumnas, setDetalleColumnas] = useState(false);
  const [esRepresentante, setEsRepresentante] = useState(true);
  const [datosGraficaPastel, setDatosGraficaPastel] = useState([]);
  const [datosGraficaPastelProductos, setDatosGraficaPastelProductos] =
    useState([]);
  const [representanteSeleccionado, setRepresentanteSeleccionado] =
    useState(null);
  const [open, setOpen] = useState(false);
  const [openPedido, setOpenPedido] = useState(false);
  const [titulo, setTitulo] = useState('Cancelar AM');
  const [mensaje, setMensaje] = useState('');
  const [trasladoCancelar, setTrasladoCancelar] = useState('');
  const [trasladoAutorizar, setTrasladoAutorizar] = useState('');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [mostrarDinero, setMostrarDinero] = useState(true);
  const listaRolRep = ['Representante', 'Sustituto'];
  const [pedidoSeleccionado, setPedidoSeleccionado] = useState(null);
  const [amPedido, setAmPedido] = useState([]);
  const [amPedidoCantidades, setAmPedidoCantidades] = useState([]);

  const handleChangeFechaInicio = newValue => {
    setFechaInicio(newValue);
  };

  const handleChangeFechaFinal = newValue => {
    setFechaFinal(newValue);
  };

  const handleChange = event => {
    const valor = event.target.value;
    if (valor === 'AM') {
      setDetalleColumnas(detalleMovimientos);
      setHeadCells(movimientosHeadCells);
      setTituloGraficas(['Almacenes (Cantidad AMs)', 'Productos (Cantidad)']);
    } else if (valor === 'OV') {
      setHeadCells(ventasHeadCells);
      setDetalleColumnas(detalleVentas);
      setTituloGraficas(['Clientes (Cantidad OVs)', 'Productos (Cantidad)']);
    } else {
      setHeadCells(pedidosHeadCells);
      setDetalleColumnas(detallePedidos);
      setTituloGraficas([
        'Almacenes (Cantidad Pedidos)',
        'Productos (Cantidad)',
      ]);
    }
    setReporte(event.target.value);
  };

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  useEffect(() => {
    getAlmacenesPropios();
  }, [almacenes]);

  useEffect(() => {
    limpiarFiltros();
  }, [reporte]);

  function setReporteOVS(listaOrdenes = ordenesVenta) {
    const tmp = [];
    let flagDetalles = false;
    if (listaOrdenes && listaOrdenes.length > 0) {
      listaOrdenes.forEach(element => {
        const { Estatus } = element;
        const fecha = element.Fecha.split('T')[0];
        const subtotal = element.SubTotal;
        const total = element.Total;
        let acciones = 4;
        if (Estatus === 3) {
          acciones = 7;
        } else if ([1, 5].includes(Estatus)) {
          acciones = 2;
        }
        const row = {
          id: element.IidOrdenVenta,
          fecha,
          origen: `${element.CentroOrigen}-${element.ClaveAlmancen}`,
          cliente: element.NombreCliente,
          FolioOV: element.FolioOV,
          ptotal: `${formatCurrency(total)} ${element.Moneda}`,
          estatusOV:
            Estatus === 1
              ? 'Activo'
              : Estatus === 2
              ? 'Autorización Cancelación'
              : Estatus === 3
              ? 'Cancelado'
              : Estatus === 4
              ? 'Autorización NC'
              : 'Nota crédito',
          acciones,
          detalles: {
            valores: [
              {
                producto: element.Nombre,
                sku: element.SKU,
                lote: element.Lote,
                cantidad: element.Cantidad,
                iva: `${formatCurrency(element.IVA)} ${element.Moneda}`,
                ieps: `${formatCurrency(element.IEPS)} ${element.Moneda}`,
                precioU: `${formatCurrency(subtotal / element.Cantidad)} ${
                  element.Moneda
                }`,
                precioN: `${formatCurrency(total)} ${element.Moneda}`,
              },
            ],
            extra: element,
          },
        };
        const flag = tmp.find(
          element2 =>
            element2.FolioOV === row.FolioOV &&
            element2.estatusOV === row.estatusOV
        );
        if (!flag) {
          tmp.push(row);
        } else {
          const CantidadDisponible = row.cantidad + flag.cantidad;
          const productoTmp = row.detalles.valores;
          const regex = /[$,]|MXN|USD/g;
          let valor1 = row.ptotal.replace(regex, '');
          let valor2 = flag.ptotal.replace(regex, '');
          let precioTtal = parseFloat(valor1) + parseFloat(valor2);
          flag.ptotal = `${formatCurrency(precioTtal)} ${element.Moneda}`;
          flag.detalles.valores.push(productoTmp[0]);
        }
      });
      flagDetalles = detalleVentas;
    }
    const SKU = producto?.SKU || false;
    if (SKU && tmp && tmp.length > 0) {
      const tmp2 = [];
      tmp.forEach(character => {
        const nuevos = character.detalles.valores.filter(
          element => element.sku === SKU
        );
        character.detalles.valores = nuevos;
        tmp2.push(character);
      });
      setRows(tmp2);
      setDataGraficas(tmp2);
    } else {
      setRows(tmp);
      setDataGraficas(tmp);
    }
    setDetalleColumnas(flagDetalles);
    dispatch({ type: 'FETCHING_END' });
  }

  const handleChangeDinero = event => {
    const flag = event.target.checked;
    setMostrarDinero(flag);
  };

  function setReporteMovimientos(
    listaMovimientos = movimientos,
    trabajadorid = IidTrabajador
  ) {
    const tmp = [];
    let id = 1;
    let flagDetalles = false;
    let flagRecibe = false;
    if (listaMovimientos && listaMovimientos.length > 0) {
      listaMovimientos.forEach((element, index) => {
        const tipomov = tMovimientos.filter(
          mov => mov.IidMov === element.IidMov
        )[0].DescripcionMov;
        const fecha = element.Fecha.split('T')[0];
        if (
          (element.IiRecibe === trabajadorid &&
            element.TipoTraspaso === 'Entrada') ||
          (origenes.filter(
            origen => origen.IidAlmacen === element.IidAlmacenEntrada
          ) &&
            !['Admin', 'Coordinador'].includes(NombreRol))
        ) {
          flagRecibe = true;
        } else if (
          ((element.IiRecibe === trabajadorid &&
            element.TipoTraspaso === 'Entrada') ||
            element.IidSolicito === trabajadorid) &&
          ['Admin', 'Coordinador'].includes(NombreRol)
        ) {
          flagRecibe = true;
        } else if (
          (element.IiRecibe !== trabajadorid &&
            element.TipoTraspaso === 'Entrada') ||
          element.IidSolicito !== trabajadorid
        ) {
          return;
        }
        const { Estatus, IidMov, TipoTraspaso } = element;
        let acciones = 4;
        if (flagRecibe) {
          acciones = Estatus === 3 ? 7 : 2;
          if(['Admin'].includes(NombreRol) && Estatus === 1){
            acciones = 3
          }
        } else if (Estatus === 1 && TipoTraspaso === 'Salida') {
          if (IidMov === 3 && esRepresentante) {
            acciones = 2;
          } else {
            acciones = 3;
          }
        } else if ( (Estatus === 1 && TipoTraspaso === 'Entrada') || Estatus === 3) {
          acciones = 2;
        } else if ((Estatus === 6 || Estatus === 7) && esRepresentante) {
          acciones = 4;
        } else if (
          Estatus === 6 &&
          ['Admin', 'Coordinador'].includes(NombreRol)
        ) {
          acciones = 6;
        }

        const row = {
          id,
          fecha,
          tipomov,
          Tipo: TipoTraspaso,
          origen: `${element.CentroOrigen}-${element.AlmOrigen}`,
          destino: `${element.CentroDestino}-${element.AlmDestino}`,
          FolioTraslado: element.FolioTraslado,
          estatusAM:
            Estatus === 1
              ? 'Activo'
              : Estatus === 6
              ? 'Autorización'
              : Estatus === 7
              ? 'AM rechazada'
              : 'Cancelado',
          acciones,
          detalles: {
            valores: [
              {
                producto: element.Nombre,
                sku: element.SKU,
                lote: element.Lote,
                cantidad: element.Enviado,
              },
            ],
            extra: element,
          },
        };
        const flag = tmp.find(
          element2 =>
            element2.FolioTraslado === row.FolioTraslado &&
            element2.Tipo === row.Tipo
        );
        if (!flag) {
          id++;
          tmp.push(row);
        } else {
          const productoTmp = row.detalles.valores;
          flag.detalles.valores.push(productoTmp[0]);
        }
      });
      flagDetalles = detalleMovimientos;
    }
    const SKU = producto?.SKU || false;
    if (SKU && tmp && tmp.length > 0) {
      const tmp2 = [];
      tmp.forEach(character => {
        const nuevos = character.detalles.valores.filter(
          element => element.sku === SKU
        );
        character.detalles.valores = nuevos;
        tmp2.push(character);
      });
      setRows(tmp2);
      setDataGraficas(tmp2);
    } else {
      setRows(tmp);
      setDataGraficas(tmp);
    }
    setDetalleColumnas(flagDetalles);
    dispatch({ type: 'FETCHING_END' });
  }

  function setReportePedidos(
    listaMovimientos = pedidos,
    trabajadorid = IidTrabajador
  ) {
    const tmp = [];
    let id = 1;
    let flagDetalles = false;
    if (listaMovimientos && listaMovimientos.length > 0) {
      listaMovimientos.forEach((element, index) => {
        const fecha = element.Fecha.split('T')[0];
        const { Estatus, TipoTraspaso } = element;
        let acciones = 3;
        if (Estatus === 3 || Estatus === 4) {
          acciones = 7;
        } else if (Estatus === 1) {
          acciones = 2;
        } else if (Estatus === 2) {
          acciones = 8;
        }
        const row = {
          id,
          fecha,
          tipomov: TipoTraspaso,
          destino: `${element.CentroDestino}-${element.AlmDestino}`,
          FolioTraslado: element.FolioTraslado,
          FolioAm: element.FolioAm,
          estatusPedido:
            Estatus === 1
              ? 'Activo'
              : Estatus === 2
              ? 'AM Realizada'
              : Estatus === 3
              ? 'Cancelado'
              : 'En inventario',
          acciones,
          detalles: {
            valores: [
              {
                producto: element.Nombre,
                sku: element.SKU,
                lote: element.Lote,
                cantidad: element.Enviado,
                recibido: element.Recibido,
              },
            ],
            extra: element,
          },
        };
        const flag = tmp.find(
          element2 =>
            element2.FolioTraslado === row.FolioTraslado &&
            element2.Tipo === row.Tipo
        );
        if (!flag) {
          id++;
          tmp.push(row);
        } else {
          const productoTmp = row.detalles.valores;
          flag.detalles.valores.push(productoTmp[0]);
        }
      });
      flagDetalles = detallePedidos;
    }
    const SKU = producto?.SKU || false;
    if (SKU && tmp && tmp.length > 0) {
      const tmp2 = [];
      tmp.forEach(character => {
        const nuevos = character.detalles.valores.filter(
          element => element.sku === SKU
        );
        character.detalles.valores = nuevos;
        tmp2.push(character);
      });
      setRows(tmp2);
      setDataGraficas(tmp2);
    } else {
      setRows(tmp);
      setDataGraficas(tmp);
    }
    setDetalleColumnas(flagDetalles);
    dispatch({ type: 'FETCHING_END' });
  }

  function getAlmacenesPropios(idRepresentante = IidTrabajador) {
    const tmpAlmacenes = almacenes.filter(
      almacen =>
        almacen.Iidtrabajador === idRepresentante ||
        almacen.idSustituto === IidTrabajador
    );
    setOrigenes(tmpAlmacenes);
    setDestinos(tmpAlmacenes);
  }

  async function getDataFiltrada() {
    const fInicial = fechaInicio?.toJSON().split('T')[0] || false;
    const fFinal = fechaFinal?.toJSON().split('T')[0] || false;
    const IidMov = movimiento?.IidMov || false;
    const SKU = producto?.SKU || false;
    const AlmOrigen = origen?.ClaveAlmacen || false;
    const AlmDestino = destino?.ClaveAlmacen || false;
    const AlmOrigenCentro = origen?.Centro || false;
    const AlmDestinoCentro = destino?.Centro || false;
    const clienteSeleccionado = cliente?.ClaveCliente || false;
    const tipoMovES = movimientoES?.label || false;
    const estatusTipo = estatusMov?.id || false;
    if (reporte === 'AM') {
      setReporteMovimientos([]);
    } else {
      setReporteOVS([]);
    }
    dispatch({ type: 'FETCHING_START' });
    let tmpMovimientos = [];
    let lista = [];
    if (reporte === 'AM' && !representanteSeleccionado) {
      lista = [...movimientos];
    } else if (reporte === 'AM' && representanteSeleccionado) {
      const { tTraslados } = representante;
      lista = [...tTraslados];
    } else if (reporte === 'OV' && representanteSeleccionado) {
      const { tOVs } = representante;
      lista = [...tOVs];
    } else if (reporte === 'Pedidos' && !representanteSeleccionado) {
      lista = [...pedidos];
    } else {
      lista = [...ordenesVenta];
    }
    // Obtiene folios
    lista.forEach(element => {
      const fecha = element.Fecha.split('T')[0];
      let tmpElement = {};
      // Búsqueda básica para ams y ovs
      if (AlmOrigen && SKU) {
        let flag =
          element.SKU === SKU &&
          ((element.ClaveAlmancen === AlmOrigen &&
            element.CentroOrigen === AlmOrigenCentro) ||
            (element.AlmOrigen === AlmOrigen &&
              element.CentroOrigen === AlmOrigenCentro));
        if (flag) tmpElement = Object.assign({}, element);
      } else if (AlmOrigen && !SKU) {
        let flag =
          (element.ClaveAlmancen === AlmOrigen &&
            element.CentroOrigen === AlmOrigenCentro) ||
          (element.AlmOrigen === AlmOrigen &&
            element.CentroOrigen === AlmOrigenCentro);
        if (flag) tmpElement = Object.assign({}, element);
      } else if (!AlmOrigen && SKU) {
        let flag = element.SKU === SKU;
        if (flag) tmpElement = Object.assign({}, element);
      } else {
        tmpElement = Object.assign({}, element);
      }
      if (
        estatusTipo &&
        tmpElement &&
        (tmpElement.FolioTraslado || tmpElement.FolioOV)
      ) {
        let flag = tmpElement.Estatus === estatusTipo;
        if (!flag) tmpElement = {};
      }
      // Valida fecha
      if (fInicial && fFinal) {
        let flag = fecha >= fInicial && fecha <= fFinal;
        if (!flag) tmpElement = {};
      } else if (fInicial && !fFinal) {
        let flag = fecha >= fInicial;
        if (!flag) tmpElement = {};
      } else if (!fInicial && fFinal) {
        let flag = fecha <= fFinal;
        if (!flag) tmpElement = {};
      }
      // Si es ventas
      if (reporte === 'OV' && clienteSeleccionado) {
        let flag = tmpElement.ClaveCliente === clienteSeleccionado;
        if (!flag) tmpElement = {};
      } else if (AlmDestino && reporte === 'AM') {
        let flag =
          element.AlmDestino === AlmDestino &&
          element.CentroDestino === AlmDestinoCentro;
        if (!flag) tmpElement = {};
      }
      // Solo AM
      if (reporte === 'AM' && IidMov) {
        let flag = element.IidMov === IidMov;
        if (!flag) tmpElement = {};
      }
      if (tmpElement && (tmpElement.FolioTraslado || tmpElement.FolioOV)) {
        tmpMovimientos.push(tmpElement.FolioTraslado || tmpElement.FolioOV);
      }
      // Solo Pedidos
      if (tmpElement && (tmpElement.FolioTraslado || tmpElement.FolioOV)) {
        tmpMovimientos.push(tmpElement.FolioTraslado || tmpElement.FolioOV);
      }
    });
    // Para coordinador/Admin
    let result = [];
    const dataArr = new Set(tmpMovimientos);
    let dataLimpia = [...dataArr];
    if (reporte === 'AM' && !representanteSeleccionado) {
      if (tipoMovES) {
        result = movimientos.filter(
          element =>
            dataLimpia.includes(element.FolioTraslado) &&
            element.TipoTraspaso === tipoMovES
        );
      } else {
        result = movimientos.filter(element =>
          dataLimpia.includes(element.FolioTraslado)
        );
      }
    } else if (reporte === 'AM' && representanteSeleccionado) {
      const { tTraslados } = representante;
      if (tipoMovES) {
        result = tTraslados.filter(
          element =>
            dataLimpia.includes(element.FolioTraslado) &&
            element.TipoTraspaso === tipoMovES
        );
      } else {
        result = tTraslados.filter(element =>
          dataLimpia.includes(element.FolioTraslado)
        );
      }
    } else if (reporte === 'OV' && representanteSeleccionado) {
      const { tOVs } = representante;
      result = tOVs.filter(element => dataLimpia.includes(element.FolioOV));
    } else if (reporte === 'Pedidos' && !representanteSeleccionado) {
      if (tipoMovES) {
        result = pedidos.filter(
          element =>
            dataLimpia.includes(element.FolioTraslado) &&
            element.TipoTraspaso === tipoMovES
        );
      } else {
        result = pedidos.filter(element =>
          dataLimpia.includes(element.FolioTraslado)
        );
      }
    } else {
      result = ordenesVenta.filter(element =>
        dataLimpia.includes(element.FolioOV)
      );
    }
    await new Promise(res => setTimeout(res, 1000));
    // Formateo de resultados
    if (reporte === 'AM') {
      if (representanteSeleccionado) {
        const idRepresentante = representanteSeleccionado.IidTrabajador;
        setReporteMovimientos(result, idRepresentante);
      } else {
        setReporteMovimientos(result);
      }
    } else if (reporte === 'Pedidos') {
      if (representanteSeleccionado) {
        const idRepresentante = representanteSeleccionado.IidTrabajador;
        setReportePedidos(result, idRepresentante);
      } else {
        setReportePedidos(result);
      }
    } else {
      setReporteOVS(result);
    }
  }

  function setDataGraficas(lista) {
    let tmpResult = [];
    let tmpResultProductos = [];
    if (reporte === 'AM' && lista && lista.length > 0) {
      lista.forEach(element => {
        const {
          origen,
          detalles: { valores },
        } = element;
        const nombre = `${origen} ${
          element.Tipo === 'Salida' ? 'Salida' : 'Entrada'
        }`;
        let flag = tmpResult.find(element2 => element2[0] === nombre);
        const tmpElement = [nombre, 1];
        if (!flag) {
          tmpResult.push(tmpElement);
        } else {
          flag[1] = flag[1] + 1;
        }
        valores.forEach(element3 => {
          const { producto, cantidad } = element3;
          let flagProducto = tmpResultProductos.find(
            element2 => element2[0] === producto
          );
          const tmpElementProducto = [producto, cantidad];
          if (!flagProducto) {
            tmpResultProductos.push(tmpElementProducto);
          } else {
            flagProducto[1] = flagProducto[1] + cantidad;
          }
        });
      });
    } else if (reporte === 'Pedidos' && lista && lista.length > 0) {
      lista.forEach(element => {
        const {
          destino,
          detalles: { valores },
        } = element;
        const nombre = `${destino} Pedido`;
        let flag = tmpResult.find(element2 => element2[0] === nombre);
        const tmpElement = [nombre, 1];
        if (!flag) {
          tmpResult.push(tmpElement);
        } else {
          flag[1] = flag[1] + 1;
        }
        valores.forEach(element3 => {
          const { producto, cantidad } = element3;
          let flagProducto = tmpResultProductos.find(
            element2 => element2[0] === producto
          );
          const tmpElementProducto = [producto, cantidad];
          if (!flagProducto) {
            tmpResultProductos.push(tmpElementProducto);
          } else {
            flagProducto[1] = flagProducto[1] + cantidad;
          }
        });
      });
    } else if (reporte === 'OV' && lista && lista.length > 0) {
      lista.forEach(element => {
        const {
          detalles: {
            extra: { NombreCliente },
            valores,
          },
        } = element;
        let flag = tmpResult.find(element2 => element2[0] === NombreCliente);
        const tmpElement = [NombreCliente, 1];
        if (!flag) {
          tmpResult.push(tmpElement);
        } else {
          flag[1] = flag[1] + 1;
        }
        valores.forEach(element3 => {
          const { producto, cantidad } = element3;
          let flagProducto = tmpResultProductos.find(
            element2 => element2[0] === producto
          );
          const tmpElementProducto = [producto, cantidad, cantidad];
          if (!flagProducto) {
            tmpResultProductos.push(tmpElementProducto);
          } else {
            flagProducto[1] = flagProducto[1] + cantidad;
          }
        });
      });
    }
    setDatosGraficaPastel(tmpResult);
    setDatosGraficaPastelProductos(tmpResultProductos);
  }

  async function limpiarFiltros() {
    try {
      dispatch({ type: 'FETCHING_START' });
      setOrigen(null);
      setDestino(null);
      setProducto(null);
      setMovimiento(null);
      setFechaFinal(null);
      setFechaInicio(null);
      setEstatusMov(null);
      setMovimientoES(null);
      setCliente(null);
      setProducto(null);
      await new Promise(res => setTimeout(res, 1000));
      if (reporte === 'AM' && !representanteSeleccionado) {
        setReporteMovimientos(movimientos);
      } else if (reporte === 'AM' && representanteSeleccionado) {
        const { tTraslados } = representante;
        setReporteMovimientos(tTraslados);
      } else if (reporte === 'OV' && representanteSeleccionado) {
        const { tOVs } = representante;
        setReporteOVS(tOVs);
      } else if (reporte === 'Pedidos' && !representanteSeleccionado) {
        setReportePedidos(pedidos);
      } else {
        setReporteOVS(ordenesVenta);
      }
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
  }

  useEffect(() => {
    const flagRepresentante = listaRolRep.includes(NombreRol);
    setEsRepresentante(flagRepresentante);
    setRepresentanteSeleccionado(null);
    limpiarFiltros();
    setProducto(null);
  }, []);

  const validarDatos = () => {
    let flag = true;
    const fInicial = fechaInicio?.toJSON().split('T')[0] || false;
    const fFinal = fechaFinal?.toJSON().split('T')[0] || false;
    const tMov = movimiento?.IidMov || false;
    const tProd = producto?.SKU || false;
    const tOrigen = origen?.C_Unica || false;
    const tDestino = destino?.C_Unica || false;
    const tipoMovES = movimientoES?.label || false;
    const clienteSeleccionado = cliente?.NombreCliente || false;
    const estatusTipo = estatusMov?.label || false;
    const validacion = {
      clienteSeleccionado,
      estatusTipo,
      tipoMovES,
      fInicial,
      tDestino,
      tOrigen,
      fFinal,
      tProd,
      tMov,
    };
    const valores = Object.values(validacion);
    const cantidadValores = valores.length;
    let cantidad = 0;
    for (let i = 0; i < cantidadValores; i++) {
      if (!valores[i]) {
        cantidad++;
      }
    }
    flag = cantidadValores === cantidad;
    return flag;
  };

  useEffect(() => {
    if (origen) {
      if (representanteSeleccionado) {
        const lista = representante.tInventario[origen.C_Unica] || [];
        setProductosAlmacen(lista);
        setProducto(null);
      } else {
        const lista = inventarios[origen.C_Unica] || [];
        setProductosAlmacen(lista);
        setProducto(null);
      }
    } else {
      getAllProductos();
    }
  }, [origen]);

  useEffect(() => {
    getAllProductos();
  }, [origenes]);

  function getAllProductos() {
    dispatch({ type: 'FETCHING_START' });
    let lista = [];
    let tmp = [];
    origenes.forEach(element => {
      const tmpAlmacen = inventarios[element.C_Unica];
      lista = lista.concat(tmpAlmacen);
    });
    lista?.forEach(element => {
      const flag = tmp.find(
        element2 =>
          element2?.SKU === element?.SKU &&
          element2?.Lote === element?.Lote &&
          element?.Iidtrabajador === IidTrabajador
      );
      if (!flag && element) {
        tmp.push(element);
      }
    });
    setProductosAlmacen(tmp);
    dispatch({ type: 'FETCHING_END' });
  }

  function exportarInfo() {
    if (reporte === 'AM') {
      exportarAM();
    } else {
      exportarOV();
    }
  }

  function exportarAM() {
    const crearXLSX = [];
    const tmpRows = [...rows];
    let num = 1;
    const { date, time } = getFechaHora();
    const filename = `Concentrado_AM_${date}-${time}`;
    tmpRows.forEach(product => {
      const {
        InventarioDespues,
        NombreTrabajador,
        InventarioAntes,
        FechaCancelado,
        EmailSustituto,
        FolioTraslado,
        CentroDestino,
        CentroOrigen,
        AlmDestino,
        AlmOrigen,
        ClaveSAT,
        Enviado,
      } = product.detalles.extra;
      const { tipomov, fecha, Tipo, estatusAM, cantidad } = product;
      product.detalles.valores.forEach((element, index) => {
        const row = {
          num,
          producto: element.producto,
          sku: element.sku,
          lote: element.lote || '',
          claveSat: ClaveSAT,
        };
        row.representante = NombreTrabajador;
        row.claveAlmacenOrigen = AlmOrigen;
        row.centroAlmacenOrigen = CentroOrigen;
        row.claveAlmacenDestino = AlmDestino;
        row.centroAlmacenDestino = CentroDestino;
        row.movimiento = tipomov;
        row.tipo = Tipo;
        row.folioAM = FolioTraslado;
        row.estatus = estatusAM;
        row.cantidadProductos = Enviado;
        row.fechaAM = fecha;
        row.fechaCancelado = FechaCancelado ? FechaCancelado.split('T')[0] : '';
        row.InventarioAntes = InventarioAntes;
        row.InventarioDespues = InventarioDespues;
        row.EmailSustituto = EmailSustituto;
        num++;
        crearXLSX.push(row);
      });
    });
    getFile(crearXLSX, filename);
  }

  function exportarOV() {
    const crearXLSX = [];
    const tmpRows = [...rows];
    let num = 1;
    const { date, time } = getFechaHora();
    const filename = `Concentrado_OV_${date}-${time}`;
    tmpRows.forEach(orden => {
      const {
        ComentariosCancelacion,
        IidtrabajadorSustituto,
        InventarioDespues,
        ComentariosEnvio,
        NombreTrabajador,
        InventarioAntes,
        FolioInternoNC,
        FechaSolicitud,
        FechaCancelado,
        EmailSustituto,
        ClaveAlmancen,
        NombreCliente,
        CentroOrigen,
        ClaveCliente,
        FolioCFDINC,
        IEPSFactura,
        IVAFactura,
        Claveuso,
        SubTotal,
        NumOrden,
        FolioOV,
        UsoCFDI,
        Estatus,
        Motivo,
        Total,
        Fecha,
        Zona,
      } = orden.detalles.extra;
      orden.detalles.valores.forEach((element, index) => {
        let ivaP = element.iva.split(' ')[0];
        ivaP = ivaP.substring(1);
        ivaP = ivaP.replace('$', '');
        ivaP = ivaP.replace(',', '');
        let iepsP = element.ieps.split(' ')[0];
        iepsP = iepsP.substring(1);
        iepsP = iepsP.replace('$', '');
        iepsP = iepsP.replace(',', '');
        let subtotalP = element.precioU.split(' ')[0];
        subtotalP = subtotalP.substring(1);
        subtotalP = subtotalP.replace('$', '');
        subtotalP = subtotalP.replace(',', '');
        let totalP = element.precioN.split(' ')[0];
        totalP = totalP.substring(1);
        totalP = totalP.replace('$', '');
        totalP = totalP.replace(',', '');
        const row = {
          num,
          producto: element.producto,
          sku: element.sku,
          lote: element.lote || '',
          cantidad: element.cantidad,
          subtotal: Number.parseFloat(subtotalP),
          iva: Number.parseFloat(ivaP),
          ieps: Number.parseFloat(iepsP),
          total: Number.parseFloat(totalP),
        };
        row.zona = Zona;
        row.representante = NombreTrabajador;
        row.claveAlmacen = ClaveAlmancen;
        row.centroAlmacen = CentroOrigen;
        row.nombreCliente = NombreCliente;
        row.claveCliente = ClaveCliente;
        row.ordenVentaCliente = NumOrden;
        row.CFDI = Claveuso;
        row.usoCFDI = UsoCFDI;
        row.subtotal = SubTotal;
        row.totalIeps = IEPSFactura;
        row.totalIva = IVAFactura;
        row.totalPedido = Total;
        row.folioMovimiento = FolioOV;
        row.estatus =
          Estatus === 1
            ? 'Activo'
            : Estatus === 2
            ? 'Autorización Cancelación'
            : Estatus === 3
            ? 'Cancelado'
            : Estatus === 4
            ? 'Autorización NC'
            : 'Nota crédito';
        row.comentariosEnvio = ComentariosEnvio;
        row.fechaCreacion = Fecha.split('T')[0];
        row.fechaSolicitudCancelacionNC = FechaSolicitud
          ? FechaSolicitud.split('T')[0]
          : '';
        row.motivo = Motivo || '';
        row.ComentariosCancelacionNC = ComentariosCancelacion || '';
        row.FolioCFDINC = FolioCFDINC || '';
        row.FolioInternoNC = FolioInternoNC || '';
        row.fechaCanceladoNC = FechaCancelado
          ? FechaCancelado.split('T')[0]
          : '';
        row.InventarioAntes = InventarioAntes;
        row.InventarioDespues = InventarioDespues;
        row.EmailSustituto = EmailSustituto;
        num++;
        crearXLSX.push(row);
      });
    });
    getFile(crearXLSX, filename);
  }

  const handleClose = () => {
    setOpenPedido(false);
  };

  const handleChangeRepresentante = async () => {
    const idRepresentante = representanteSeleccionado?.IidTrabajador || IidTrabajador ;
    try {
      dispatch({ type: 'FETCHING_START' });
      const { tClientes, tOVs, tTraslados, tInventario } =
        await getMovimientosR(token, idRepresentante);
      const tiempo = 900;
      await new Promise(res => setTimeout(res, tiempo));
      dispatch({
        type: 'REPRESENTANTE_FETCHING',
        payload: {
          representante: { tClientes, tOVs, tTraslados, tInventario },
        },
      });
      setListaClientes(tClientes);
      getAlmacenesPropios(idRepresentante);
      if (reporte === 'AM') {
        setReporteMovimientos(tTraslados, idRepresentante);
      } else {
        setReporteOVS(tOVs);
      }
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
  };

  useEffect(() => {
    if (representanteSeleccionado) {
      handleChangeRepresentante();
    } else {
      dispatch({ type: 'FETCHING_START' });
      dispatch({
        type: 'REPRESENTANTE_FETCHING',
        payload: {
          representante: null,
        },
      });
      setListaClientes(clientes);
      getAlmacenesPropios();
      if (reporte === 'AM') {
        setReporteMovimientos();
      } else {
        setReporteOVS();
      }
      dispatch({ type: 'FETCHING_END' });
    }
  }, [representanteSeleccionado]);

  async function crearPdf(linea) {
    const folio =
      linea.detalles.extra?.FolioTraslado || linea.detalles.extra?.FolioOV;
    const solicito =
      linea.detalles.extra.IidSolicito || linea.detalles.extra.Iidtrabajador;
    const TipoTraspaso = linea.detalles.extra.TipoTraspaso || false;
    const estatusMov = linea.detalles.extra.Estatus || false;
    try {
      dispatch({ type: 'FETCHING_START' });
      const filename = `${
        reporte === 'AM' ? 'AM' : reporte === 'Pedidos' ? 'Pedido' : 'OV'
      }_${folio}`;
      const payload = {
        folio,
        solicito,
        TipoTraspaso,
        mostrarDinero,
        tipo: reporte,
        estatus: estatusMov,
      };
      await getRecuperarPdf(token, payload, filename);
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
  }

  async function enviarPdf(linea) {
    const folio =
      linea.detalles.extra?.FolioTraslado || linea.detalles.extra?.FolioOV;
    const solicito =
      linea.detalles.extra.IidSolicito || linea.detalles.extra.Iidtrabajador;
    const TipoTraspaso = linea.detalles.extra.TipoTraspaso || false;
    const estatusMov = linea.detalles.extra.Estatus || false;
    try {
      dispatch({ type: 'FETCHING_START' });
      const payload = {
        folio,
        solicito,
        TipoTraspaso,
        mostrarDinero,
        tipo: reporte,
        estatus: estatusMov,
      };
      await enviaEmailPdf(token, payload);
      dispatch({ type: 'FETCHING_END' });
      enqueueSnackbar('Correo envíado con éxito', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
  }

  async function cancelarMov(mov) {
    const { FolioTraslado } = mov;
    if (reporte === 'Pedidos') {
      setTitulo('Cancelar/Rechazar Pedido');
    } else {
      setTitulo('Cancelar/Rechazar AM');
    }
    setMensaje(`¿Deseas cancelar el folio ${FolioTraslado}?`);
    setTrasladoCancelar(mov);
    setTrasladoAutorizar(null);
    setOpen(true);
  }

  async function editItem(mov) {
    const { FolioTraslado, FolioAm } = mov;
    const esPedido = reporte === 'Pedidos';
    if (esPedido) {
      dispatch({ type: 'FETCHING_START' });
      setPedidoSeleccionado(mov);
      const { tTraslado } = await getAMByFolio(token, FolioAm);
      setAmPedido(tTraslado);
      const tmp = [];
      for (let index = 0; index < tTraslado.length; index++) {
        const element = tTraslado[index];
        tmp.push(element.Enviado);
      }
      setAmPedidoCantidades(tmp);
      setTitulo('Validar Pedido');
      setMensaje(
        `Selecciona las cantidades que te llegaron del pedido ${FolioTraslado} AM:${FolioAm}`
      );
      dispatch({ type: 'FETCHING_END' });
      setOpenPedido(true);
    } else {
      setTitulo('Autorizar AM');
      setMensaje(`¿Deseas autorizar el folio ${FolioTraslado}?`);
      setTrasladoAutorizar(mov);
      setTrasladoCancelar(null);
      setOpen(true);
    }
  }

  async function aceptar() {
    setOpen(false);
    try {
      dispatch({ type: 'FETCHING_START' });
      let folio;
      let solicito;
      let cancelar = false;
      if (trasladoCancelar?.FolioTraslado) {
        const {
          detalles: {
            extra: { IidSolicito },
          },
          FolioTraslado,
        } = trasladoCancelar;
        folio = FolioTraslado;
        solicito = IidSolicito;
        cancelar = true;
      } else {
        const {
          detalles: {
            extra: { IidSolicito },
          },
          FolioTraslado,
        } = trasladoAutorizar;
        folio = FolioTraslado;
        solicito = IidSolicito;
      }
      const esPedido = reporte === 'Pedidos';
      const payload = {
        folio,
        esPedido,
        solicito,
      };

      const { tInventario, tTraslado, tPedidos } = await (cancelar
        ? cancelarAM(token, payload)
        : autorizarAM(token, payload));
      if (tInventario && !esPedido) {
        dispatch({
          type: 'INVENTARIO_FETCHING',
          payload: { inventarios: tInventario },
        });
        dispatch({
          type: 'MOVIMIENTO_REMOVE',
          payload: { folio },
        });
        await tTraslado.forEach(element => {
          dispatch({
            type: 'MOVIMIENTO_ADD',
            payload: { movimiento: element },
          });
        });
        setReporte('OV');
        await new Promise(res => setTimeout(res, 100));
        setReporte('AM');
        handleChangeRepresentante();
      } else if (esPedido) {
        dispatch({
          type: 'PEDIDOS_FETCHING',
          payload: { pedidos: tPedidos },
        });
        setReporte('OV');
        await new Promise(res => setTimeout(res, 100));
        setReporte('Pedidos');
        handleChangeRepresentante();
      }
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
  }

  async function cerrar() {
    setOpen(false);
    setTrasladoCancelar('');
  }

  async function aceptarPedido() {
    setOpenPedido(false);
    try {
      dispatch({ type: 'FETCHING_START' });
      const tmp = [];
      for (let index = 0; index < amPedidoCantidades.length; index++) {
        const element = amPedido[index];
        try {
          element.Cantidad = amPedidoCantidades[index];
          element.Solicitado =
            pedidoSeleccionado.detalles.valores[index].cantidad;
          tmp.push(element);
        } catch (error) {
          console.log('')
        }
      }
      const cUnica = pedidoSeleccionado.destino.replace('-', '');
      const { AlmOrigen, CentroOrigen, FolioTraslado } = tmp[0];
      const cUnicaOrigen = `${CentroOrigen}${AlmOrigen}`;
      const FolioOrigen = FolioTraslado.substr(0, 4);
      const almacenOrigen = almacenes.filter(
        item => item.C_Unica === cUnicaOrigen && item.Folio === FolioOrigen
      )[0];
      const almacen = origenes.filter(item => item.C_Unica === cUnica)[0];
      const payload = {
        pedidoSeleccionado,
        productos: tmp,
        almacenOrigen,
        almacen,
      };

      const { tInventario, tPedidos } = await validarPedido(token, payload);
      dispatch({
        type: 'PEDIDOS_FETCHING',
        payload: { pedidos: tPedidos },
      });
      dispatch({
        type: 'INVENTARIO_FETCHING',
        payload: { inventarios: tInventario },
      });
      setReporte('OV');
      await new Promise(res => setTimeout(res, 100));
      setReporte('Pedidos');
      handleChangeRepresentante();
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
  }

  const handleChangeCantidad = (event, index, value) => {
    const newValue = parseInt(event.target.value);
    const tmp = [];
    for (let i = 0; i < amPedidoCantidades.length; i++) {
      const element =
        index === i
          ? parseInt(newValue <= value.Enviado ? newValue : value.Enviado)
          : amPedidoCantidades[i];
      tmp.push(element);
    }
    setAmPedidoCantidades(tmp);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={divStyle}>
          <div style={{ marginTop: 10 }}>
            <Typography variant='h5' gutterBottom>
              Reporte de
            </Typography>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Select
              labelId='demo-simple-select-standard-label'
              id='demo-simple-select-standard'
              value={reporte}
              onChange={handleChange}
              label='Tipo de reporte'
            >
              {reportes.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        {reporte === 'OV' ? (
          <FormControlLabel
            control={
              <>
                <Switch checked={mostrarDinero} onChange={handleChangeDinero} />
                <AttachMoneyIcon />
              </>
            }
            label='Mostrar dinero en PDF'
          />
        ) : null}
      </div>
      {esRepresentante ? null : (
        <div style={divStyle}>
          <Stack spacing={1} sx={{ width: '30%' }} style={{ marginTop: 20 }}>
            <Autocomplete
              options={representantes}
              id='auto-origen'
              clearOnEscape
              value={representanteSeleccionado}
              onChange={(event, newValue) => {
                setRepresentanteSeleccionado(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Representante'
                  variant='standard'
                  helperText='Selecciona el representante'
                />
              )}
            />
          </Stack>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={divStyle}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={1} sx={{ width: '25%' }} style={{ marginTop: 10 }}>
              <DesktopDatePicker
                label='Fecha inicio'
                inputFormat='DD/MM/YYYY'
                maxDate={fechaFinal || new Date()}
                value={fechaInicio}
                minDate={dayjs('2022-11-18')}
                onChange={handleChangeFechaInicio}
                renderInput={params => <TextField {...params} />}
              />
            </Stack>
            <Stack spacing={1} sx={{ width: '25%' }} style={{ marginTop: 10 }}>
              <DesktopDatePicker
                label='Fecha final'
                inputFormat='DD/MM/YYYY'
                maxDate={new Date()}
                minDate={fechaInicio || dayjs('2022-11-18')}
                value={fechaFinal}
                onChange={handleChangeFechaFinal}
                renderInput={params => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        <div style={divStyle}>
          {reporte === 'Pedidos' ? (
            <Stack spacing={1} sx={{ width: '25%' }}>
              <Autocomplete
                options={destinos}
                id='auto-destino'
                clearOnEscape
                value={destino}
                onChange={(event, newValue) => {
                  setDestino(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Almacen destino'
                    required
                    variant='standard'
                    helperText='Selecciona el Almacen destino'
                  />
                )}
              />
            </Stack>
          ) : (
            <Stack spacing={1} sx={{ width: '25%' }}>
              <Autocomplete
                options={origenes}
                id='auto-origen'
                clearOnEscape
                value={origen}
                onChange={(event, newValue) => {
                  setOrigen(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Almacen origen'
                    required
                    variant='standard'
                    helperText='Selecciona el Almacen origen'
                  />
                )}
              />
            </Stack>
          )}
          <Stack spacing={1} sx={{ width: '35%' }}>
            <Autocomplete
              size='medium'
              options={productosAlmacen}
              id='auto-producto'
              clearOnEscape
              value={producto}
              onChange={(event, newValue) => {
                setProducto(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label='Producto'
                  variant='standard'
                  helperText='Selecciona el producto'
                />
              )}
            />
          </Stack>
          <Stack spacing={1} sx={{ width: '20%' }}>
            <Autocomplete
              size='medium'
              options={reporte === 'Pedidos' ? tipoEstatusPedidos : tipoEstatus}
              id='auto-producto'
              clearOnEscape
              value={estatusMov}
              onChange={(event, newValue) => {
                setEstatusMov(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label='Estatus'
                  variant='standard'
                  helperText='Estatus'
                />
              )}
            />
          </Stack>
        </div>
        <div style={divStyle}></div>
        {reporte === 'AM' ? (
          <>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '35%' }}>
                <Autocomplete
                  options={destinos}
                  id='auto-destino'
                  clearOnEscape
                  value={destino}
                  onChange={(event, newValue) => {
                    if (newValue !== origen && newValue !== destino) {
                      setDestino(newValue);
                    } else {
                      setDestino(null);
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Almacen destino'
                      required
                      variant='standard'
                      helperText='Selecciona el Almacen destino'
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '25%' }}>
                <Autocomplete
                  size='medium'
                  options={tMovimientos}
                  id='auto-movimiento'
                  clearOnEscape
                  value={movimiento}
                  onChange={(event, newValue) => {
                    setMovimiento(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Movimiento'
                      variant='standard'
                      helperText='Selecciona tipo de movimiento'
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '25%' }}>
                <Autocomplete
                  size='medium'
                  options={tipoMovES}
                  id='auto-entradaSalida'
                  clearOnEscape
                  value={movimientoES}
                  onChange={(event, newValue) => {
                    setMovimientoES(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Entrada/Salida'
                      variant='standard'
                      helperText='Selecciona Entrada/Salida'
                    />
                  )}
                />
              </Stack>
            </div>
          </>
        ) : reporte === 'Pedidos' ? null : (
          <>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '70%' }}>
                <Autocomplete
                  size='medium'
                  options={listaClientes}
                  id='auto-cliente'
                  clearOnEscape
                  value={cliente}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Cliente'
                      variant='standard'
                      helperText='Selecciona el Cliente'
                    />
                  )}
                />
              </Stack>
            </div>
          </>
        )}
      </div>
      <div style={divStyle}>
        <Button
          variant='contained'
          color='warning'
          style={{ marginTop: 20 }}
          onClick={() => limpiarFiltros()}
        >
          Limpiar filtros
        </Button>
        <Button
          variant='contained'
          color='success'
          style={{ marginTop: 20 }}
          onClick={() => getDataFiltrada()}
          disabled={validarDatos()}
        >
          Filtrar información
        </Button>
        <Button
          variant='contained'
          color='success'
          style={{ marginTop: 20 }}
          onClick={() => exportarInfo()}
          disabled={rows.length === 0}
        >
          Exportar información
        </Button>
      </div>
      <br />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography align='center' sx={{ width: '100%' }}>
            Tabla
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={divStyle}>
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
              <StyledPaper>
                <Grid container wrap='nowrap' spacing={2}>
                  <Grid item xs={12}>
                    <TablaProductos
                      headers={headCells}
                      productos={rows}
                      detalles={detalleColumnas}
                      mensaje={`${
                        reporte === 'Pedidos' ? reporte : reporte + 's'
                      } `}
                      crearPdf={crearPdf}
                      enviarPdf={enviarPdf}
                      cancelarMov={cancelarMov}
                      editItem={editItem}
                    />
                  </Grid>
                </Grid>
              </StyledPaper>
            </Box>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography align='center' sx={{ width: '100%' }}>
            Graficas
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={divStyle}>
            <Graficas
              datosCircular={datosGraficaPastel}
              datosBarra={datosGraficaPastelProductos}
              title={tituloGraficas}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Dialogo
        abrir={open}
        cerrar={cerrar}
        titulo={titulo}
        mensaje={mensaje}
        aceptar={aceptar}
      />
      <React.Fragment>
        <Dialog open={openPedido} onClose={handleClose}>
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent>
            <Typography variant='subtitle1' gutterBottom>
              {mensaje}
            </Typography>
            <div style={divStyle}>
              <Stack spacing={2} sx={{ width: '70%' }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {amPedido.map((value, index) => (
                    <Grid size={{ xs: 2, sm: 4, md: 4 }} key={index}>
                      <TextField
                        id='outlined-number'
                        label={'Cantidad maxima ' + value.Enviado}
                        type='number'
                        required
                        style={{ marginTop: 20, padding: 15 }}
                        value={amPedidoCantidades[index]}
                        onChange={event => {
                          handleChangeCantidad(event, index, value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: 0, max: value.Enviado },
                        }}
                        helperText={`${value.SKU} - ${value.Nombre}`}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='warning' variant='contained'>
              Cancelar
            </Button>
            <Button
              onClick={aceptarPedido}
              autoFocus
              color='success'
              variant='contained'
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default Reportes;
