/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Grid, InputBase, Paper, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../actions/types';
import { getCierreAlmacen, getDatos, getFile, getInventario } from '../../api';
import { StyledPaper } from '../../components/Paper/Paper';
import TablaProductos from '../../components/TablaDeProductos/Tabla';
import { getFechaHora } from '../../Utils/validations';
const Home = () => {
  const {
    inventarios: {
      almacenes,
      inventarios,
      usuario: { NombreRol, token, IidTrabajador },
      representantes,
    },
  } = useSelector(state => {
    return state;
  });
  const [representanteOrigenes, setRepresentanteOrigenes] = useState([]);
  const [representanteInventarios, setRepresentanteInventarios] = useState([]);
  const [representanteOrigen, setRepresentanteOrigen] = useState(null);
  const [esRepresentante, setEsRepresentante] = useState(true);
  const [origen, setOrigen] = useState(null);
  const [origenes, setOrigenes] = useState([]);
  const listaRolRep = ['Representante', 'Sustituto'];
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const flagRepresentante = listaRolRep.includes(NombreRol);
  const { enqueueSnackbar } = useSnackbar();
  let variant = 'error';
  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 20,
  };
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Producto',
    },
    {
      id: 'sku',
      numeric: false,
      disablePadding: true,
      label: 'SKU',
    },
    {
      id: 'sat',
      numeric: false,
      disablePadding: true,
      label: 'Clave SAT',
    },
    {
      id: 'lote',
      numeric: true,
      disablePadding: false,
      label: 'Lote',
    },
    {
      id: 'iva',
      numeric: false,
      disablePadding: false,
      label: 'IVA',
    },
    {
      id: 'ieps',
      numeric: false,
      disablePadding: false,
      label: 'IEPS',
    },
    {
      id: 'cantidad',
      numeric: true,
      disablePadding: false,
      label: 'Cantidad disponible',
    },
  ];

  const detalleColumnas = [
    {
      id: 'id',
      label: 'id',
    },
    {
      id: 'bodega',
      label: 'Bodega',
    },
    {
      id: 'tipo',
      label: 'Tipo',
    },
    {
      id: 'cantidad',
      numeric: true,
      disablePadding: false,
      label: 'Cantidad disponible',
    },
    {
      id: 'rep',
      label: 'Representante',
    },
  ];

  const [productos, setProductos] = useState([]);
  const [rows, setRows] = useState(productos);
  const [searched, setSearched] = useState('');
  const dispatch = useDispatch();

  const cancelSearch = () => {
    if (searched !== '') {
      setSearched('');
      setRows(productos);
    }
  };

  const handleChange = event => {
    const filteredRows = productos.filter(row => {
      return (
        row?.name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.sku?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.sat?.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setRows(filteredRows);
    setSearched(event.target.value);
  };

  const handleChangeFechaInicio = newValue => {
    setFechaInicio(newValue);
  };

  const handleChangeFechaFinal = newValue => {
    setFechaFinal(newValue);
  };

  const handleChangeRepresentante = async () => {
    const idRepresentante = representanteOrigen.IidTrabajador;
    try {
      dispatch({ type: 'FETCHING_START' });
      const { tInventario } = await getInventario(
        token,
        idRepresentante,
        false
      );
      setRepresentanteInventarios(tInventario);
      if (origen) {
        const lista = tInventario[origen.C_Unica] || [];
        setFormato([lista]);
      } else if (tInventario) {
        const tmpAlmacenes = almacenes.filter(
          almacen =>
            almacen.Iidtrabajador === idRepresentante ||
            almacen.idSustituto === idRepresentante
        );
        tmpAlmacenes.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        setOrigenes(tmpAlmacenes);
        setFormato(tInventario);
      }
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(error);
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
  };

  const handleChangeAll = async () => {
    try {
      dispatch({ type: 'FETCHING_START' });
      const { tInventario } = await getInventario(token, IidTrabajador, true);
      setRepresentanteInventarios(tInventario);
      setFormato(tInventario);
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
  };

  const exportar = async () => {
    const crearXLSX = [];
    let num = 0;
    const { date, time } = getFechaHora();
    const filename = `Inventario_${date}-${time}`;
    dispatch({ type: 'FETCHING_START' });
    await rows.forEach(element => {
      element.detalles.valores.forEach(detalleP => {
        const row = {
          num: num + 1,
          producto: element.name,
          sku: element.sku,
          sat: element.ClaveSAT,
          lote: element.lote,
          cantidad: detalleP.cantidad,
          bodega: detalleP.bodega,
          representante: detalleP.rep,
        };
        if (num === 0) {
          row.fecha = `${date} ${time}`;
        }
        num++;
        crearXLSX.push(row);
      });
    });
    getFile(crearXLSX, filename);
    dispatch({ type: 'FETCHING_END' });
  };

  const cierreInventario = async () => {
    const crearXLSX = [];
    const inicio =
      fechaInicio
        ?.toJSON()
        .split('T')[0]
        .replace(/[^0-9 ]/g, '') || false;
    const final =
      fechaFinal
        ?.toJSON()
        .split('T')[0]
        .replace(/[^0-9 ]/g, '') || false;
    const filename = `CierreAlmacen_${origen.C_Unica}_${inicio}-${final}`;
    dispatch({ type: 'FETCHING_START' });
    try {
      dispatch({ type: 'FETCHING_START' });
      const params = {
        almacen: origen.ClaveAlmacen,
        centro: origen.Centro,
        inicio,
        final,
      };
      const { tInventario } = await getCierreAlmacen(token, params);
      await tInventario.forEach(element => {
        crearXLSX.push(element);
      });
      getFile(crearXLSX, filename);
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
    dispatch({ type: 'FETCHING_END' });
  };

  const actualizarInventario = async () => {
    dispatch({ type: 'FETCHING_START' });
    try {
      const {
        configuraciones,
        tAlmacenes,
        tInventario,
        tClientes,
        tOVs,
        tPedidos,
        tTraslados,
      } = await getDatos(token);
      setRows([]);
      dispatch({
        type: 'CONFIGURACION_FETCHING',
        payload: { configuraciones },
      });
      dispatch({
        type: 'ALMACENES_FETCHING',
        payload: { almacenes: tAlmacenes },
      });
      dispatch({
        type: 'INVENTARIO_FETCHING',
        payload: { inventarios: tInventario },
      });
      dispatch({
        type: 'CLIENTES_FETCHING',
        payload: { clientes: tClientes },
      });
      dispatch({
        type: 'MOVIMIENTOS_FETCHING',
        payload: { movimientos: tTraslados },
      });
      dispatch({
        type: 'ORDENES_VENTA_FETCHING',
        payload: { ordenesVenta: tOVs },
      });
      dispatch({
        type: 'PEDIDOS_FETCHING',
        payload: { pedidos: tPedidos },
      });
      setFormato(tInventario);
      dispatch({ type: 'FETCHING_END' });
    } catch (error) {
      console.log(JSON.stringify(error));
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      dispatch({ type: 'FETCHING_END' });
    }
    dispatch({ type: 'FETCHING_END' });
  };

  useEffect(() => {
    let origenTmo = null;
    let origenesTmp = [];
    if (!flagRepresentante) {
      origenesTmp = [...representantes];
    }
    origenesTmp.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    setEsRepresentante(flagRepresentante);
    setRepresentanteOrigenes(origenesTmp);
    setRepresentanteOrigen(origenTmo);
    setFormato();
    getAlmacenesPropios();
  }, []);

  function setFormato(inventarioProductos = inventarios) {
    let tmp = [];
    const values = Object.values(inventarioProductos);
    values.forEach(element => {
      element.map(articulo => {
        const almacen = almacenes.find(
          alma =>
            alma.ClaveAlmacen === articulo.ClaveAlmacen &&
            alma.Centro === articulo.Centro
        );
        if (!almacen && !articulo.Estatus) {
          return;
        }
        const nombreTrabajador = articulo.NombreTrabajador || '';
        const formateo = {
          name: articulo.Nombre,
          sku: articulo.SKU,
          sat: articulo.ClaveSAT,
          lote: articulo.Lote === null ? '' : articulo.Lote,
          iva: `${Math.trunc(articulo.PIva * 100)}%`,
          ieps: `${Math.trunc(articulo.PIEPS * 100)}%`,
          cantidad: articulo.CantidadDisponible,
          detalles: {
            valores: [
              {
                id: articulo.IidInventario,
                bodega: `${almacen.Almacen} (${articulo.Centro} -${articulo.ClaveAlmacen})`,
                tipo: almacen.TipoInventario,
                cantidad: articulo.CantidadDisponible,
                rep: nombreTrabajador,
              },
            ],
            extra: articulo,
          },
        };
        const flag = tmp.find(
          element2 =>
            element2.sku === formateo.sku && element2.lote === formateo.lote
        );
        if (!flag) {
          tmp.push(formateo);
        } else {
          if (nombreTrabajador !== '') {
            const flag2 = flag.detalles.valores.find(
              element2 =>
                element2.bodega === formateo.detalles.valores[0].bodega
            );
            if (flag2) {
              const nuevaBodega = formateo.detalles.valores;
              if (
                flag2.bodega !== nuevaBodega[0].bodega &&
                flag2.rep !== nuevaBodega[0].rep
              )
                flag.detalles.valores.push(nuevaBodega[0]);
            } else {
              const CantidadDisponible = formateo.cantidad + flag.cantidad;
              const nuevaBodega = formateo.detalles.valores;
              flag.cantidad = CantidadDisponible;
              flag.detalles.valores.push(nuevaBodega[0]);
            }
          }
        }
      });
    });
    tmp.sort(function (a, b) {
      return a.sku - b.sku;
    });
    setProductos(tmp);
  }

  useEffect(() => {
    setRows(productos);
  }, [productos]);

  useEffect(() => {
    if (!flagRepresentante) {
      setRows([]);
      if (representanteOrigen) {
        handleChangeRepresentante();
      } else {
        handleChangeAll();
      }
    }
  }, [representanteOrigen]);

  function getAlmacenesPropios() {
    if (!flagRepresentante) {
      const tmp = [];
      almacenes.forEach(almacen => {
        const flag = tmp.find(
          element => element.IidAlmacen === almacen.IidAlmacen
        );
        if (!flag && almacen?.Almacen !== 'SAP') {
          tmp.push(almacen);
        }
      });
      tmp.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setOrigenes(tmp);
    } else {
      const tmpAlmacenes = almacenes.filter(
        almacen =>
          almacen.Iidtrabajador === IidTrabajador ||
          almacen.idSustituto === IidTrabajador
      );
      tmpAlmacenes.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setOrigenes(tmpAlmacenes);
    }
  }

  useEffect(() => {
    if (flagRepresentante) {
      if (origen) {
        const lista = inventarios[origen.C_Unica] || [];
        setFormato([lista]);
      } else {
        setFormato();
      }
    } else {
      if (origen) {
        const lista = inventarios[origen.C_Unica] || [];
        setFormato([lista]);
      } else {
        setFormato(inventarios);
      }
    }
  }, [origen]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h5' gutterBottom>
          Existencias Generales
        </Typography>
        <Paper
          component='form'
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder='Búsqueda por nombre y sku'
            inputProps={{ 'aria-label': 'Búsqueda por nombre y sku' }}
            value={searched}
            onChange={handleChange}
          />
          <IconButton
            type='button'
            sx={{ p: '10px' }}
            aria-label='search'
            onClick={() => cancelSearch()}
          >
            {searched !== '' ? (
              <CloseIcon color='error' />
            ) : (
              <SearchIcon color='primary' />
            )}
          </IconButton>
        </Paper>
        <Stack spacing={1} sx={{ width: '40%' }}>
          <Autocomplete
            options={origenes}
            id='auto-origen'
            clearOnEscape
            value={origen}
            onChange={(event, newValue) => {
              setOrigen(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label='Origen'
                required
                variant='standard'
                helperText='Selecciona el origen'
              />
            )}
          />
        </Stack>

        {esRepresentante ? null : (
          <Stack spacing={1} sx={{ width: '30%' }} style={{ marginTop: 20 }}>
            <Autocomplete
              options={representanteOrigenes}
              id='auto-origen'
              clearOnEscape
              value={representanteOrigen}
              onChange={(event, newValue) => {
                setRepresentanteOrigen(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Representante'
                  variant='standard'
                  helperText='Selecciona el representante'
                />
              )}
            />
          </Stack>
        )}
        <Button
          variant='contained'
          color='success'
          style={{ marginTop: 20 }}
          onClick={() => exportar()}
          disabled={rows.length === 0}
        >
          Exportar inventario
        </Button>
        <Button
          variant='contained'
          color='secondary'
          style={{ marginTop: 20 }}
          onClick={() => actualizarInventario()}
        >
          Actualizar inventario
        </Button>
        <div style={divStyle}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={1} sx={{ width: '40%' }} style={{ marginTop: 10 }}>
              <DesktopDatePicker
                label='Fecha inicio'
                inputFormat='DD/MM/YYYY'
                maxDate={fechaFinal || new Date()}
                value={fechaInicio}
                minDate={dayjs('2022-11-18')}
                onChange={handleChangeFechaInicio}
                renderInput={params => <TextField {...params} />}
              />
            </Stack>
            <Stack spacing={1} sx={{ width: '40%' }} style={{ marginTop: 10 }}>
              <DesktopDatePicker
                label='Fecha final'
                inputFormat='DD/MM/YYYY'
                maxDate={new Date()}
                minDate={fechaInicio || dayjs('2022-11-18')}
                value={fechaFinal}
                onChange={handleChangeFechaFinal}
                renderInput={params => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        <Button
          variant='contained'
          color='warning'
          style={{ marginTop: 20 }}
          onClick={() => cierreInventario()}
          disabled={
            origen === null || fechaInicio === null || fechaFinal === null
          }
        >
          Cierre de inventario
        </Button>
      </div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <StyledPaper>
          <Grid container wrap='nowrap' spacing={2}>
            <Grid item xs={12}>
              <TablaProductos
                headers={headCells}
                productos={rows}
                detalles={detalleColumnas}
                mensaje={'Productos '}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
    </>
  );
};

export default Home;
