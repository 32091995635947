import CryptoJs from 'crypto-js';
const clave = process.env.REACT_APP_SIGNATURE

export const encripta = texto => {
  return CryptoJs.AES.encrypt(texto, clave).toString();
};

export const desencripta = texto => {
  const bytes = CryptoJs.AES.decrypt(texto, clave);
  const textoDescifrado = bytes.toString(CryptoJs.enc.Utf8);
  return textoDescifrado;
};
