/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientes,
  getFile,
  setClienteNuevo,
  updateCliente,
} from '../../api';
import { StyledPaper } from '../Paper/Paper';
import TablaProductos from '../TablaDeProductos/Tabla';
import { getFechaHora } from '../../Utils/validations';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nombre',
  },
  {
    id: 'codigo',
    numeric: false,
    disablePadding: true,
    label: 'Clave',
  },
  {
    id: 'rfc',
    numeric: false,
    disablePadding: false,
    label: 'RFC',
  },
  {
    id: 'tel',
    numeric: false,
    disablePadding: false,
    label: 'Teléfono',
  },
  {
    id: 'acciones',
    numeric: false,
    label: 'Acciones',
  },
];

const detalleColumnas = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'MP',
    numeric: true,
    disablePadding: false,
    label: 'Método Pago',
  },
  {
    id: 'Direccion',
    numeric: false,
    label: 'Direccion',
  },
  {
    id: 'Ciudad',
    numeric: false,
    label: 'Ciudad',
  },
  {
    id: 'CP',
    numeric: false,
    label: 'CP',
  },
  {
    id: 'Zona',
    numeric: false,
    label: 'Zona',
  },
  {
    id: 'Representante',
    numeric: false,
    label: 'Representante',
  },
];

export default function Clientes() {
  const {
    inventarios: {
      usuario: { token },
      representantes,
    },
  } = useSelector(state => {
    return state;
  });
  let variant = 'error';
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [listaDeClientes, setListaDeClientes] = useState([]);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [clienteNombre, setClienteNombre] = useState('');
  const [clienteComentarios, setClienteComentarios] = useState('');
  const [clienteClave, setClienteClave] = useState('');
  const [clienteRFC, setClienteRFC] = useState('');
  const [clienteMPago, setClienteMPago] = useState('');
  const [clienteDireccion, setClienteDireccion] = useState('');
  const [clienteCiudad, setClienteCiudad] = useState('');
  const [clienteZona, setClienteZona] = useState('');
  const [clienteCP, setClienteCP] = useState('');
  const [clienteCorreo, setClienteCorreo] = useState('');
  const [clienteTel, setClienteTel] = useState('');
  const [clienteRepresentante, setClienteRepresentante] = useState(null);
  const [searched, setSearched] = useState('');
  const [open, setOpen] = useState(false);

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  const sendSnackA = message => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  async function getAllClientes() {
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tClientes } = await getClientes(token);
      setFormato(tClientes);
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesión finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    } finally {
      dispatch({ type: 'FETCHING_END' });
    }
  }

  function setFormato(listaClientes) {
    let tmp = [];
    listaClientes.forEach(element => {
      const formateo = {
        id: element.IidCliente,
        name: element.NombreCliente,
        codigo: element.ClaveCliente,
        rfc: element.RFC,
        tel: element.Tel1,
        acciones: 1,
        extra: element,
        detalles: {
          valores: [
            {
              email: element.Email1,
              metodo: element.MetodoPago,
              direccion: element.Direccion,
              ciudad: element.Ciudad,
              cp: element.CP,
              zona: element.Zona,
              rep: element.label,
            },
          ],
        },
      };
      tmp.push(formateo);
    });
    setRows(tmp);
    setListaDeClientes(tmp);
  }
  const exportar = async () => {
    const crearXLSX = [];
    let num = 0;
    const { date, time } = getFechaHora();
    const filename = `Productos_${date}-${time}`;
    dispatch({ type: 'FETCHING_START' });
    rows.forEach(element => {
      const {
        id,
        name,
        codigo,
        rfc,
        tel,
        detalles: { valores },
        extra: { Comentarios },
      } = element;
      const { email, metodo, direccion, ciudad, cp, zona, rep } = valores[0];
      const row = {
        num: num + 1,
        id,
        nombre: name,
        código: codigo,
        rfc,
        tel,
        email,
        métodoPago: metodo,
        dirección: direccion,
        ciudad,
        cp,
        zona,
        rep,
        Comentarios,
      };
      num++;
      crearXLSX.push(row);
    });
    getFile(crearXLSX, filename);
    dispatch({ type: 'FETCHING_END' });
  };
  useEffect(() => {
    getAllClientes();
  }, []);

  async function editItem(id) {
    const productoEdit = rows.filter(item => item.id === id)[0];
    setEditando(productoEdit);
    setEditar(true);
    setOpen(true);
  }

  useEffect(() => {
    if (editando) {
      const {
        codigo,
        name,
        tel,
        rfc,
        detalles: { valores },
        extra: { Comentarios },
      } = editando;
      const { email, metodo, direccion, ciudad, cp, zona, rep } = valores[0];
      const productTmp = representantes.filter(item => item.label === rep)[0];
      setClienteClave(codigo || '');
      setClienteCorreo(email || '');
      setClienteNombre(name || '');
      setClienteTel(tel || '');
      setClienteComentarios(Comentarios || '');
      setClienteRepresentante(productTmp || null);
      setClienteRFC(rfc || '');
      setClienteMPago(metodo || '');
      setClienteDireccion(direccion || '');
      setClienteCiudad(ciudad || '');
      setClienteZona(zona || '');
      setClienteCP(cp || '');
    }
  }, [editando]);

  const handleChangeNombre = event => {
    const newValue = event.target.value;
    setClienteNombre(newValue);
  };

  const handleChangeClave = event => {
    const newValue = event.target.value;
    setClienteClave(newValue);
  };

  const handleChangeCorreo = event => {
    const newValue = event.target.value;
    setClienteCorreo(newValue);
  };

  const handleChangeTel = event => {
    const newValue = event.target.value;
    setClienteTel(newValue);
  };

  const handleChangeComent = event => {
    const newValue = event.target.value;
    setClienteComentarios(newValue);
  };

  const handleChangeRFC = event => {
    const newValue = event.target.value;
    setClienteRFC(newValue);
  };

  const handleChangeMPago = event => {
    const newValue = event.target.value;
    setClienteMPago(newValue);
  };

  const handleChangeDireccion = event => {
    const newValue = event.target.value;
    setClienteDireccion(newValue);
  };

  const handleChangeCiudad = event => {
    const newValue = event.target.value;
    setClienteCiudad(newValue);
  };

  const handleChangeZona = event => {
    const newValue = event.target.value;
    setClienteZona(newValue);
  };

  const handleChangeCP = event => {
    const newValue = event.target.value;
    setClienteCP(newValue);
  };

  const guardar = async nuevo => {
    const payload = {
      clienteRepresentante,
      clienteComentarios,
      clienteDireccion,
      clienteCorreo,
      clienteNombre,
      clienteCiudad,
      clienteClave,
      clienteMPago,
      clienteZona,
      clienteRFC,
      clienteTel,
      clienteCP,
      editando,
    };
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tClientes } = nuevo
        ? await setClienteNuevo(token, payload)
        : await updateCliente(token, payload);
      if (tClientes) {
        variant = 'success';
        sendSnackA('Se guardó el cliente con éxito');
        setFormato(tClientes);
        dispatch({ type: 'FETCHING_END' });
        limpiar();
      }
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const validarDatos = () => {
    if (
      clienteDireccion !== '' &&
      clienteCorreo !== '' &&
      clienteNombre !== '' &&
      clienteCiudad !== '' &&
      clienteClave !== '' &&
      clienteMPago !== '' &&
      clienteZona !== '' &&
      clienteRFC !== '' &&
      clienteCP !== ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  function limpiar() {
    setEditando(null);
    setEditar(false);
    setClienteNombre('');
    setClienteComentarios('');
    setClienteClave('');
    setClienteRFC('');
    setClienteMPago('');
    setClienteDireccion('');
    setClienteCiudad('');
    setClienteZona('');
    setClienteCP('');
    setClienteCorreo('');
    setClienteTel('');
    setClienteRepresentante(null);
    setOpen(false);
  }

  const cancelSearch = () => {
    if (searched !== '') {
      setSearched('');
      setRows(listaDeClientes);
    }
  };

  const handleChange = event => {
    const filteredRows = listaDeClientes.filter(row => {
      return (
        row?.name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.detalles?.valores[0]?.rep
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row?.rfc?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.detalles?.valores[0]?.direccion
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row?.codigo?.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setRows(filteredRows);
    setSearched(event.target.value);
  };

  const handleClose = () => {
    limpiar();
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={divStyle}>
          <Paper
            component='form'
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Búsqueda por nombre, clave y representante'
              inputProps={{ 'aria-label': 'Búsqueda por nombre y clave' }}
              value={searched}
              onChange={handleChange}
            />
            <IconButton
              type='button'
              sx={{ p: '10px' }}
              aria-label='search'
              onClick={() => cancelSearch()}
            >
              {searched !== '' ? (
                <CloseIcon color='error' />
              ) : (
                <SearchIcon color='primary' />
              )}
            </IconButton>
          </Paper>
        </div>
      </div>
      <div style={divStyle}>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='primary' size='medium' aria-label='add' sx={{ mr: 1 }}>
            <AddIcon onClick={() => setOpen(true)} />
          </Fab>
        </Box>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='warning' size='medium' aria-label='export' sx={{ mr: 1 }}>
            <FileDownloadIcon onClick={() => exportar()} />
          </Fab>
        </Box>
      </div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <StyledPaper>
          <Grid container wrap='nowrap' spacing={2}>
            <Grid item xs={12}>
              <TablaProductos
                headers={headCells}
                productos={rows}
                detalles={detalleColumnas}
                editItem={editItem}
                mensaje={'Clientes '}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        onClose={handleClose}
        maxWidth={'xl'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {editar ? 'Actualizar ' : 'Agregar '} cliente
        </DialogTitle>
        <DialogContent dividers={'paper'}>
          <div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '40%' }}>
                <TextField
                  id='outlined-nombre'
                  label='Nombre'
                  type='Text'
                  required
                  value={clienteNombre}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeNombre}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '20%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Clave cliente'
                  type='Text'
                  required
                  value={clienteClave}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeClave}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '20%' }}>
                <TextField
                  id='outlined-rfc'
                  label='RFC'
                  type='Text'
                  required
                  value={clienteRFC}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeRFC}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '10%' }}>
                <TextField
                  id='outlined-mpago'
                  label='Método Pago'
                  required
                  type='Text'
                  value={clienteMPago}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeMPago}
                />
              </Stack>
            </div>
            <div style={divStyle}></div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '40%' }}>
                <TextField
                  id='outlined-direccion'
                  label='Direccion'
                  required
                  type='Text'
                  value={clienteDireccion}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeDireccion}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '20%' }}>
                <TextField
                  id='outlined-ciudad'
                  label='Ciudad'
                  required
                  type='Text'
                  value={clienteCiudad}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeCiudad}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '15%' }}>
                <TextField
                  id='outlined-zona'
                  label='Zona'
                  required
                  type='Text'
                  value={clienteZona}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeZona}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '15%' }}>
                <TextField
                  id='outlined-CP'
                  label='CP'
                  required
                  type='number'
                  value={clienteCP}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeCP}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '40%' }}>
                <TextField
                  id='outlined-correo'
                  label='Correo'
                  type='Text'
                  value={clienteCorreo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeCorreo}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '13%' }}>
                <TextField
                  id='outlined-telefono'
                  label='Teléfono'
                  type='Text'
                  value={clienteTel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeTel}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '40%' }}>
                <Autocomplete
                  size='medium'
                  options={representantes}
                  value={clienteRepresentante}
                  id='auto-producto'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setClienteRepresentante(newValue);
                  }}
                  style={{ marginTop: -10 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='Representante'
                      variant='standard'
                    />
                  )}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '60%' }}>
                <TextField
                  label='Comentarios de entrega'
                  multiline
                  minRows={4}
                  maxRows={7}
                  inputProps={{ maxLength: 500 }}
                  style={{ width: '100%' }}
                  value={clienteComentarios}
                  onChange={handleChangeComent}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              {editar ? (
                <>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => {
                      limpiar();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='contained'
                    disabled={validarDatos()}
                    onClick={() => guardar(false)}
                  >
                    Actualizar
                  </Button>
                </>
              ) : (
                <Button
                  variant='contained'
                  disabled={validarDatos()}
                  onClick={() => guardar(true)}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
