/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFile, setAlmacen, updateAlmacen } from '../../api';
import { StyledPaper } from '../Paper/Paper';
import TablaProductos from '../TablaDeProductos/Tabla';
import { estados } from '../../Utils/Estados';
import municipios from '../../Utils/EstadosMunicipios.json'
import { getFechaHora } from '../../Utils/validations';

const headCells = [
  {
    id: '#',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'clave',
    numeric: false,
    disablePadding: true,
    label: 'Clave',
  },
  {
    id: 'nombre',
    numeric: false,
    disablePadding: true,
    label: 'Almacen',
  },
  {
    id: 'zona',
    numeric: false,
    disablePadding: false,
    label: 'Zona',
  },
  {
    id: 'edo',
    numeric: false,
    disablePadding: false,
    label: 'Estado',
  },
  {
    id: 'tipo',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'acciones',
    numeric: false,
    label: 'Acciones',
  },
];

const detalleColumnas = [
  {
    id: 'idrepresentante',
    label: 'id',
  },
  {
    id: 'representante',
    label: 'Representante',
  },
  {
    id: 'folio',
    label: 'Folio',
  },
  {
    id: 'am',
    label: 'AMs',
  },
  {
    id: 'ov',
    label: 'OVs',
  },
];

const listaTipo = ['Bodega', 'Consignación'];

export default function Almacenes() {
  const {
    inventarios: {
      configuraciones: { tZonas },
      usuario: { token },
      representantes,
      almacenes,
    },
  } = useSelector(state => {
    return state;
  });
  let variant = 'error';
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [listaDeClientes, setListaDeClientes] = useState([]);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [almacenNombre, setAlmacenNombre] = useState('');
  const [almacenClave, setAlmacenClave] = useState('');
  const [almacenTipo, setAlmacenTipo] = useState(listaTipo[0]);
  const [almacenZona, setAlmacenZona] = useState('');
  const [almacenCentro, setAlmacenCentro] = useState('');
  const [almacenEstado, setAlmacenEstado] = useState('');
  const [almacenMunicipio, setAlmacenMunicipio] = useState('');
  const [almacenColonia, setAlmacenColonia] = useState('');
  const [almacenCP, setAlmacenCP] = useState('');
  const [almacenRFC, setAlmacenRFC] = useState('');
  const [almacenDireccion, setAlmacenDireccion] = useState('');
  const [almacenRepresentante, setAlmacenRepresentante] = useState([]);
  const [searched, setSearched] = useState('');
  const [open, setOpen] = useState(false);
  const [listaMunicipios, setListaMunicipios] = useState([]);
  const [listaRepresentantes, setListaRepresentantes] = useState([]);

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  const sendSnackA = message => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  async function getAllAlmacenes() {
    dispatch({ type: 'FETCHING_START' });
    try {
      setFormato(almacenes);
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    } finally {
      dispatch({ type: 'FETCHING_END' });
    }
  }

  function setFormato(listaAlmacenes) {
    let tmp = [];
    listaAlmacenes.forEach(element => {
      const formateo = {
        id: element.IidAlmacen,
        clave: `${element.ClaveAlmacen} - ${element.Centro}`,
        nombre: element.Almacen,
        zona: element.Zona,
        estado: element.Estado,
        tipo: element.TipoInventario,
        acciones: 1,
        extra: element,
        detalles: {
          valores: [],
        },
      };
      const val = {
        idrep: element.Iidtrabajador,
        rep: element.label2,
        folio: element.Folio,
        am: element.ConsAM,
        ov: element.ConsOV,
      };
      formateo.detalles.valores.push(element.Estatus ? val : {});
      const flag = tmp.find(element2 => element2.clave === formateo.clave);
      if (!flag) {
        tmp.push(formateo);
      } else {
        const productoTmp = formateo.detalles.valores;
        flag.detalles.valores.push(productoTmp[0]);
      }
    });
    setRows(tmp);
    setListaDeClientes(tmp);
  }

  const exportar = async () => {
    const crearXLSX = [];
    let num = 0;
    const { date, time } = getFechaHora();
    const filename = `Almacenes-Representantes_${date}-${time}`;
    dispatch({ type: 'FETCHING_START' });
    rows.forEach(element => {
      const {
        estado,
        id,
        nombre,
        tipo,
        zona,
        extra: { CP, Centro, ClaveAlmacen, Direccion, Colonia, Municipio, RFC },
        detalles: { valores },
      } = element;
      if (valores.length > 0) {
        valores.forEach(detalle => {
          const { idrep, rep, folio, am, ov } = detalle;
          const row = {
            num: num + 1,
            idrep,
            rep,
            folio,
            am,
            ov,
            idAlmacen: id,
            nombreAlmacen: nombre,
            CentroAlmacen: Centro,
            ClaveAlmacen: ClaveAlmacen,
            tipoAlmacen: tipo,
            RFC,
            zona,
            estado,
            Municipio,
            Direccion,
            Colonia,
            CP,
          };
          if (idrep) {
            num++;
            crearXLSX.push(row);
          }
        });
      } else {
        const row = {
          num: num + 1,
          idrep: '',
          rep: '',
          folio: '',
          am: '',
          ov: '',
        };
        row.idAlmacen = id;
        row.nombreAlmacen = nombre;
        row.CentroAlmacen = Centro;
        row.ClaveAlmacen = ClaveAlmacen;
        row.tipoAlmacen = tipo;
        row.RFC = RFC;
        row.zona = zona;
        row.estado = estado;
        row.Municipio = Municipio;
        row.Direccion = Direccion;
        row.Colonia = Colonia;
        row.CP = CP;
        num++;
        crearXLSX.push(row);
      }
    });
    getFile(crearXLSX, filename);
    dispatch({ type: 'FETCHING_END' });
  };

  useEffect(() => {
    getAllAlmacenes();
    setFormatoRep();
  }, []);

  function setFormatoRep() {
    let tmp = [];
    representantes.forEach(element => {
      const { folios } = element;
      if (folios.length > 0) {
        folios.forEach(fol => {
          const newRep = Object.assign({}, element);
          newRep.label = newRep.label + ' - ' + (fol.Folio || '');
          newRep.FolioZona = fol.Folio;
          if (fol.Estatus) tmp.push(newRep);
        });
      }
    });
    setListaRepresentantes(tmp);
  }

  useEffect(() => {
    const listaTmp = municipios[almacenEstado] || [];
    setListaMunicipios(listaTmp);
  }, [almacenEstado]);

  useEffect(() => {
    getAllAlmacenes();
  }, [almacenes]);

  async function editItem(id) {
    const productoEdit = rows.filter(item => item.id === id)[0];
    setEditando(productoEdit);
    setEditar(true);
    setOpen(true);
  }

  useEffect(() => {
    if (editando) {
      let array = [];
      const {
        tipo,
        detalles: { valores },
        extra: {
          Centro,
          ClaveAlmacen,
          Colonia,
          Municipio,
          Estado,
          CP,
          Direccion,
          RFC,
          Zona,
        },
      } = editando;
      valores.forEach(element => {
        const flag =
          listaRepresentantes.filter(
            item =>
              item.IidTrabajador === element.idrep &&
              item.FolioZona === element.folio
          )[0] || false;
        if (flag) array.push(flag);
      });
      const tmpZona = tZonas.filter(zon => zon.NombreZona === Zona)[0];
      setAlmacenNombre(editando.nombre || '');
      setAlmacenClave(ClaveAlmacen || '');
      setAlmacenTipo(tipo || '');
      setAlmacenZona(tmpZona || '');
      setAlmacenCentro(Centro || '');
      setAlmacenEstado(Estado || '');
      setAlmacenMunicipio(Municipio || '');
      setAlmacenColonia(Colonia || '');
      setAlmacenCP(CP || '');
      setAlmacenDireccion(Direccion || '');
      setAlmacenRFC(RFC || '');
      setAlmacenRepresentante(array);
    }
  }, [editando]);

  const guardar = async nuevo => {
    const payload = {
      almacenZona: almacenZona.NombreZona || '',
      almacenRepresentante,
      almacenMunicipio,
      almacenDireccion,
      almacenColonia,
      almacenNombre,
      almacenCentro,
      almacenEstado,
      almacenClave,
      almacenTipo,
      almacenRFC,
      almacenCP,
      editando,
    };
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tAlmacenes } = nuevo
        ? await setAlmacen(token, payload)
        : await updateAlmacen(token, payload);
      if (tAlmacenes) {
        setFormato(tAlmacenes);
        variant = 'success';
        sendSnackA('Se guardó el almacen con éxito');
        dispatch({
          type: 'ALMACENES_FETCHING',
          payload: { almacenes: tAlmacenes },
        });
        dispatch({ type: 'FETCHING_END' });
        limpiar();
      }
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesión finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const validarDatos = () => {
    if (
      almacenNombre !== '' &&
      almacenCentro !== '' &&
      almacenClave !== '' &&
      almacenTipo !== null &&
      almacenZona !== '' &&
      almacenRFC !== ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  function limpiar() {
    setEditando(null);
    setEditar(false);
    setAlmacenNombre('');
    setAlmacenClave('');
    setAlmacenTipo(listaTipo[0]);
    setAlmacenZona(null);
    setAlmacenCentro('');
    setAlmacenEstado(null);
    setAlmacenMunicipio(null);
    setAlmacenColonia('');
    setAlmacenCP('');
    setAlmacenDireccion('');
    setAlmacenRFC('');
    setAlmacenRepresentante(null);
    setOpen(false);
  }

  const cancelSearch = () => {
    if (searched !== '') {
      setSearched('');
      setRows(listaDeClientes);
    }
  };

  const handleChange = event => {
    const filteredRows = listaDeClientes.filter(row => {
      return (
        row?.nombre?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.rep?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.clave?.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setRows(filteredRows);
    setSearched(event.target.value);
  };

  const handleClose = () => {
    limpiar();
  };

  const handleChangeRFC = event => {
    const newValue = event.target.value;
    setAlmacenRFC(newValue);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={divStyle}>
          <Paper
            component='form'
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Búsqueda por nombre, clave y representante'
              inputProps={{ 'aria-label': 'Búsqueda por nombre y clave' }}
              value={searched}
              onChange={handleChange}
            />
            <IconButton
              type='button'
              sx={{ p: '10px' }}
              aria-label='search'
              onClick={() => cancelSearch()}
            >
              {searched !== '' ? (
                <CloseIcon color='error' />
              ) : (
                <SearchIcon color='primary' />
              )}
            </IconButton>
          </Paper>
        </div>
      </div>
      <div style={divStyle}>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='primary' size='medium' aria-label='add' sx={{ mr: 1 }}>
            <AddIcon onClick={() => setOpen(true)} />
          </Fab>
        </Box>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='warning' size='medium' aria-label='export' sx={{ mr: 1 }}>
            <FileDownloadIcon onClick={() => exportar()} />
          </Fab>
        </Box>
      </div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <StyledPaper>
          <Grid container wrap='nowrap' spacing={2}>
            <Grid item xs={12}>
              <TablaProductos
                headers={headCells}
                productos={rows}
                detalles={detalleColumnas}
                editItem={editItem}
                mensaje={'Almacenes '}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        onClose={handleClose}
        maxWidth={'xl'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {editar ? 'Actualizar ' : 'Agregar '} almacen
        </DialogTitle>
        <DialogContent dividers={'paper'}>
          <div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '25%' }}>
                <TextField
                  id='outlined-nombre'
                  label='Nombre'
                  type='Text'
                  required
                  value={almacenNombre}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setAlmacenNombre(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '20%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Clave'
                  type='Text'
                  required
                  value={almacenClave}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setAlmacenClave(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '20%' }}>
                <TextField
                  id='outlined-centro'
                  label='Centro'
                  type='Text'
                  value={almacenCentro}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  onChange={({ target: { value } }) => {
                    setAlmacenCentro(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '20%' }}>
                <Autocomplete
                  size='medium'
                  options={listaTipo}
                  value={almacenTipo}
                  id='auto-rol'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setAlmacenTipo(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      style={{ marginTop: -10 }}
                      {...params}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='Tipo inventario'
                      variant='standard'
                    />
                  )}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <Autocomplete
                  size='medium'
                  options={estados}
                  value={almacenEstado}
                  id='auto-rol'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setAlmacenEstado(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      style={{ marginTop: -10 }}
                      {...params}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='Estado'
                      variant='standard'
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <Autocomplete
                  size='medium'
                  options={listaMunicipios}
                  value={almacenMunicipio}
                  id='auto-rol'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setAlmacenMunicipio(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      style={{ marginTop: -10 }}
                      {...params}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='Municipio'
                      variant='standard'
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <Autocomplete
                  size='medium'
                  options={tZonas}
                  value={almacenZona}
                  id='auto-rol'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setAlmacenZona(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      style={{ marginTop: -10 }}
                      {...params}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='Zona almacen'
                      variant='standard'
                    />
                  )}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-direccion'
                  label='Direccion'
                  type='Text'
                  required
                  value={almacenDireccion}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setAlmacenDireccion(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Colonia'
                  required
                  type='Text'
                  value={almacenColonia}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setAlmacenColonia(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-cp'
                  label='CP'
                  type='Text'
                  required
                  value={almacenCP}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setAlmacenCP(value);
                  }}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '20%' }}>
                <TextField
                  id='outlined-rfc'
                  label='RFC'
                  type='Text'
                  required
                  value={almacenRFC}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeRFC}
                />
              </Stack>
              <Stack spacing={3} sx={{ width: 500 }}>
                <Autocomplete
                  multiple
                  options={listaRepresentantes}
                  value={almacenRepresentante || []}
                  id='auto-producto'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setAlmacenRepresentante(newValue || []);
                  }}
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant='standard'
                      label='Representantes'
                      placeholder='Representante'
                    />
                  )}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              {editar ? (
                <>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => {
                      limpiar();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='contained'
                    disabled={validarDatos()}
                    onClick={() => guardar(false)}
                  >
                    Actualizar
                  </Button>
                </>
              ) : (
                <Button
                  variant='contained'
                  disabled={validarDatos()}
                  onClick={() => guardar(true)}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
