import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import ReduxThunk from 'redux-thunk';
//----  reducers  ----
import rootReducer from '../reducers';

const middleware = [ReduxThunk];

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
  // Permite utilizar la extension de redux para chrome
  composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;
}

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const store = createStore(
    persistedReducer, // reducer compilado
    composeEnhancers(
      applyMiddleware(...middleware) // permite ejecutar acciones de manera asíncrona
    )
  );
  const persisters = persistStore(store);

  return { store, persisters };
};

export default configureStore;
