/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFile, setZona, updateZona } from '../../api';
import { StyledPaper } from '../Paper/Paper';
import TablaProductos from '../TablaDeProductos/Tabla';
import { getFechaHora } from '../../Utils/validations';

const headCells = [
  {
    id: 'name',
    numeric: true,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'nombre',
    numeric: false,
    disablePadding: true,
    label: 'Zona',
  },
  {
    id: 'estatus',
    numeric: false,
    disablePadding: true,
    label: 'Estatus',
  },
  {
    id: 'acciones',
    numeric: false,
    disablePadding: true,
    label: 'Acciones',
  },
];

const listaEstatus = [{ label: 'Activa' }, { label: 'Desactivada' }];

export default function Zonas() {
  const {
    inventarios: {
      usuario: { token },
      configuraciones: { tZonas },
    },
  } = useSelector(state => {
    return state;
  });
  let variant = 'error';
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [listaDeZonas, setListaDeZonas] = useState([]);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [zonaNombre, setZonaNombre] = useState('');
  const [zonaEstatus, setZonaEstatus] = useState(listaEstatus[0]);
  const [searched, setSearched] = useState('');
  const [open, setOpen] = useState(false);
  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  const sendSnackA = message => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  async function getAllZonas() {
    dispatch({ type: 'FETCHING_START' });
    try {
      setFormato(tZonas);
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    } finally {
      dispatch({ type: 'FETCHING_END' });
    }
  }

  function setFormato(listaZonas) {
    let tmp = [];
    listaZonas.forEach(element => {
      const formateo = {
        name: element.IidZon,
        nombre: element.NombreZona || '',
        estatus: element.Estatus ? 'Activa' : 'Desactivada',
        acciones: 1,
        extra: element,
        detalles: {
          valores: [],
        },
      };
      const flag = tmp.find(element2 => element2.name === formateo.name);
      if (!flag) {
        tmp.push(formateo);
      }
    });
    setRows(tmp);
    setListaDeZonas(tmp);
  }

  const exportar = async () => {
    const crearXLSX = [];
    const { date, time } = getFechaHora();
    const filename = `Zonas_${date}-${time}`;
    dispatch({ type: 'FETCHING_START' });
    await rows.forEach(element => {
      const { name, nombre, estatus } = element;
      const row = {
        id: name,
        nombre,
        estatus,
      };
      crearXLSX.push(row);
    });
    getFile(crearXLSX, filename);
    dispatch({ type: 'FETCHING_END' });
  };
  useEffect(() => {
    getAllZonas();
  }, []);

  useEffect(() => {
    getAllZonas();
  }, [tZonas]);

  async function editItem(id) {
    const productoEdit = rows.filter(item => item.name === id)[0];
    setEditando(productoEdit);
    setEditar(true);
    setOpen(true);
  }

  useEffect(() => {
    if (editando) {
      const { nombre, estatus } = editando;
      setZonaNombre(nombre || '');
      setZonaEstatus(estatus || '');
    }
  }, [editando]);

  const guardar = async nuevo => {
    const payload = {
      Nombre: zonaNombre,
      Estatus: zonaEstatus.label === 'Activa' ? 1 : 0,
      editando,
    };
    dispatch({ type: 'FETCHING_START' });
    try {
      const { configuraciones } = nuevo
        ? await setZona(token, payload)
        : await updateZona(token, payload);
      if (configuraciones) {
        setFormato(configuraciones.tZonas);
        variant = 'success';
        sendSnackA('Se guardó la zona con éxito');
        dispatch({
          type: 'CONFIGURACION_FETCHING',
          payload: { configuraciones },
        });
        dispatch({ type: 'FETCHING_END' });
        limpiar();
      }
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesión finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const validarDatos = () => {
    if (zonaNombre !== '' && zonaEstatus !== '') {
      return false;
    } else {
      return true;
    }
  };

  function limpiar() {
    setEditando(null);
    setEditar(false);
    setZonaNombre('');
    setZonaEstatus(listaEstatus[0]);
    setOpen(false);
  }

  const cancelSearch = () => {
    if (searched !== '') {
      setSearched('');
      setRows(listaDeZonas);
    }
  };

  const handleChange = event => {
    const filteredRows = listaDeZonas.filter(row => {
      return (
        row?.nombre?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.estatus?.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setRows(filteredRows);
    setSearched(event.target.value);
  };

  const handleClose = () => {
    limpiar();
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={divStyle}>
          <Paper
            component='form'
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Búsqueda por nombre y estatus'
              inputProps={{ 'aria-label': 'Búsqueda por nombre y clave' }}
              value={searched}
              onChange={handleChange}
            />
            <IconButton
              type='button'
              sx={{ p: '10px' }}
              aria-label='search'
              onClick={() => cancelSearch()}
            >
              {searched !== '' ? (
                <CloseIcon color='error' />
              ) : (
                <SearchIcon color='primary' />
              )}
            </IconButton>
          </Paper>
        </div>
      </div>
      <div style={divStyle}>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='primary' size='medium' aria-label='add' sx={{ mr: 1 }}>
            <AddIcon onClick={() => setOpen(true)} />
          </Fab>
        </Box>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='warning' size='medium' aria-label='export' sx={{ mr: 1 }}>
            <FileDownloadIcon onClick={() => exportar()} />
          </Fab>
        </Box>
      </div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <StyledPaper>
          <Grid container wrap='nowrap' spacing={2}>
            <Grid item xs={12}>
              <TablaProductos
                headers={headCells}
                productos={rows}
                detalles={false}
                editItem={editItem}
                mensaje={'Zonas '}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        onClose={handleClose}
        maxWidth={'xl'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {editar ? 'Actualizar ' : 'Agregar '} Zona
        </DialogTitle>
        <DialogContent dividers={'paper'}>
          <div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '25%' }}>
                <TextField
                  id='outlined-nombre'
                  label='Nombre'
                  type='Text'
                  required
                  value={zonaNombre}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setZonaNombre(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '20%' }}>
                <Autocomplete
                  size='medium'
                  options={listaEstatus}
                  value={zonaEstatus}
                  id='auto-rol'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setZonaEstatus(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      style={{ marginTop: -5 }}
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      label='Estatus'
                      variant='standard'
                      helperText='Estatus'
                    />
                  )}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              {editar ? (
                <>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => {
                      limpiar();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='contained'
                    disabled={validarDatos()}
                    onClick={() => guardar(false)}
                  >
                    Actualizar
                  </Button>
                </>
              ) : (
                <Button
                  variant='contained'
                  disabled={validarDatos()}
                  onClick={() => guardar(true)}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
