/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import types from '../../actions/types';
import { getDatos, login, password, validCode } from '../../api';
import backimg from '../../assets/imagenes/NovoPortal.png';
import logo from '../../assets/imagenes/logo.png';
import { encripta } from '../../Utils/Aes';

function Login() {
  const [formData, setFormData] = useState(defaultValue());
  const { enqueueSnackbar } = useSnackbar();
  const [logged, setLogged] = useState(false);
  const [mfaCode, setMfaCode] = useState(false);
  const [mfaCodeUsuario, setMfaCodeUsuario] = useState(false);
  const [iniciando, setIniciando] = useState(false);
  const [contra, setContra] = useState(false);
  const [usuarioTmp, setUsuarioTmp] = useState(null);

  const dispatch = useDispatch();
  const { inventarios } = useSelector(state => {
    return state;
  });
  let variant = 'error';

  useEffect(() => {
    const { usuario } = inventarios;
    setLogged(usuario ? true : false);
    dispatch({ type: 'FETCHING_END' });

  }, []);

  const handleLogin = async () => {
    if (contra && formData.email) {
      try {
        const { data } = await password(formData);
        variant = 'success';
        // eslint-disable-next-line no-console
        enqueueSnackbar(data.message, { variant, autoHideDuration: 1500 });
      } catch (error) {
        enqueueSnackbar(types.PASSWORD_FAIL, {
          variant,
          autoHideDuration: 1500,
        });
        setIniciando(false);
      }
    } else {
      let errors = {};
      if (!formData.email || !formData.password) {
        if (!formData.email) errors.email = true;
        if (!formData.password) errors.password = true;
      } else {
        setIniciando(true);
        try {
          const emailCrypt = encripta(formData.email);
          const passwordCrypt = encripta(formData.password);
          const formDataEncrypt = {
            email: emailCrypt,
            password: passwordCrypt,
          };
          dispatch({ type: 'FETCHING_START' });
          const { usuario } = await login(formDataEncrypt);
          setUsuarioTmp(usuario || null)
          setIniciando(false)
          if (usuario && usuario.Email) {
            dispatch({ type: 'SAVE_TOKEN', payload: { token: usuario.token } });
            setFormData({ ...formData, email: '' });
            setMfaCode(true);
          }
          dispatch({ type: 'FETCHING_END' });
        } catch (error) {
          console.log(error);
          let mensaje = '';
          if (error && !error.toString().includes('of undefined')) {
            mensaje = error.toString();
            dispatch({ type: 'RESET_ALL_DATA' });
          } else {
            mensaje = types.FAIL;
          }
          variant = 'error';
          enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
          setIniciando(false);
          dispatch({ type: 'FETCHING_END' });
        }
      }
    }
  };

  const onChange = (e, type) => {
    setFormData({ ...formData, [type]: e.target.value });
  };


  const handleMFA = async () => {
    const { token } = inventarios;
    const codePayLoad = { codigo: mfaCodeUsuario }
    try {
      const response = await validCode(codePayLoad, token);
      if (response && response.codigo) {
        setIniciando(true);
        dispatch({ type: 'FETCHING_START' });
        try {
          const {
            configuraciones,
            tAlmacenes,
            tInventario,
            tClientes,
            tOVs,
            tTraslados,
            tRepresentantes,
            tPedidos
          } = await getDatos(token);
          dispatch({ type: 'USUARIO_LOGIN', payload: { usuario: usuarioTmp } });
          dispatch({
            type: 'CONFIGURACION_FETCHING',
            payload: { configuraciones },
          });
          dispatch({
            type: 'ALMACENES_FETCHING',
            payload: { almacenes: tAlmacenes },
          });
          dispatch({
            type: 'INVENTARIO_FETCHING',
            payload: { inventarios: tInventario },
          });
          dispatch({
            type: 'CLIENTES_FETCHING',
            payload: { clientes: tClientes },
          });
          dispatch({
            type: 'REPRESENTANTES_FETCHING',
            payload: { representantes: tRepresentantes },
          });
          dispatch({
            type: 'MOVIMIENTOS_FETCHING',
            payload: { movimientos: tTraslados },
          });
          dispatch({
            type: 'ORDENES_VENTA_FETCHING',
            payload: { ordenesVenta: tOVs },
          });
          dispatch({
            type: 'PEDIDOS_FETCHING',
            payload: { pedidos: tPedidos },
          });
          dispatch({ type: 'SAVE_TOKEN', payload: { token: false } });
          dispatch({ type: 'FETCHING_END' });
          setLogged(true);
        } catch (error) {
          console.log(JSON.stringify(error))
          let mensaje = '';
          if (error && !error.toString().includes('of undefined')) {
            mensaje = error.toString();
          } else {
            mensaje = types.FAIL;
          }
          variant = 'error';
          enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
          setIniciando(false);
          dispatch({ type: 'FETCHING_END' });
        }
      }
    } catch (error) {
      console.log(error.toString());
      let mensaje = '';
      if (error && !error.toString().includes('of undefined')) {
        mensaje = error.toString();
      } else {
        mensaje = types.FAIL;
      }
      variant = 'error';
      enqueueSnackbar(mensaje, { variant, autoHideDuration: 1500 });
      setIniciando(false);
      dispatch({ type: 'FETCHING_END' });
    }
  };

  const handleChangeCode = (e) => {
    setMfaCodeUsuario(e.target.value);
  };

  const keyPress = (e) =>{
    if(e.keyCode == 13){
       handleMFA()
    }
 }

  return (
    <div
      className='container'
      style={{
        backgroundImage: 'url(' + backimg + ')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          m: 1,
          borderRadius: 1,
        }}
        style={{ display: 'grid', margin: 0 }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            marginTop: 50,
          }}
        >
          <img
            loading='lazy'
            src={logo}
            alt='logo'
            style={{ maxHeight: 250 }}
          />
        </div>
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {logged ? (
            <>
              <Navigate to='/' replace />
            </>
          ) : (
            <>
              {mfaCode ?
                <Card style={styles.card} variant='outlined' name='formcodigoCard' id='formcodigoCard'>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                      }}
                      style={{ display: 'grid', marginTop: 10 }}
                    >
                      <TextField
                        placeholder='Codigo'
                        required
                        name='codigo'
                        id='codigo'
                        autoComplete='false'
                        defaultValue=''
                        onChange={handleChangeCode}
                        onKeyDown={keyPress}
                      />
                      <br />
                      <Button
                        type='submit'
                        disabled={iniciando}
                        variant='contained'
                        onClick={handleMFA}
                        name='codigobtn'
                      >
                        Validar código
                        {iniciando && (
                          <CircularProgress color='secondary' size={14} />
                        )}
                      </Button>
                      <br />
                      <Button
                        disabled={iniciando}
                        variant='contained'
                        color='warning'
                        onClick={() => {
                          setMfaCode(false);
                        }}
                        name='codigo'
                      >
                        Regresar
                      </Button>
                    </Box>
                  </CardContent>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#ffffff',
                    }}
                  >
                    Versión 2.5.0
                  </span>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#ffffff',
                    }}
                  >
                    Producción
                  </span>
                </Card> :
                <Card style={styles.card} variant='outlined' name='formUserCard' id='formUserCard'>
                  <CardContent>
                    <form onSubmit={e => e.preventDefault() && false}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          p: 1,
                          m: 1,
                          borderRadius: 1,
                        }}
                        style={{ display: 'grid', marginTop: 10 }}
                      >
                        <TextField
                          placeholder='Usuario'
                          required
                          autoComplete='false'
                          id='email'
                          name='email'
                          onChange={e => onChange(e, 'email')}
                        />
                        <br />
                        {!contra ? (
                          <TextField
                            type='password'
                            placeholder='Contraseña'
                            required={!contra}
                            onChange={e => onChange(e, 'password')}
                          />
                        ) : null}
                        <br />
                        <Button
                          type='submit'
                          disabled={iniciando}
                          variant='contained'
                          onClick={handleLogin}
                        >
                          {!contra ? ' Iniciar sesión' : ' Recuperar contraseña'}
                          {iniciando && (
                            <CircularProgress color='secondary' size={14} />
                          )}
                        </Button>
                      </Box>
                    </form>
                  </CardContent>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#ffffff',
                    }}
                  >
                    Versión 2.5.0
                  </span>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#ffffff',
                    }}
                  >
                    Producción
                  </span>
                </Card>}
            </>
          )}
        </div>
      </Box>
    </div>
  );
}

function defaultValue() {
  return {
    email: '',
    password: '',
  };
}

const styles = {
  error: {
    borderColor: '#940c0c',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '100%',
  },
  card: {
    background: 'transparent',
    backdropFilter: 'blur(5px)',
    marginTop: 30,
  },
};

export default Login;
